import React, { useState, useEffect } from "react";
import Family from "./components/family";
import Category from "./components/category";
import Product from "./components/product";
import { FamilyDialog } from "./dialogs/newFamilyDialog";
import { CategoryDialog } from "./dialogs/newCategoryDialog";
import { ProductDialog } from "./dialogs/newProductDialog";


// TODO: Implement name checking for families, categories and products as it is used as a key and should be unique


const Step1 = () => {
  
  const [families, setFamilies] = useState(() => {
    const savedFamilies = localStorage.getItem("families");
    return savedFamilies ? JSON.parse(savedFamilies) : familiesMock;
  });
  const [selectedFamily, setSelectedFamily] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    localStorage.setItem("families", JSON.stringify(families));
  }, [families]);

  return (
    <div>
      <div>
        <h2>Families</h2>
        <div style={{ display: 'flex' }}>
          {families.map(family => (
            <Family
              key={family.name}
              name={family.name}
              isSelected={selectedFamily === family.name}
              onClick={() => {
                setSelectedFamily(family.name);
                setSelectedCategory(null);
              }}
              setSelectedFamily={setSelectedFamily}
              setFamilies={setFamilies}
            />
          ))}
          <FamilyDialog 
            setFamilies={setFamilies} 
          />
        </div>
      </div>
      {selectedFamily && (
        <div>
          <h2>Categories</h2>
          <div style={{ display: 'flex' }}>
            {families?.length > 0 && families.find(family => family?.name === selectedFamily).categories.map(category => (
              <Category
                key={category.name}
                name={category.name}
                isSelected={selectedCategory === category.name}
                onClick={() => setSelectedCategory(category.name)}
                setSelectedCategory={setSelectedCategory}
                setFamilies={setFamilies}
                selectedFamily={selectedFamily}
              />
            ))}
            <CategoryDialog 
              setFamilies={setFamilies} 
              selectedFamily={selectedFamily} 
            />
          </div>
          {selectedCategory && (
            <div>
              <h2>Products</h2>
              <div style={{ display: 'flex' }}>
                {families.find(family => family.name === selectedFamily).categories.find(category => category.name === selectedCategory).products.map(product => (
                  <Product
                    key={product.name}
                    name={product.name}
                    setFamilies={setFamilies}
                    selectedFamily={selectedFamily}
                    selectedCategory={selectedCategory}
                  />
                ))}
                <ProductDialog 
                  setFamilies={setFamilies} 
                  selectedFamily={selectedFamily} 
                  selectedCategory={selectedCategory} 
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Step1;



const familiesMock = [
  {
    name: 'Beverages',
    categories: [
      {
        name: 'Sodas',
        products: [
          {
            name: 'Coca-Cola',
            price: 1
          },
          {
            name: 'Pepsi',
            price: 1
          }
        ]
      },
      {
        name: 'Shakes',
        products: [
          {
            name: 'Strawberry Shake',
            price: 3
          },
          {
            name: 'Vanilla Shake',
            price: 3
          }
        ]
      }
    ]
  },
  {
    name: 'Meat',
    categories: []
  },
  {
    name: 'Fish',
    categories: []
  },
  {
    name: 'Coffee',
    categories: []
  }
];