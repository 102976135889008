import React, { useEffect, useState } from "react";
import { getBusinessLanguagesAPI, putBusinessLanguagesAPI } from "../services/business.service";

const styles = {
    mainContainer: {
        position: "relative",
        width: "135px",
    },
    buttonClose: {
        backgroundColor: '#374957',
        color: 'white',
        padding: '10px',
        cursor: 'pointer',
        borderRadius: '10px',
        outline: 'none',
        width: '100%',
        border: 'none',
    },
    buttonOpen: {
        backgroundColor: '#374957',
        color: 'white',
        padding: '10px',
        cursor: 'pointer',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        outline: 'none',
        width: '100%',
        border: 'none',
    },
    dropdown: {
        position: 'absolute',
        top: '100%',
        left: 0,
        width: '100%',
        backgroundColor: 'white',
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
        zIndex: 1,
        outline: 'none',
    },
    item: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px',
        borderTop: '1px solid #ccc',
        cursor: 'pointer',
        fontSize: '12px',
    },
};

export default function AddLanguage({businessId, setSelectedItemsHandler}) {
    const [isOpen, setIsOpen] = useState(false);
    const [items, setItems] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
  
    const toggleDropdown = () => setIsOpen(!isOpen);
  
    const handleCheck = (event) => {
      const { value, checked } = event.target;
      if (checked) {
        const temp = [...selectedItems, value];
        setSelectedItems(temp);
        putBusinessLanguagesAPI(businessId, temp);
      } else {
        const temp = selectedItems.filter(item => item !== value);
        setSelectedItems(temp);
        putBusinessLanguagesAPI(businessId, temp);
      }
    };

    useEffect(() => {
      setSelectedItemsHandler(selectedItems);
    } , [selectedItems]);

    useEffect(() => {
      if (businessId === undefined) return;
      getBusinessLanguagesAPI(businessId).then((response) => {
        setItems(response);
        setSelectedItems(response.filter(item => item.selected).map(item => item.code));
      });
    }, [businessId]);
  
    return (
      <div style={styles.mainContainer}>
        <button style={isOpen ? styles.buttonOpen : styles.buttonClose} onClick={toggleDropdown}>
            Añadir idioma
        </button>
        {isOpen && (
          <div style={styles.dropdown}>
            {items.map(item => (
              <div key={item._id} style={styles.item}>
                <input
                  type="checkbox"
                  value={item.code}
                  id={item._id}
                  checked={selectedItems.includes(item.code)}
                  onChange={handleCheck}
                />
                <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                  <p
                    style={{
                      fontWeight: "800",
                      fontSize: "10px",
                      lineHeight: "12.5px",
                      borderRadius: "20px",
                      zIndex: "5",
                      fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {item.name}
                  </p>
                  <img
                    src={`/assets/flags/${item.code}.png`}
                    alt={item.code}
                    style={{
                      width: 35,
                      height: 23,
                      objectFit: "cover",
                      padding: 0,
                      borderRadius: "5px",
                      zIndex: "5",
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };
