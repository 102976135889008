import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Hidder from "../components/hidder";
import SelectRestaurantInput from "../components/select-restaurant-input";
import {
  Card,
  CardContent,
  TextField,
  Button,
  IconButton,
  Checkbox,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  updatePeriodName,
  getFvnHistory,
  getFvnEmails,
  sendFVNs,
  downloadFVNs,
  closeDay,
  updateFvnEmails,
  updatePeriodStatus,
  updatePeriodsStatuses,
} from "../services/business.service";

const styles = {
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
  },
  emailsContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 20,
  },
  listHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
    maxWidth: "450px",
  },
  listHeaderButtons: {
    display: "flex",
    alignItems: "center",
  },
};

const RestaurantFVNs = () => {
  const [selectedRestaurant, setSelectedRestaurant] = useState(null);
  const [restaurantEmails, setRestaurantEmails] = useState([]);
  const [inputEmail, setInputEmail] = useState("");
  const [fvnHistory, setFvnHistory] = useState([]);
  const [openFVN, setOpenFVN] = useState(null);
  const [selectedFVNs, setSelectedFVNs] = useState([]);

  useEffect(() => {
    if (selectedRestaurant) {
      getFVNHistory();
    }
  }, [selectedRestaurant]);

  const getFVNHistory = async () => {
    await getFvnHistory(selectedRestaurant._id, 0, 1000).then(async (data) => {
      setFvnHistory(data);
      await getFvnEmails(selectedRestaurant._id).then((emails) => {
        setRestaurantEmails(emails);
      });
    });
  };

  function isValidEmail(email) {
    if (restaurantEmails.includes(email)) return false;
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  const addEmail = () => {
    updateFvnEmails(selectedRestaurant._id, [
      ...restaurantEmails,
      inputEmail,
    ]).then(() =>
      setRestaurantEmails((prevEmails) => [...prevEmails, inputEmail])
    );
  };

  const deleteEmail = (email) => {
    updateFvnEmails(
      selectedRestaurant._id,
      restaurantEmails.filter((prevEmail) => prevEmail !== email)
    ).then(() =>
      setRestaurantEmails((prevEmails) =>
        prevEmails.filter((prevEmail) => prevEmail !== email)
      )
    );
  };

  const debounce = (func, delay) => {
    let inDebounce;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(inDebounce);
      inDebounce = setTimeout(() => func.apply(context, args), delay);
    };
  };

  const debouncedUpdatePeriodName = debounce((businessId, periodId, text) => {
    updatePeriodName(businessId, periodId, text);
  }, 500);

  const handleInputChange = (text, periodId) => {
    if (text && text !== "") {
      debouncedUpdatePeriodName(selectedRestaurant._id, periodId, text);
    }
  };

  const formatDate = (date) => {
    if (!date) return "";
    const d = new Date(date);
    return `${d.getDate()}/${
      d.getMonth() + 1
    }/${d.getFullYear()} ${d.getHours()}:${d.getMinutes()}`;
  };

  const allCheckboxChange = (e) => {
    if (e.target.checked) {
      setSelectedFVNs(
        fvnHistory?.fvn_history?.map((period) => "" + period._id)
      );
    } else {
      setSelectedFVNs([]);
    }
  };

  const checkboxChange = (event) => {
    const id = event.target.value;
    setSelectedFVNs((prevSelectedFVNs) => {
      if (prevSelectedFVNs.includes(id)) {
        return prevSelectedFVNs.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedFVNs, "" + id];
      }
    });
  };

  const closeFNV = () => {
    closeDay(selectedRestaurant._id, restaurantEmails, "").then((data) => {
      getFVNHistory();
    });
  };

  const sendFVNsHandler = () => {
    sendFVNs(selectedRestaurant._id, restaurantEmails, selectedFVNs);
  };

  const downloadFVNsHandler = () => {
    downloadFVNs(selectedRestaurant._id, selectedFVNs)
      .then((data) => {
        const zipbuffer = data.resp.buffer.data;
        const uint8Array = new Uint8Array(zipbuffer);
        const blob = new Blob([uint8Array], { type: "application/zip" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Cierres de caja.zip");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleFVNStatusChange = (status, periodId) => {
    const period = fvnHistory?.fvn_history?.find(
      (period) => period._id === periodId
    );
    if (period) {
      period.status = status;
      updatePeriodStatus(selectedRestaurant._id, periodId, status).then(() =>
        setFvnHistory((prevFvnHistory) => {
          const newFvnHistory = [...prevFvnHistory.fvn_history];
          const index = newFvnHistory.findIndex(
            (period) => period._id === periodId
          );
          newFvnHistory[index] = period;
          return { fvn_history: newFvnHistory };
        })
      );
    }
  };

  const handleFVNStatusesChange = (status) => {
    updatePeriodsStatuses(selectedRestaurant._id, selectedFVNs, status).then(
      () =>
        setFvnHistory((prevFvnHistory) => {
          const newFvnHistory = [...prevFvnHistory.fvn_history];
          newFvnHistory.forEach((period) => {
            if (selectedFVNs.includes("" + period._id)) {
              period.status = status;
            }
          });
          return { fvn_history: newFvnHistory };
        })
    );
  };

  return (
    <>
      <Hidder/>
      <div style={styles.main}>
        <div style={styles.header}>
          <span>Cierres realizados</span>
          <SelectRestaurantInput
            setSelectedRestaurant={setSelectedRestaurant}
          />
        </div>
        {selectedRestaurant ? (
          <div>
            <div style={styles.emailsContainer}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  addEmail(inputEmail);
                }}
              >
                <input
                  type="text"
                  placeholder="Añadir email"
                  value={inputEmail}
                  onChange={(e) => setInputEmail(e.target.value)}
                />
                <button type="submit" disabled={!isValidEmail(inputEmail)}>
                  Añadir
                </button>
              </form>
              {restaurantEmails.map((email) => (
                <div key={email}>
                  <span>{email}</span>
                  <IconButton onClick={() => deleteEmail(email)}>
                    <DeleteIcon />
                  </IconButton>
                </div>
              ))}
            </div>
            <div style={styles.listHeader}>
              <div style={styles.listHeaderButtons}>
                <Checkbox
                  onChange={allCheckboxChange}
                  checked={
                    selectedFVNs.length ===
                    (fvnHistory?.fvn_history?.length || 0)
                  }
                />
                {selectedFVNs.length > 0 ? (
                  <div>
                    <button onClick={sendFVNsHandler}>Enviar correo</button>
                    <button onClick={downloadFVNsHandler}>Descargar</button>
                    <select
                      value={""}
                      onChange={(e) => handleFVNStatusesChange(e.target.value)}
                    >
                      <option value="" disabled hidden>
                        Seleccione una estado
                      </option>
                      <option value="SETTLED">Pago establecido</option>
                      <option value="PENDING">Pago en proceso</option>
                      <option value="SENT">Pago realizado</option>
                      <option value="REJECTED">Error</option>
                    </select>
                  </div>
                ) : null}
              </div>
              <button onClick={closeFNV}>Cierre</button>
            </div>
            {fvnHistory?.fvn_history?.length > 0
              ? fvnHistory?.fvn_history?.map((period) => (
                  <div key={period._id} style={{ cursor: "pointer" }}>
                    <div>
                      <Checkbox
                        value={period?._id}
                        onChange={checkboxChange}
                        checked={selectedFVNs.includes("" + period?._id)}
                      />
                      <input
                        defaultValue={period?.name}
                        onChange={(e) =>
                          handleInputChange(e.target.value, period?._id)
                        }
                      />
                      <span
                        onClick={() =>
                          setOpenFVN(
                            period?._id === openFVN ? null : period?._id
                          )
                        }
                      >
                        {period?.timeClosed2
                          ? formatDate(period?.timeClosed2)
                          : "INICIO"}
                      </span>
                      <span
                        onClick={() =>
                          setOpenFVN(
                            period?._id === openFVN ? null : period?._id
                          )
                        }
                      >
                        {formatDate(period?.timeClosed)}
                      </span>
                      {period?.total ? (
                        <span
                          onClick={() =>
                            setOpenFVN(
                              period?._id === openFVN ? null : period?._id
                            )
                          }
                        >
                          {period?.total}€
                        </span>
                      ) : null}
                      <select
                        value={period?.status || "not_done"}
                        onChange={(e) =>
                          handleFVNStatusChange(e.target.value, period?._id)
                        }
                      >
                        <option value="not_done">Pago no realizado</option>
                        <option value="in_process">Pago en proceso</option>
                        <option value="done">Pago finalizado</option>
                        <option value="error">Error en el pago</option>
                      </select>
                    </div>
                    {openFVN === period?._id ? <div>a</div> : null}
                  </div>
                ))
              : null}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default RestaurantFVNs;
