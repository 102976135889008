import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { logOut } from "../features/authSlice/authSlice";

const LeftDrawer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const redirectHome = () => navigate("/admin-home");
  const createBusinessRedirection = () => navigate("/create-business");
  const editZonesAndTablesRedirection = () =>
    navigate("/edit-zones-and-tables");
  const editProductsRedirection = () => navigate("/edit-products");
  const restaurantFVN = () => navigate("/restaurant-fvn");
  const POS = () => navigate("/pos");
  const TICKETS = () => navigate("/tickets");
  const KITCHEN = () => navigate("/kitchen");

  const logout = () => {
    if (window.confirm("¿Seguro que desea cerrar la sesión?")) {
      dispatch(logOut());
      navigate("/");
    }
  };

  return (
    <MainContainer>
      <DrawerContainer isOpen={isOpen} onMouseLeave={() => setIsOpen(false)}>
        <ToggleButton isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
          <img src="assets/icons/fi-rr-angle-right.svg" alt="open" />
        </ToggleButton>
        <ButtonsContainer isOpen={isOpen}>
          <DrawerItem isOpen={isOpen} onClick={redirectHome}>
            Inicio
          </DrawerItem>
          <DrawerItem isOpen={isOpen} onClick={createBusinessRedirection}>
            Crear local
          </DrawerItem>
          <DrawerItem isOpen={isOpen} onClick={editZonesAndTablesRedirection}>
            Editar zonas y mesas
          </DrawerItem>
          <DrawerItem isOpen={isOpen} onClick={editProductsRedirection}>
            Editar carta
          </DrawerItem>
          <DrawerItem isOpen={isOpen} onClick={restaurantFVN}>
            Cierres
          </DrawerItem>
          <DrawerItem isOpen={isOpen} onClick={POS}>
            POS
          </DrawerItem>
          <DrawerItem isOpen={isOpen} onClick={TICKETS}>
            COMANDAS
          </DrawerItem>
          <DrawerItem isOpen={isOpen} onClick={KITCHEN}>
            COCINA
          </DrawerItem>
          <LogoutButton isOpen={isOpen} onClick={logout}>
            Cerrar sesión
          </LogoutButton>
        </ButtonsContainer>
      </DrawerContainer>
      <DrawerButton isOpen={isOpen} delay={0} onClick={POS}>
        <img src="assets/icons/fi-rr-cursor.svg" alt="TPV" />
      </DrawerButton>
      <DrawerButton isOpen={isOpen} delay={100} onClick={TICKETS}>
        <img src="assets/icons/fi-rr-notebook.svg" alt="COMANDAS" />
      </DrawerButton>
      <DrawerButton isOpen={isOpen} delay={200} onClick={KITCHEN}>
        <img src="assets/icons/fi-rr-bell-ring.svg" alt="COCINA" />
      </DrawerButton>
    </MainContainer>
  );
};

export default LeftDrawer;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 100vh;
  margin: 7px;
  align-items: flex-start;
  gap: 20px;
`;

const DrawerContainer = styled.div`
  display: flex;
  width: ${({ isOpen }) => (isOpen ? "280px" : "50px")};
  background: rgba(55, 73, 87, 0.9);
  box-shadow: 0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  flex-direction: row;
  align-items: flex-start;
  border-radius: 15px;
  transition: width 0.3s ease, border-radius 0.3s ease;
`;

const DrawerButton = styled.button`
  display: flex;
  border: none;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 0px 2px 0px rgba(145, 158, 171, 0.2),
    0px 12px 24px -4px rgba(145, 158, 171, 0.12);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 15px;
  display: flex;

  transition: ${({ isOpen }) =>
    isOpen ? "none" : "transform 0.3s ease, opacity 0.3s ease"};
  transform: ${({ isOpen }) =>
    isOpen ? "translateX(-100px)" : "translateX(0)"};
  opacity: ${({ isOpen }) => (isOpen ? 0 : 1)};

  transition-delay: ${({ delay }) => `${delay}ms`};

  img {
    width: 24px;
    height: 24px;
  }
`;

const ToggleButton = styled.button`
  border: none;
  background: none;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transform: ${({ isOpen }) => (isOpen ? "rotateY(180deg)" : "rotateY(0deg)")};
  transition: transform 0.3s ease;

  img {
    width: 24px;
    height: 24px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${({ isOpen }) => (isOpen ? "5px 10px" : "0")};
  height: 100%;
  gap: 20px;
  transition: padding 0.3s ease;
`;

const DrawerItem = styled.span`
  cursor: pointer;
  color: white;
  font-size: 20px;
  width: 100%;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: opacity 0.3s ease;
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const LogoutButton = styled.button`
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  border-radius: 5px;
  border: none;
  background: #ffffff;
  padding: 10px 20px;
  margin-bottom: 10px;
  align-self: flex-start;
  transition: opacity 0.3s ease;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
`;