// src/pages/tickets.jsx

import React, { useState, useEffect, useMemo } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchTickets,
  refundTicket,
  cancelTicket,
  deleteTicket,
} from "../features/ticketSlice/ticketSlice";
import Pagination from "../components/tickets/pagination";
import SelectRestaurantInput from "../components/select-restaurant-input";

const GlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

const Tickets = () => {
  const dispatch = useDispatch();
  const { tickets, loading, error } = useSelector((state) => state.tickets);
  const [sortBy, setSortBy] = useState("createdAt");
  const [page, setPage] = useState(1);
  const [selectedRestaurant, setSelectedRestaurant] = useState(null);
  const itemsPerPage = 100;

  useEffect(() => {
    if (selectedRestaurant) {
      dispatch(fetchTickets(selectedRestaurant._id));
    }
  }, [selectedRestaurant, dispatch]);

  const handleRefund = (ticketId) => {
    dispatch(refundTicket(ticketId));
  };

  const handleCancel = (ticketId) => {
    dispatch(cancelTicket(ticketId));
  };

  const handleDelete = (ticketId) => {
    dispatch(deleteTicket(ticketId));
  };

  const getButtonText = (ticket) => {
    if (ticket.refunded) {
      return "Devuelto";
    } else if (!ticket.paid && !ticket.pendingPaymentAddedPOS) {
      return "Borrar";
    } else if (!ticket.paid && ticket.pendingPaymentAddedPOS) {
      return "Cancelar";
    } else if (ticket.paid) {
      return "Devolver";
    }
    return "";
  };

  const getButtonColor = (ticket) => {
    if (ticket.refunded) {
      return "#6c757d"; // Gris
    } else if (!ticket.paid && !ticket.pendingPaymentAddedPOS) {
      return "#343a40"; // Gris oscuro
    } else if (!ticket.paid && ticket.pendingPaymentAddedPOS) {
      return "#dc3545"; // Rojo
    } else if (ticket.paid) {
      return "#007bff"; // Azul
    }
    return "#6c757d"; // Gris por defecto
  };

  const sortedTickets = useMemo(() => {
    return [...tickets].sort((a, b) => {
      return new Date(b[sortBy]) - new Date(a[sortBy]);
    });
  }, [tickets, sortBy]);

  const paginatedTickets = useMemo(() => {
    return sortedTickets.slice(
      Math.max((page - 1) * itemsPerPage, 0),
      Math.min(page * itemsPerPage, sortedTickets.length)
    );
  }, [sortedTickets, page, itemsPerPage]);

  return (
    <>
      <GlobalStyle />

      <MainContainer>
        <Selector>
          <SelectRestaurantInput
            setSelectedRestaurant={setSelectedRestaurant}
          />
        </Selector>
        <TicketsContainer>
          <h2>Tickets</h2>
          <TableWrapper>
            <Table>
              <thead>
                <tr>
                  <th onClick={() => setSortBy("createdAt")}>Creado</th>
                  <th onClick={() => setSortBy("updatedAt")}>Actualizado</th>
                  <th>Zona</th>
                  <th>Mesa</th>
                  <th>Pedido</th>
                  <th>Persona</th>
                  <th>Entregado</th>
                  <th>Productos</th>
                  <th>Total</th>
                  <th>Propina</th>
                  <th>Pagado QR</th>
                  <th>Cobrar</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {paginatedTickets.map((ticket) => (
                  <tr key={ticket._id.$oid || ticket._id}>
                    <td>
                      {new Date(
                        ticket.createdAt.$date || ticket.createdAt
                      ).toLocaleString()}
                    </td>
                    <td>
                      {new Date(
                        ticket.updatedAt.$date || ticket.updatedAt
                      ).toLocaleString()}
                    </td>
                    <td>{ticket.zone_name || "N/A"}</td>
                    <td>{ticket.table_name || "N/A"}</td>
                    <td>{ticket.order_no}</td>
                    <td>{ticket.orderer_name || "N/A"}</td>
                    <td>{ticket.delivered ? "Yes" : "No"}</td>
                    <td>{ticket.foods ? ticket.foods.length : "0"}</td>
                    <td>{ticket.total}</td>
                    <td>{ticket.tip}</td>
                    <td>{ticket.paid ? "Yes" : "No"}</td>
                    <td>{ticket.pendingPaymentAddedPOS ? "Yes" : "No"}</td>
                    <td>
                      <Button
                        color={getButtonColor(ticket)}
                        onClick={() => {
                          if (ticket.paid) {
                            handleRefund(ticket._id.$oid || ticket._id);
                          } else if (
                            !ticket.paid &&
                            ticket.pendingPaymentAddedPOS
                          ) {
                            handleCancel(ticket._id.$oid || ticket._id);
                          } else if (
                            !ticket.paid &&
                            !ticket.pendingPaymentAddedPOS
                          ) {
                            handleDelete(ticket._id.$oid || ticket._id);
                          }
                        }}
                        disabled={ticket.refunded}
                      >
                        {getButtonText(ticket)}
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </TableWrapper>
          {loading ? (
            <StyledMessage>Cargando...</StyledMessage>
          ) : paginatedTickets.length === 0 ? (
            <StyledMessage>No hay tickets recientes 🤒</StyledMessage>
          ) : null}
          {!loading && paginatedTickets.length > 0 && (
            <Pagination
              currentPage={page}
              totalItems={tickets.length}
              itemsPerPage={itemsPerPage}
              onPageChange={setPage}
            />
          )}
          {error && <StyledError>{error}</StyledError>}
        </TicketsContainer>
      </MainContainer>
    </>
  );
};

export default Tickets;

const MainContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
  width: calc(100vw - 70px);
  height: 100%;
  max-height: 100vh;
  overflow: hidden;
`;

const Selector = styled.div`
  display: flex;
  position: fixed;
  top: 20px;
  right: 20px;
  flex-direction: column;
`;

const TicketsContainer = styled.div`
  padding: 20px;
`;

const TableWrapper = styled.div`
  overflow-x: auto;
  border-radius: 15px;
  box-shadow: 0px 0px 2px 0px rgba(145, 158, 171, 0.2),
    0px 12px 24px -4px rgba(145, 158, 171, 0.12);
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 15px;
  overflow: hidden;
  table-layout: fixed;

  thead {
    background-color: #f2f2f2;
  }

  tbody tr {
    border-bottom: 1px solid #ddd;
  }

  tbody tr:last-child td {
    border-bottom: none;
  }

  th,
  td {
    padding: 12px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }

  th {
    cursor: pointer;
    width: 80px;
  }

  td {
    width: 80px;
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  tr:hover {
    background-color: #f1f1f1;
  }
`;

const StyledMessage = styled.span`
  display: flex;
  justify-content: center;
  align-items: start;
  text-align: center;
  padding: 20px;
  font-size: 14px;
`;

const StyledError = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
  font-size: 14px;
  color: red;
`;

const Button = styled.button`
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  font-size: 14px;
  background-color: ${(props) => props.color};
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
  &:hover {
    background-color: ${(props) =>
      props.disabled
        ? props.color
        : props.color === "#007bff"
        ? "#0056b3"
        : props.color};
  }
`;
