import React, { useState } from 'react';
import { Card, Typography, CardContent, TextField, Button, Grid, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import PhotoCamera from '@mui/icons-material/PhotoCamera';

const Step2CreateBusiness = ({ formData, setFormData, errors }) => {

    const styles = {
        bannerContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '5rem',
        },
        hiddenInput: {
          display: 'none',
        },
        imageButton: {
          
        },
        image: {
          width: '100%',
          maxWidth: '800px',
        },
    };
  
    const handleChange = (e) => {
        const { name, value } = e.target;
        const nameParts = name.split('.');
        if (nameParts.length === 1) {
            setFormData(prevFormData => ({
                ...prevFormData,
                [name]: value
            }));
        } else {
            setFormData(prevFormData => ({
                ...prevFormData,
                [nameParts[0]]: {
                    ...prevFormData[nameParts[0]],
                    [nameParts[1]]: value
                }
            }));
        }
    };

    const [imageBase64, setImageBase64] = useState("");
    const handleImageChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            setFormData(prevFormData => ({
                ...prevFormData,
                banner: reader.result
            }));
            setImageBase64(reader.result);
        };
        if (file) {
            reader.readAsDataURL(file);
        }
      };

    const [tips, setTips] = useState([""]);
    const handleAddTipField = () => {
        setTips([...tips, ""]);
        setFormData(prevFormData => ({
            ...prevFormData,
            tips: {
                ...prevFormData.tips,
                [`tips-${tips.length}`]: ""
            }
        }));
        
    };
    const handleRemoveTipField = (index) => {
        const newTips = [...tips];
        newTips.splice(index, 1);
        setTips(newTips);
        setFormData(prevFormData => {
            const newTips = { ...prevFormData.tips };
            delete newTips[`tips-${index}`];
            return {
                ...prevFormData,
                tips: newTips
            };
        });
    };
    const handleInputTipChange = (event, index) => {
        const newTips = [...tips];
        newTips[index] = event.target.value;
        setTips(newTips);
        setFormData(prevFormData => ({
            ...prevFormData,
            tips: {
                ...prevFormData.tips,
                [`tips-${index}`]: event.target.value
            }
        }));
    };

    const [nutConditions, setNutConditions] = useState([""]);
    const handleAddNutCondsField = () => {
        setNutConditions([...nutConditions, ""]);
        setFormData(prevFormData => ({
            ...prevFormData,
            nutConditions: {
                ...prevFormData.nutConditions,
                [`nutConditions-${nutConditions.length}`]: ""
            }
        }));
    };
    const handleRemoveNutCondsField = (index) => {
        const newNutConditions = [...nutConditions];
        newNutConditions.splice(index, 1);
        setNutConditions(newNutConditions);
        setFormData(prevFormData => {
            const newNutConditions = { ...prevFormData.nutConditions };
            delete newNutConditions[`nutConditions-${index}`];
            return {
                ...prevFormData,
                nutConditions: newNutConditions
            };
        });
    };
    const handleInputNutCondsChange = (event, index) => {
        const newNutConditions = [...nutConditions];
        newNutConditions[index] = event.target.value;
        setNutConditions(newNutConditions);
        setFormData(prevFormData => ({
            ...prevFormData,
            nutConditions: {
                ...prevFormData.nutConditions,
                [`nutConditions-${index}`]: event.target.value
            }
        }));
    };

    /*
    banner: "",
    tips: { tip1: "1", tip2: "1,5", tip3: "2" },
    nutConditions: {
      nut1: "VEGANO",
      nut2: "VEGETARIANO",
      nut3: "NO PICANTES",
    },
    temporalCode: [],
    ticketLimit: { zone: "5", local: "15" }
    */

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h5" gutterBottom>
                    Crear Empresa
                </Typography>
                <Card variant="outlined" sx={{ mb: 3 }}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            Banner
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <input
                                    accept="image/*"
                                    id="contained-button-file"
                                    type="file"
                                    onChange={handleImageChange}
                                    style={styles.hiddenInput}
                                />
                                <div style={styles.bannerContainer}>
                                    <label htmlFor="contained-button-file">
                                        <Button 
                                            variant="contained" 
                                            component="span" 
                                            style={styles.imageButton}
                                        >
                                            Upload Image
                                        </Button>
                                    </label>
                                    {imageBase64 ? 
                                        <img src={imageBase64} alt="banner" style={styles.image} /> : 
                                        <span>
                                            No hay imagen seleccionada
                                        </span>
                                    }
                                </div>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <Card variant="outlined" sx={{ mb: 3 }}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            Propinas por defecto
                        </Typography>
                        <Grid container spacing={2}>
                            {tips.map((condition, index) => (
                                <Grid item xs={12} key={index}
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center"
                                    }}>
                                    <TextField
                                        style={{width: "100%"}}
                                        label={`Propina ${index + 1}`}
                                        value={condition}
                                        onChange={(event) => handleInputTipChange(event, index)}
                                        error={!!errors[`tips-${index}`]}
                                        helperText={errors[`tips-${index}`]}
                                    />
                                    { index === 0 ? null : <IconButton onClick={() => handleRemoveTipField(index)}>
                                        <DeleteIcon />
                                    </IconButton>}
                                </Grid>
                            ))}
                            <Grid item xs={12}>
                                <Button variant="contained" onClick={handleAddTipField}>
                                    Añadir propina
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <Card variant="outlined" sx={{ mb: 3 }}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            Condiciones nutricionales
                        </Typography>
                        <Grid container spacing={2}>
                            {nutConditions.map((condition, index) => (
                                <Grid item xs={12} key={index}
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center"
                                    }}>
                                    <TextField
                                        style={{width: "100%"}}
                                        label={`Condición nutricional ${index + 1}`}
                                        value={condition}
                                        onChange={(event) => handleInputNutCondsChange(event, index)}
                                        error={!!errors[`nutConditions-${index}`]}
                                        helperText={errors[`nutConditions-${index}`]}
                                    />
                                    { index === 0 ? null : <IconButton onClick={() => handleRemoveNutCondsField(index)}>
                                        <DeleteIcon />
                                    </IconButton>}
                                </Grid>
                            ))}
                            <Grid item xs={12}>
                                <Button variant="contained" onClick={handleAddNutCondsField}>
                                    Añadir condición
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <Card variant="outlined" sx={{ mb: 3 }}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            Límite de tickets sin entregar
                        </Typography>
                        <Grid container spacing={2}>
                        <Grid item xs={12}>
                                <TextField
                                variant="outlined"
                                fullWidth
                                label="Límite de tickets por zona" 
                                name="ticketLimit.zone"
                                value={formData.ticketLimit.zone}
                                onChange={handleChange}
                                error={!!errors['ticketLimit-zone']}
                                helperText={errors['ticketLimit-zone']}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                variant="outlined"
                                fullWidth
                                label="Límite de tickets por local"
                                name="ticketLimit.local"
                                value={formData.ticketLimit.local}
                                onChange={handleChange}
                                error={!!errors['ticketLimit-local']}
                                helperText={errors['ticketLimit-local']}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <Card variant="outlined" sx={{ mb: 3 }}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            Código temporal
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                variant="outlined"
                                fullWidth
                                label="Límite de tickets por zona" 
                                name="temporalCode"
                                value={formData.temporalCode}
                                onChange={handleChange}
                                error={!!errors['temporalCode']}
                                helperText={errors['temporalCode']}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );

};

export default Step2CreateBusiness;