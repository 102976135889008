import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from 'dayjs';
import { useMediaQuery } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { 
    getAllergenceAndNutCondApi, 
    putAllergenceAndNutCondApi, 
    updateFoodNameAPI,
    updateFoodPriceAPI,
    updateFoodPrice2API,
    updateFoodDescriptionAPI,
    updateFoodImageAPI,
    getCombinationsAndMenusApi,
    editProductWorkZoneApi,
    updateFoodSectionAPI,
    createFoodInCategoryAPI,
    deleteFoodApi,
    deleteFoodSectionAPI
} from "../services/food.service";
import { 
    getMenusAPI, 
    updateMenuAPI, 
    updateMenuFamilyAPI,
    deleteMenuAPI, 
    getCategoryFoodsApi,
    getCategoriesFoodsApi,
    createCategoryAPI,
    updateCategoryOpenningTimesAPI
} from '../services/menu.service';
import {
    getBusinessFamiliesAPI,
    createFamilyAPI,
    updateFamilyAPI,
    deleteFamilyAPI,
} from '../services/family.service';
import { 
    getRestaurantWorkZonesAPI,
    createRestaurantWorkZonesAPI,
    deleteRestaurantWorkZonesAPI,
    getBusinessLanguagesAPI,
} from '../services/business.service';
import Modal from "../components/modal";
import FamilyItem from "../components/editProducts/familyItem";
import AddFamilyItem from "../components/editProducts/addFamilyItem";
import CategoryItem from "../components/editProducts/categoryItem";
import AddCategoryItem from "../components/editProducts/addCategoryItem";
import ProductItem from "../components/editProducts/productItem";
import AddProductItem from "../components/editProducts/addProductItem";
import SelectRestaurantInput from "../components/select-restaurant-input";
import SelectLanguage from "../components/selectLanguage";
import AddLanguage from "../components/addLanguage";
import Coupons from "../components/editProducts/coupons";

import styled from 'styled-components';

import editProductsStyles from "../styles/editProducts";
const allergensAndNutCondModalStyles = "/styles/allergensAndNutConditionsModal.css";
const sectionModalStyles = "/styles/sectionModal.css";
const editCategoryModalStyles = "/styles/editCategoryModal.css";

const EditProducts = () => {
    const navigate = useNavigate();
    const selectedRestaurant = useParams()?.id;
    const isIOS = useMediaQuery('(platform: ios)');
    let lastSelectedRestaurant = null;

    const [selectedRestaurantFamilies, setSelectedRestaurantFamilies] = useState([]);
    const [selectedFamily, setSelectedFamily] = useState(null);
    const [selectedRestaurantCategories, setSelectedRestaurantCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedCategoryFoods, setSelectedCategoryFoods] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditFamilyOpen, setIsEditFamilyOpen] = useState(false);
    const [isEditFamilyId, setIsEditFamilyId] = useState(null);
    const [isEditCategoryOpen, setIsEditCategoryOpen] = useState(false);
    const [isEditCategoryId, setIsEditCategoryId] = useState(null);
    const [combinationsAndMenusData, setCombinationsAndMenusData] = useState(null);
    const [isConnectSectionOpen, setIsConnectSectionOpen] = useState(false);
    const [isConnectedSectionFoodsOpen, setIsConnectedSectionFoodsOpen] = useState(false);
    const [isSectionFoodsOpen, setIsSectionFoodsOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [actualCategoryId, setActualCategoryId] = useState(null);
    const [restaurantworkZonesChips, setRestaurantworkZonesChips] = useState([]);
    const [restaurantworkZonesInputValue, setRestaurantworkZonesInputValue] = useState('');
    const [sectionFoodAllergensOpenId, setSectionFoodAllergensOpenId] = useState(null);
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [cssStylesAllergensAndNutCondModalStyles, setCssStylesAllergensAndNutCondModalStyles] = useState('');
    const [cssStylesSectionModalStyles, setCssStylesSectionModalStyles] = useState('');
    const [cssStylesEditCategoryModalStyles, setCssStylesEditCategoryModalStyles] = useState('');
    
    const openModal = () => {
        setIsConnectSectionOpen(false);
        setIsConnectedSectionFoodsOpen(false);
        setIsSectionFoodsOpen(false);
        setIsModalOpen(true);
    }
    
    const closeModal = () => {
        setSectionFoodAllergensOpenId(null);
        setIsModalOpen(false);
        setModalContent(null);
        setCombinationsAndMenusData(null);
        setIsEditCategoryOpen(false);
        setIsEditCategoryId(null);
    }

    const getBusinessFamilies = async () => {
        try {
            const familiesRes = await getBusinessFamiliesAPI(selectedRestaurant);
            setSelectedRestaurantFamilies(familiesRes);
        } catch (error) {
            navigate('/admin-home');
        }
    }

    const getBusinessCategories = async () => {
        if (!selectedRestaurant) return;
        try {
            let language = await localStorage.getItem("i18nextLng") || 'es-ES';
            if (language) {
                await getBusinessLanguagesAPI(selectedRestaurant).then((response) => {
                    const filteredRes = response.filter((lang) => lang.selected);
                    setSelectedLanguages(filteredRes.map((lang) => lang.code));
                    let flag__ = filteredRes.length === 0 ? true : false;
                    filteredRes.forEach((lang) => {
                        if (lang.code === language) {
                            flag__ = true;
                        }
                    });
                    if(!flag__) {
                        language = filteredRes[0].code ? filteredRes[0].code : 'es-ES';
                        localStorage.setItem("i18nextLng", language);
                        window.location.reload();
                    }
                });
            }        
            const categoriesRes = await getMenusAPI(selectedRestaurant, language);
            setActualCategoryId(null);
            setSelectedRestaurantCategories(categoriesRes);            
            const workZonesRes = await getRestaurantWorkZonesAPI(selectedRestaurant);
            setRestaurantworkZonesChips(workZonesRes);
        } catch (error) {
            navigate('/admin-home');
        }
    }

    const handlerestaurantworkZonesInputChange = (event) => {
        setRestaurantworkZonesInputValue(event.target.value);
    };

    const handlerestaurantworkZonesInputKeyDown = async (event) => {
        if (event.key === 'Enter' && restaurantworkZonesInputValue) {
            if (!restaurantworkZonesChips.find((chip) => chip.name === restaurantworkZonesInputValue)) {
                await createRestaurantWorkZonesAPI(selectedRestaurant, restaurantworkZonesInputValue).then((res) => {
                    setRestaurantworkZonesChips(res);
                });
            setRestaurantworkZonesInputValue('');
            }
        }
    };

    const handlerestaurantworkZonesDeleteChip = (index) => {
        setModalContent(
            <div>
                <span>¿Está seguro de querer eliminar esta zona de trabajo?</span>
                <div>
                    <button className="button-primary" onClick={closeModal}>NO</button>
                    <button className="button-deny" onClick={() => deleteRestaurantworkZoneHandler(index)}>SI</button>
                </div>
            </div>
        );
        openModal();
    };

    const deleteRestaurantworkZoneHandler = (index) => {
        closeModal();
        deleteRestaurantWorkZonesAPI(selectedRestaurant, restaurantworkZonesChips[index]._id);
        setRestaurantworkZonesChips(restaurantworkZonesChips.filter((chip, i) => i !== index));
    };

    const editProductWorkZone = (workZoneId, foodId) => {
        editProductWorkZoneApi(workZoneId, foodId);
    }

    const editNameCategory = (title, menuId) => {
        const language = localStorage.getItem("i18nextLng") || 'es-ES';
        updateMenuAPI(title, menuId, selectedRestaurant, language);
        setSelectedRestaurantCategories(prev => 
            prev.map((category) => category._id === menuId ? {
                ...category,
                name: title
            } : category
        ));
    }

    const editImageCategory = (title, menuId, file) => {
        const language = localStorage.getItem("i18nextLng") || 'es-ES';
        updateMenuAPI(title, menuId, selectedRestaurant, language, file);
        setSelectedRestaurantCategories(prev => 
            prev.map((category) => category._id === menuId ? {
                ...category,
                image_url: file
            } : category
        ));
    }

    const deleteCategory = (menuId) => {
        setModalContent(
            <div>
                <span>¿Está seguro de querer eliminar esta categoría?</span>
                <button className="button-primary" onClick={closeModal}>NO</button>
                <button className="button-deny" onClick={() => deleteMenuHandler(menuId, selectedRestaurant)}>SI</button>
            </div>
        )
        openModal();
    }

    const editNameFamily = async (title, familyId) => {
        const language = localStorage.getItem("i18nextLng") || 'es-ES';
        const resp = await updateFamilyAPI(title, familyId, selectedRestaurant, language);
        setSelectedRestaurantFamilies(prev => 
            prev.map((family) => family._id === familyId ? resp : family)
        );
    }

    const editImageFamily = async (title, familyId, file) => {
        const language = localStorage.getItem("i18nextLng") || 'es-ES';
        const resp = await updateFamilyAPI(title, familyId, selectedRestaurant, language, file);
        setSelectedRestaurantFamilies(prev => 
            prev.map((family) => family._id === familyId ? resp : family)
        );
    }

    const deleteFamily = (familyId) => {
        setModalContent(
            <div>
                <span>¿Está seguro de querer eliminar esta familia?</span>
                <button className="button-primary" onClick={closeModal}>NO</button>
                <button className="button-deny" onClick={() => deleteFamilyHandler(familyId, selectedRestaurant)}>SI</button>
            </div>
        )
        openModal();
    }

    useEffect(() => {
        if (isEditFamilyOpen) {
            editFamilyModal(isEditFamilyId);
        }
    }, [isEditFamilyOpen, isEditFamilyId, selectedRestaurantFamilies]);

    const editFamily = (familyId) => {
        setIsEditFamilyId(familyId);
        setIsEditFamilyOpen(true);
    }

    const deleteFamilyHandler = (familyId, businessId) => {
        deleteFamilyAPI(familyId, businessId);
        closeModal();
        if (selectedFamily?._id === familyId) {
            setSelectedFamily(null);
        }
        setSelectedRestaurantFamilies(selectedRestaurantFamilies.filter((family) => family._id !== familyId));
    }

    const editImageFamilyHandler = () => {
        fileInputRef.current.click();
    }
  
    const handleFamilyFileInputChange = (e, family) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64Image = reader.result.split(',')[1];
                editImageFamily(family.name, family._id, base64Image);
                const index = selectedRestaurantFamilies.findIndex((f) => f._id === family._id);              
                let newFamilies = [...selectedRestaurantFamilies];                
                newFamilies[index] = {...newFamilies[index], image_url: base64Image};   
                setSelectedRestaurantFamilies(newFamilies);
                return base64Image;
            };
            reader.readAsDataURL(file);
        }
    };

    const editFamilyModal = (familyId) => {
        const family = selectedRestaurantFamilies.find((family) => family._id === familyId);
        if (!family) return;
        setModalContent(
            <div>
                <style dangerouslySetInnerHTML={{ __html: cssStylesEditCategoryModalStyles }}></style>
                <div className="main-container">
                    <div className="row jc-sb">
                        <div>
                            <input
                                type="text"
                                placeholder="Nombre de la familia"
                                defaultValue={family.name}
                                className="title"
                                onChange={(event) => setFamilyTitle(event.target.value, familyId)}
                            />
                        </div>  
                        <div>
                            <div className="openning-button" onClick={closeModal}>
                                <img src="/assets/icons/fi-rr-cross.svg" alt="close" className="icon"/>
                            </div>
                        </div>
                    </div>
                    <div className="row wrap">
                        <div className="column">
                            <div className="row g-30">
                                <div className="column w-130">
                                    { family.image_url ? 
                                        <img src={`data:image/jpeg;base64,${family.image_url}`} alt="family" className="image"/> 
                                        : 
                                        <div className="image-box">
                                            <img 
                                                src="/assets/icons/fi-rr-mode-landscape.svg" 
                                                alt="upload"
                                                className="icon"
                                            />
                                        </div>  
                                    }                                    
                                </div>
                                <div className="column w-210">
                                    <div className="row">
                                        <span className="image-description">
                                            Aquí puedes subir una foto o vídeo de la familia a mostrar
                                        </span>
                                    </div>
                                    <div className="row jc-sb">
                                        <div 
                                            onClick={editImageFamilyHandler}
                                            className="button"
                                        >
                                            <input
                                                type="file" 
                                                ref={fileInputRef} 
                                                style={{ display: 'none' }} 
                                                accept="image/*"
                                                onChange={(e) => handleFamilyFileInputChange(e, family)}
                                            />
                                            <img 
                                                src="/assets/icons/fi-rr-cloud-upload.svg" 
                                                alt="upload"
                                                className="icon"
                                            />
                                            <span>Subir</span>
                                        </div>
                                        <div 
                                            onClick={() => deleteFamily(family._id)}
                                            className="button"    
                                        >
                                            <img 
                                                src="/assets/icons/fi-rr-trash.svg" 
                                                alt="delete"
                                                className="icon"
                                            />
                                            <span>Borrar</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
        openModal();
    }

    const addFamilyItemHandler = () => {
        let name = "";
        let image = null;
        setModalContent(
            <div>
                <h3>Crear familia</h3>
                <input
                    type="text"
                    placeholder="Nombre de la familia"
                    onChange={(event) => name = event.target.value}
                />
                <input
                    type="file"
                    accept="image/*"
                    onChange={(event) => {
                        const file = event.target.files[0];
                        if (file) {
                            const reader = new FileReader();
                            reader.onloadend = () => {
                                const base64Image = reader.result.split(',')[1];
                                image = base64Image;
                            };
                            reader.readAsDataURL(file);
                        }
                    }}
                />
                <button className="button-primary" onClick={() => createFamilyItem(name, image)}>Crear familia</button>
                <button className="button-deny" onClick={closeModal}>Cancelar</button>
            </div>
        );
        openModal();
    }

    const createFamilyItem = (name, image) => {
        if (!name) {
            alert("El nombre de la familia es obligatorio");
            return;
        } else {
            const language = localStorage.getItem("i18nextLng") || 'es-ES';
            createFamilyAPI(selectedRestaurant, name, image, language).then((res) => {
                setSelectedRestaurantFamilies([...selectedRestaurantFamilies, res]);
                closeModal();
            });
        }

    }

    const timeoutIdRef = useRef(null);
    const fileInputRef = useRef();

    const setFamilyTitle = (title, familyId) => {
        if (timeoutIdRef.current) {
            clearTimeout(timeoutIdRef.current);
        }
        timeoutIdRef.current = setTimeout(() => {
            editNameFamily(title, familyId);
            timeoutIdRef.current = null; 
        }, 1300);
    }

    const setTitle = (title, menuId) => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
      timeoutIdRef.current = setTimeout(() => {
        editNameCategory(title, menuId);
        timeoutIdRef.current = null; 
      }, 1300);
    }

    const editImageCategoryHandler = () => {
        fileInputRef.current.click();
    }
  
    const handleFileInputChange = (e, category) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64Image = reader.result.split(',')[1];
                editImageCategory(category.name, category._id, base64Image);
                const index = selectedRestaurantCategories.findIndex((c) => c._id === category._id);              
                let newCategories = [...selectedRestaurantCategories];                
                newCategories[index] = {...newCategories[index], image_url: base64Image};   
                setSelectedRestaurantCategories(newCategories);
                return base64Image;
            };
            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {
        if (isEditCategoryOpen) {
            editCategoryModal(isEditCategoryId);
        }
    }, [isEditCategoryOpen, isEditCategoryId, selectedRestaurantCategories]);

    const editCategory = (menuId) => {
        setIsEditCategoryId(menuId);
        setIsEditCategoryOpen(true);
    }

    const setCategoryOpenning = (e, menuId, i) => {
        const category = selectedRestaurantCategories.find((category) => category._id === menuId);
        category.openning_times[i].start = e;
        setSelectedRestaurantCategories([...selectedRestaurantCategories]);
        updateCategoryOpenningTimesAPI(menuId, category.openning_times);
    }

    const setCategoryClosing = (e, menuId, i) => {
        const category = selectedRestaurantCategories.find((category) => category._id === menuId);
        category.openning_times[i].end = e;
        setSelectedRestaurantCategories([...selectedRestaurantCategories]);
        updateCategoryOpenningTimesAPI(menuId, category.openning_times)
    }

    const refreshCategoryTimes = (menuId, i) => {
        const category = selectedRestaurantCategories.find((category) => category._id === menuId);
        category.openning_times[i]= {
            start: null,
            end: null
        };
        setSelectedRestaurantCategories([...selectedRestaurantCategories]);
        updateCategoryOpenningTimesAPI(menuId, category.openning_times);
    }

    const deleteCategoryTimes = (menuId, i) => {
        const category = selectedRestaurantCategories.find((category) => category._id === menuId);
        category.openning_times.splice(i, 1);
        setSelectedRestaurantCategories([...selectedRestaurantCategories]);
        updateCategoryOpenningTimesAPI(menuId, category.openning_times);
    }

    const addCategoryOpenningTime = () => {
        const category = selectedRestaurantCategories.find((category) => category._id === isEditCategoryId);
        if (category.openning_times) {
            category.openning_times.push({start: null, end: null});
        } else {
            category.openning_times = [{start: null, end: null}];
        }
        setSelectedRestaurantCategories([...selectedRestaurantCategories]);
    }

    const editCategoryModal = (menuId) => {
        const category = selectedRestaurantCategories.find((category) => category._id === menuId);
        if (!category.family) category.family = '';
        setModalContent(
            <div>
                <style dangerouslySetInnerHTML={{ __html: cssStylesEditCategoryModalStyles }}></style>
                <div className="main-container">
                    <div className="row jc-sb">
                        <div>
                            <input
                                type="text"
                                placeholder="Nombre de la categoría"
                                defaultValue={category.name}
                                className="title"
                                onChange={(event) => setTitle(event.target.value, menuId)}
                            />
                        </div>  
                        <div>
                            {/* Category editable visibily */}
                            <div className="openning-button" onClick={closeModal}>
                                <img src="/assets/icons/fi-rr-cross.svg" alt="close" className="icon"/>
                            </div>
                        </div>
                    </div>
                    <div className="row wrap">
                        <div className="column">
                            <div className="row g-30">
                                <div className="column w-130">
                                    { category.image_url ? 
                                        <img src={`data:image/jpeg;base64,${category.image_url}`} alt="category" className="image"/> 
                                        : 
                                        <div className="image-box">
                                            <img 
                                                src="/assets/icons/fi-rr-mode-landscape.svg" 
                                                alt="upload"
                                                className="icon"
                                            />
                                        </div>  
                                    }                                    
                                </div>
                                <div className="column w-210">
                                    <div className="row">
                                        <span className="image-description">
                                            Aquí puedes subir una foto o vídeo de la categoría a mostrar
                                        </span>
                                    </div>
                                    <div className="row jc-sb">
                                        <div 
                                            onClick={editImageCategoryHandler}
                                            className="button"
                                        >
                                            <input
                                                type="file" 
                                                ref={fileInputRef} 
                                                style={{ display: 'none' }} 
                                                accept="image/*"
                                                onChange={(e) => handleFileInputChange(e, category)}
                                            />
                                            <img 
                                                src="/assets/icons/fi-rr-cloud-upload.svg" 
                                                alt="upload"
                                                className="icon"
                                            />
                                            <span>Subir</span>
                                        </div>
                                        <div 
                                            onClick={() => deleteCategory(category._id)}
                                            className="button"    
                                        >
                                            <img 
                                                src="/assets/icons/fi-rr-trash.svg" 
                                                alt="delete"
                                                className="icon"
                                            />
                                            <span>Borrar</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="column">
                                <div className="row">
                                    <FormControl 
                                        fullWidth
                                        className="category-modal__family-select-form"
                                    >
                                        <InputLabel 
                                            id="category-modal__family-select-label"
                                            className="category-modal__family-select-label"
                                        >
                                            Familia
                                        </InputLabel>
                                        <Select
                                            labelId="category-modal__family-select-label"
                                            value={category.family}
                                            label="Familia"
                                            onChange={(event) => {
                                                category.family = event.target.value;
                                                setSelectedRestaurantCategories([...selectedRestaurantCategories]);
                                                updateMenuFamilyAPI(category._id, event.target.value);
                                            }}
                                            className="category-modal__family-select"
                                            placeholder="Familia a la que pertenece"
                                            >
                                                { selectedRestaurantFamilies.map((family) => (
                                                    <MenuItem key={family._id} value={family._id}>{family.name}</MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div className="column">
                                <div className="row">
                                    <span className="openning-title">HORARIO DE APERTURA</span>
                                </div>
                                <div className="row">
                                    <div className="column g-10">
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            {
                                                category.openning_times && category.openning_times.length > 0 ? category.openning_times.map((time, i) => {
                                                    return <div className="row openning-time" key={i}>
                                                        {isIOS ? (
                                                            <>
                                                                <input
                                                                    type="time"
                                                                    value={category.openning_times[i].start ? dayjs(category.openning_times[i].start).format('HH:mm') : ''}
                                                                    onChange={(e) => setCategoryOpenning(dayjs(e.target.value), menuId, i)}
                                                                    className="time-picker" 
                                                                />
                                                                <input
                                                                    type="time"
                                                                    value={category.openning_times[i].end ? dayjs(category.openning_times[i].end).format('HH:mm') : ''}
                                                                    onChange={(e) => setCategoryClosing(dayjs(e.target.value), menuId, i)}
                                                                    className="time-picker" 
                                                                />
                                                            </>
                                                        ) : (
                                                            <>
                                                                <TimePicker
                                                                    className="time-picker"
                                                                    value={category.openning_times[i].start ? dayjs(category.openning_times[i].start) : null}
                                                                    onChange={(e) => setCategoryOpenning(e, menuId, i)}
                                                                    minTime={i > 0 && category.openning_times[i - 1].end ? dayjs(category.openning_times[i - 1].end) : null}
                                                                    maxTime={category.openning_times[i].end ? dayjs(category.openning_times[i].end) : null}
                                                                />
                                                                <TimePicker
                                                                    className="time-picker"
                                                                    value={category.openning_times[i].end ? dayjs(category.openning_times[i].end) : null}
                                                                    onChange={(e) => setCategoryClosing(e, menuId, i)}
                                                                    minTime={category.openning_times[i].start ? dayjs(category.openning_times[i].start) : null}
                                                                    maxTime={i < category.openning_times.length && category.openning_times[i + 1] ? dayjs(category.openning_times[i + 1].start) : null}
                                                                />
                                                            </>
                                                        )}
                                                        <div className="openning-button" onClick={() => deleteCategoryTimes(menuId, i)}>
                                                            <img src="/assets/icons/fi-rr-trash-white.svg" alt="delete" className="icon"/>
                                                        </div>
                                                        <div className="openning-button" onClick={() => refreshCategoryTimes(menuId, i)}>
                                                            <img src="/assets/icons/fi-rr-time-past.svg" alt="clock" className="icon"/>
                                                        </div>
                                                    </div>
                                                }) : null
                                            }
                                        </LocalizationProvider>
                                        <div>
                                            <img src="/assets/icons/fi-rr-add.svg" alt="plus" className="icon" onClick={addCategoryOpenningTime}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
        openModal();
    }

    const deleteMenuHandler = (menuId, businessId) => {
        deleteMenuAPI(menuId, businessId);
        closeModal();
        if (selectedCategory?._id === menuId) {
            setSelectedCategory(null);
            setSelectedCategoryFoods([]);
        }
        setSelectedRestaurantCategories(selectedRestaurantCategories.filter((category) => category._id !== menuId));
    }

    const editProductName = (title, foodId) => {
        const language = localStorage.getItem("i18nextLng") || 'es-ES';
        updateFoodNameAPI(title, foodId, language);
    }

    const editProductPrice = (title, foodId) => {
        updateFoodPriceAPI(title, foodId);
    }

    const editProductPrice2 = (title, foodId) => {
        updateFoodPrice2API(title, foodId);
    }

    const editProductDescription = (title, foodId) => {
        const language = localStorage.getItem("i18nextLng") || 'es-ES';
        updateFoodDescriptionAPI(title, foodId, language);
    }

    const editProductImage = (file, foodId) => {
        updateFoodImageAPI(file, foodId);
    }

    const saveAllergensAndNutConditions = (food) => {
        food.allergens = food.allergens.filter((allergen) => allergen.value === true);
        putAllergenceAndNutCondApi(food).then((res) => {
            closeModal();
        });
    }

    const saveCombinationsAndMenus = () => {
        if (combinationsAndMenusData?.companion && combinationsAndMenusData.companion[0]) {
            const temp = {...combinationsAndMenusData.companion[0]};
            if (temp?.categories) {
                temp.categories = temp.categories.map((category) => {
                    category.foods = category.foods.filter((food) => food.selected === true);
                    return category;
                });
                temp.categories = temp.categories.filter((category) => category.foods.length > 0);
            } else {
                temp.categories = [];
            }
            temp.foods = temp?.foods ? temp.foods : [];
            temp.foods.forEach((food) => {
                if (food?.allergens) food.allergens = food.allergens.filter((all) => all.value).map((all) => all._id);
            });
            const language = localStorage.getItem("i18nextLng") || 'es-ES';
            updateFoodSectionAPI(combinationsAndMenusData.foodId, temp, selectedRestaurant, language).then(() => {
                if (selectedCategory) {
                    getCategoryFoodsApi(selectedCategory._id, language).then((res) => {
                        setSelectedCategoryFoods(res);
                        setActualCategoryId(selectedCategory._id);
                    });
                }
                closeModal();
            });
        }
        else  {
            alert("Seleccione al menos una categoría o un producto para guardar los cambios");
            return;
        }
    }

    const editConnectedCategories = () => {
        const temp = combinationsAndMenusData.businessMenus.menus.filter((menu) => menu.selected);
        if (temp.length > 0) {
            const ids = temp.map(c => c._id);        
            getCategoriesFoodsApi(ids).then((res) => {
                res.forEach(combined => combined.foods.forEach(food => food.selected = true));
                if (combinationsAndMenusData?.companion && combinationsAndMenusData?.companion[0]) {
                    const cm = {...combinationsAndMenusData};
                    cm.companion[0].categories = res;
                    setCombinationsAndMenusData(cm);
                } else {
                    setCombinationsAndMenusData({...combinationsAndMenusData, companion: [{categories: res}]});
                }            
            });
        } else {
            if (combinationsAndMenusData?.companion && combinationsAndMenusData?.companion[0]) {
                const cm = {...combinationsAndMenusData};
                cm.companion[0].categories = [];
                setCombinationsAndMenusData(cm);
            } else {
                setCombinationsAndMenusData({...combinationsAndMenusData, companion: [{categories: []}]});
            }
        }
    }

    const addCategoryItemHandler = () => {
        let name = "";
        let image = null;
        setModalContent(
            <div>
                <h3>Crear categoría</h3>
                <input
                    type="text"
                    placeholder="Nombre de la categoría"
                    onChange={(event) => name = event.target.value}
                />
                <input
                    type="file"
                    accept="image/*"
                    onChange={(event) => {
                        const file = event.target.files[0];
                        if (file) {
                            const reader = new FileReader();
                            reader.onloadend = () => {
                                const base64Image = reader.result.split(',')[1];
                                image = base64Image;
                            };
                            reader.readAsDataURL(file);
                        }
                    }}
                />
                <button className="button-primary" onClick={() => createCategoryItem(name, image)}>Crear categoría</button>
                <button className="button-deny" onClick={closeModal}>Cancelar</button>
            </div>
        );
        openModal();
    }

    const createCategoryItem = (name, image) => {
        if (!name) {
            alert("El nombre de la categoría es obligatorio");
            return;
        } else {
            const language = localStorage.getItem("i18nextLng") || 'es-ES';
            createCategoryAPI(selectedRestaurant, name, image, language).then((res) => {
                setSelectedRestaurantCategories([...selectedRestaurantCategories, res]);
                closeModal();
            });
        }

    }

    const FormWrapper = styled.div`
        display: flex;
        flex-direction: column;
        gap: 15px;
    `;
    
    const Title = styled.h3`
        font-size: 1.5rem;
        color: #333;
        text-align: center;
        margin-bottom: 20px;
    `;
    
    const InputField = styled.input`
        padding: 10px;
        font-size: 1rem;
        border: 1px solid #ccc;
        border-radius: 8px;
        width: 100%;
        box-sizing: border-box;
        transition: border-color 0.3s;
    
        &:focus {
            border-color: #007bff;
            outline: none;
        }
    `;
    
    const ButtonPrimary = styled.button`
        background-color: #007bff;
        color: white;
        padding: 10px;
        border: none;
        border-radius: 8px;
        font-size: 1rem;
        cursor: pointer;
        width: 100%;
        box-sizing: border-box;
    
        &:hover {
            background-color: #0056b3;
        }
    `;
    
    const ButtonDeny = styled(ButtonPrimary)`
        background-color: #dc3545;
    
        &:hover {
            background-color: #c82333;
        }
    `;
    
    const FileInput = styled(InputField)`
        padding: 5px;
    `;
    
    const ButtonWrapper = styled.div`
        display: flex;
        justify-content: space-between;
        gap: 10px;
    `;
    
    const PriceWrapper = styled.div`
        display: flex;
        gap: 10px;
        width: 100%;
        box-sizing: border-box;
    
        ${InputField} {
            flex: 1;
        }
    `;
    
    const addProductItemHandler = () => {
        const food = {
            name: '',
            price: '',
            price_2: '',
            description: '',
            image_url: ''
        };
    
        setModalContent(
            <FormWrapper>
                <Title>Crear producto</Title>
                <InputField
                    type="text"
                    placeholder="Nombre del producto"
                    onChange={(event) => food.name = event.target.value}
                />
                <InputField
                    type="text"
                    placeholder="Descripción del producto"
                    onChange={(event) => food.description = event.target.value}
                />
                <PriceWrapper>
                    <InputField
                        type="text"
                        placeholder="Precio del producto"
                        onChange={(event) => {
                            const formattedPrice = event.target.value.replace(',', '.');
                            food.price = formattedPrice;
                        }}
                    />
                    <InputField
                        type="text"
                        placeholder="Precio en horario especial"
                        onChange={(event) => {
                            const formattedPrice2 = event.target.value.replace(',', '.');
                            food.price_2 = formattedPrice2;
                        }}
                    />
                </PriceWrapper>
                <FileInput
                    type="file"
                    accept="image/*"
                    onChange={(event) => {
                        const file = event.target.files[0];
                        if (file) {
                            const reader = new FileReader();
                            reader.onloadend = () => {
                                const base64Image = reader.result.split(',')[1];
                                food.image_url = base64Image;
                            };
                            reader.readAsDataURL(file);
                        }
                    }}
                />
                <ButtonWrapper>
                    <ButtonPrimary onClick={() => createProductItem(food)}>
                        Crear producto
                    </ButtonPrimary>
                    <ButtonDeny onClick={() => {
                        if (window.confirm('¿Estás seguro de que deseas cancelar?')) {
                            closeModal();
                        }
                    }}>
                        Cancelar
                    </ButtonDeny>
                </ButtonWrapper>
            </FormWrapper>
        );
        openModal();
    };

    const createProductItem = (food) => {
        if (!food.name) {
            alert("El nombre del producto es obligatorio");
            return;
        } else if (!food.price) {
            alert("El precio del producto es obligatorio");
            return;
        } else {
            const language = localStorage.getItem("i18nextLng") || 'es-ES';
            createFoodInCategoryAPI(selectedCategory._id, food, language, selectedRestaurant).then((res) => {
                setSelectedCategory(res);
                closeModal();
            });
        }
    }

    const combinationsAndMenusModal = (companionId, foodId) => {
        const language = localStorage.getItem("i18nextLng") || 'es-ES';
        getCombinationsAndMenusApi(selectedRestaurant, foodId, companionId, language).then((res) => {
            setCombinationsAndMenusData(res);
            openModal();
        });
    }

    const addNewProductToSection = () => {
        const food = {
            name: '',
            price_extra: '',
            description: ''
        };
        if (combinationsAndMenusData?.companion && combinationsAndMenusData?.companion[0]) {
            const cm = {...combinationsAndMenusData};
            cm.companion[0]?.foods ? cm.companion[0].foods.push(food) : cm.companion[0].foods = [food];
            setCombinationsAndMenusData(cm);
        } else {
            setCombinationsAndMenusData({...combinationsAndMenusData, companion: [{foods: [food]}]});
        }
        const temp = {...isSectionFoodsOpen};
        setIsSectionFoodsOpen(temp);
    }

    const deleteProductToSection = (foodId, index) => {
        if (index === sectionFoodAllergensOpenId) setSectionFoodAllergensOpenId(null);
        if (combinationsAndMenusData?.companion && combinationsAndMenusData?.companion[0]) {
            const cm = {...combinationsAndMenusData};
            cm.companion[0].foods ? foodId ? cm.companion[0].foods.splice(cm.companion[0].foods.findIndex(f => f?._id === foodId), 1) : cm.companion[0].foods.splice(index, 1) : cm.companion[0].foods = [];
            setCombinationsAndMenusData(cm);
        }
    }

    const deleteProductHandler = (foodId) => {
        setModalContent(
            <div>
                <span>¿Está seguro de querer eliminar este producto?</span>
                <button className="button-primary" onClick={closeModal}>NO</button>
                <button className="button-deny" onClick={() => deleteProduct(foodId)}>SI</button>
            </div>
        )
        openModal();
    }

    const deleteProduct = (foodId) => {
        const language = localStorage.getItem("i18nextLng") || 'es-ES';
        deleteFoodApi(foodId, selectedCategory._id, selectedRestaurant, language).then((res) => {
            closeModal();
            setSelectedCategoryFoods(selectedCategoryFoods.filter((food) => food._id !== foodId));
        });
    }

    const deleteSectionHandler = (combinationsAndMenusData) => {
        setModalContent(
            <div>
                <span>¿Está seguro de querer eliminar esta sección?</span>
                <button className="button-primary" onClick={closeModal}>NO</button>
                <button className="button-deny" onClick={() => deleteSection(combinationsAndMenusData)}>SI</button>
            </div>
        )
        openModal();
    }

    const deleteSection = (combinationsAndMenusData) => {
        deleteFoodSectionAPI(combinationsAndMenusData.foodId, combinationsAndMenusData.companion[0]._id, selectedRestaurant).then(() => {
            if (selectedCategory) {
                const language = localStorage.getItem("i18nextLng") || 'es-ES';
                getCategoryFoodsApi(selectedCategory._id, language).then((res) => {
                    setSelectedCategoryFoods(res);
                    setActualCategoryId(selectedCategory._id);
                });
            }
            closeModal();
        });
    }

    const sectionFoodAllergensHandler = (food, i) => {
        if (sectionFoodAllergensOpenId === i) {
            setSectionFoodAllergensOpenId(null);
        } else if (!combinationsAndMenusData.companion[0].foods[i].nutConditions && !combinationsAndMenusData.companion[0].foods[i].allergens) {
            getAllergenceAndNutCondApi(selectedRestaurant, food._id ? food._id : null).then((res) => {
                const updatedCombinationsAndMenusData = { ...combinationsAndMenusData };
                updatedCombinationsAndMenusData.companion[0].foods[i].nutConditions = res.nutConditions;
                updatedCombinationsAndMenusData.companion[0].foods[i].allergens = res.allergens;
                setCombinationsAndMenusData(updatedCombinationsAndMenusData);
                setSectionFoodAllergensOpenId(i);
            });
        } else {
            setSectionFoodAllergensOpenId(i);
        }
    }

    const handleWorkZoneSelectorChange = (event, food) => {
        if (event.target.value === '-1') return;
        const updatedCombinationsAndMenusData = { ...combinationsAndMenusData };
        updatedCombinationsAndMenusData.companion[0].foods.forEach((f, i) => {
            if (f._id === food._id) {
                updatedCombinationsAndMenusData.companion[0].foods[i].work_zone = event.target.value;
            }
        });
        setCombinationsAndMenusData(updatedCombinationsAndMenusData);
    }

    useEffect(() => {
        fetch(allergensAndNutCondModalStyles)
            .then(response => response.text())
            .then(data => {
            setCssStylesAllergensAndNutCondModalStyles(data);
        });
        fetch(sectionModalStyles)
            .then(response => response.text())
            .then(data => {
                setCssStylesSectionModalStyles(data);
        });
        fetch(editCategoryModalStyles)
            .then(response => response.text())
            .then(data => {
                setCssStylesEditCategoryModalStyles(data);
        });
    }, []);

    useEffect(() => {
        if (combinationsAndMenusData?.businessMenus) {
            setModalContent(
                <div style={{maxHeight: '70vh'}}>
                    <style dangerouslySetInnerHTML={{ __html: cssStylesSectionModalStyles }}></style>
                    {  combinationsAndMenusData?.companion && combinationsAndMenusData?.companion[0] && combinationsAndMenusData?.companion[0]?._id ? 
                        <button className="button-deny button-delete-section" onClick={() => deleteSectionHandler(combinationsAndMenusData)}>Eliminar sección</button>
                    : null}
                    <div className="container">
                        <input 
                            type="text"
                            className="section__name-input"
                            placeholder="Nombre de la sección" 
                            value={
                                combinationsAndMenusData?.companion && combinationsAndMenusData?.companion[0] 
                                ? combinationsAndMenusData.companion[0].name 
                                : ""
                            } 
                            onChange={(event) => {
                                let updatedCombinationsAndMenusData = { ...combinationsAndMenusData };
                                if (!updatedCombinationsAndMenusData.companion || updatedCombinationsAndMenusData.companion.length === 0) {
                                    updatedCombinationsAndMenusData.companion = [{
                                        name: event.target.value,
                                        categories: [],
                                        foods: []
                                    }];
                                } else {
                                    updatedCombinationsAndMenusData.companion[0].name = event.target.value;
                                }                            
                                setCombinationsAndMenusData(updatedCombinationsAndMenusData);
                            }}
                        />
                        <button className="button-primary" onClick={() => setIsConnectSectionOpen(!isConnectSectionOpen)}>
                            Mostrar categorías existentes
                        </button>
                        {isConnectSectionOpen ? <div className="toggle-list">
                            {combinationsAndMenusData.businessMenus.menus.map((menu) => {
                                return <div key={menu._id} className="toggle-item">
                                    <span className="item-name">{menu.name}</span>
                                    <label className="switch">
                                        <input 
                                            type="checkbox" 
                                            defaultChecked={menu.selected} 
                                            onChange={() => menu.selected = !menu.selected} 
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            })}
                            <button className="button-blue" onClick={() => editConnectedCategories()}>
                                Conectar categorías seleccionadas
                                </button>
                            </div> 
                        : null}
                        <button className="button-primary" onClick={() => setIsConnectedSectionFoodsOpen(!isConnectedSectionFoodsOpen)}>Mostrar productos de categorias conectadas</button>
                        {isConnectedSectionFoodsOpen ?
                            <div className="toggle-list">
                                { combinationsAndMenusData?.companion && 
                                    combinationsAndMenusData?.companion[0] && 
                                    combinationsAndMenusData?.companion[0]?.categories && 
                                    combinationsAndMenusData?.companion[0]?.categories.length > 0 ? 
                                    combinationsAndMenusData.companion[0].categories.map((category) => {
                                    return category.foods.map((food) => {
                                        return <div className="toggle-item" key={category._id+food._id}>
                                            <span className="item-name">{food.name}</span>
                                            <input 
                                                type="text" 
                                                className="price"
                                                defaultValue={food.price_extra ? food.price_extra : ''} 
                                                onChange={(event) => food.price_extra = event.target.value} 
                                            />
                                            <label className="switch">
                                                <input 
                                                    type="checkbox" 
                                                    defaultChecked={food.selected}
                                                    onChange={() => food.selected = (food.selected === true || food.selected === false) ? !food.selected : false} 
                                                />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                    });
                                }) : (
                                    <div>
                                        <span>No hay categorías conectadas</span>
                                    </div>
                                )}
                            </div>
                        : null}
                        <button className="button-primary" onClick={() => setIsSectionFoodsOpen(!isSectionFoodsOpen)}>Mostrar productos</button>
                        {isSectionFoodsOpen ?
                            <div className="product-container">
                                { combinationsAndMenusData?.companion && 
                                    combinationsAndMenusData?.companion[0] && 
                                    combinationsAndMenusData?.companion[0]?.foods && 
                                    combinationsAndMenusData?.companion[0]?.foods.length > 0 ? 
                                    combinationsAndMenusData.companion[0].foods.map((food, i) => {
                                    return <div key={combinationsAndMenusData.companion[0]._id ? (combinationsAndMenusData.companion[0]._id + food._id + i) : i} className="card">
                                        <div className="close-btn" onClick={() => deleteProductToSection(food._id, i)}>X</div>
                                        <input type="text" className="title" placeholder="Nombre" value={food.name ? food.name : ''} 
                                            onChange={(event) => {
                                                const updatedCombinationsAndMenusData = { ...combinationsAndMenusData };
                                                updatedCombinationsAndMenusData.companion[0].foods[i].name = event.target.value;
                                                setCombinationsAndMenusData(updatedCombinationsAndMenusData);
                                            }} 
                                        />
                                        <div className="space-between-container">
                                            <input type="text" className="price-tag" placeholder="Precio" value={food.price_extra ? food.price_extra : ''}
                                                onChange={(event) => {
                                                    let inputPrice = event.target.value.replace(',', '.').replace(/[^\d.]/g, '');
                                                    if (/^\d*(\.\d{0,2})?$/.test(inputPrice)) {
                                                    inputPrice = isNaN(inputPrice) ? '' : inputPrice;
                                                    setCombinationsAndMenusData(prevState => {
                                                        const updatedState = { ...prevState };
                                                        updatedState.companion[0].foods[i].price_extra = inputPrice;
                                                        return updatedState;
                                                    });
                                                    }
                                                }}
                                            />
                                            <button className="button-alergens" onClick={() => sectionFoodAllergensHandler(food, i)}>ALERGENOS</button>
                                        </div>
                                        <select style={editProductsStyles.workZoneInput} value={food.work_zone} onChange={(event) => handleWorkZoneSelectorChange(event, food)}>
                                            <option value="-1">Seleccione una zona de trabajo</option>
                                            {restaurantworkZonesChips.map(zone => (
                                                <option key={zone._id} value={zone._id}>{zone.name}</option>
                                            ))}
                                        </select>
                                        { sectionFoodAllergensOpenId === i ? 
                                            <div>
                                                <style dangerouslySetInnerHTML={{ __html: cssStylesAllergensAndNutCondModalStyles }}></style>
                                                <ul className="allergen-list">
                                                    <h2>Condiciones nutricionales</h2>
                                                    {combinationsAndMenusData.companion[0].foods[i].nutConditions.map((condition, i) => {
                                                        return <li className="allergen-item" key={i}>
                                                            <div className="allergen-name">
                                                                <span>{condition.name}</span>
                                                            </div>
                                                            <label className="switch">
                                                                <input type="checkbox" defaultChecked={condition.value} onChange={() => condition.value = !condition.value} />
                                                                <span className="slider round"></span>
                                                            </label>
                                                        </li>
                                                    })}
                                                    <h2>Alérgenos</h2>
                                                    {combinationsAndMenusData.companion[0].foods[i].allergens.map((allergen, i) => {
                                                        return <li className="allergen-item" key={i}>
                                                            <div className="allergen-name">
                                                                <img src={`data:image/jpg;base64,${allergen.image_url}`} alt={allergen.name} className="allergen-icon"/>
                                                                <span>{allergen.name}</span>
                                                            </div>
                                                            <label className="switch">
                                                                <input type="checkbox" defaultChecked={allergen.value} onChange={() => allergen.value = !allergen.value} />
                                                                <span className="slider round"></span>
                                                            </label>
                                                        </li>
                                                    })}
                                                </ul>
                                            </div>
                                        : null}
                                        <textarea type="text" className="description-input" placeholder="DESCRIPCIÓN" value={food.description ? food.description : ''} 
                                            onChange={(event) => {
                                                const updatedCombinationsAndMenusData = { ...combinationsAndMenusData };
                                                updatedCombinationsAndMenusData.companion[0].foods[i].description = event.target.value;
                                                setCombinationsAndMenusData(updatedCombinationsAndMenusData);
                                            }}
                                        ></textarea>
                                    </div>
                                }) : (
                                    <div>
                                        <span>No hay productos</span>
                                    </div>
                                )}
                                <button className="button-primary" onClick={() => addNewProductToSection()}>Añadir producto</button>
                            </div>
                        : null}
                    </div>
                    <div className="buttons">
                        <button className="button-deny" onClick={closeModal}>Cancelar</button>
                        <button className="button-primary" onClick={() => saveCombinationsAndMenus()}>Guardar</button>
                    </div>
                </div>
            );
        }
    }, [combinationsAndMenusData, isConnectSectionOpen, isConnectedSectionFoodsOpen, isSectionFoodsOpen, sectionFoodAllergensOpenId, cssStylesSectionModalStyles, cssStylesAllergensAndNutCondModalStyles]);

    const allergensAndNutCondModal = (foodId) => {
        getAllergenceAndNutCondApi(selectedRestaurant, foodId).then((res) => {
            setModalContent(
                <div>
                    <div>
                        <style dangerouslySetInnerHTML={{ __html: cssStylesAllergensAndNutCondModalStyles }}></style>
                        <ul className="allergen-list">
                            <h2>Condiciones nutricionales</h2>
                            {res.nutConditions.map((condition, i) => {
                                return <li className="allergen-item" key={i}>
                                    <div className="allergen-name">
                                        <span>{condition.name}</span>
                                    </div>
                                    <label className="switch">
                                        <input type="checkbox" defaultChecked={condition.value} onChange={() => condition.value = !condition.value} />
                                        <span className="slider round"></span>
                                    </label>
                                </li>
                            })}
                            <h2>Alérgenos</h2>
                            {res.allergens.map((allergen, i) => {
                                return <li className="allergen-item" key={i}>
                                    <div className="allergen-name">
                                        <img src={`data:image/jpg;base64,${allergen.image_url}`} alt={allergen.name} className="allergen-icon"/>
                                        <span>{allergen.name}</span>
                                    </div>
                                    <label className="switch">
                                        <input type="checkbox" defaultChecked={allergen.value} onChange={() => allergen.value = !allergen.value} />
                                        <span className="slider round"></span>
                                    </label>
                                </li>
                            })}
                        </ul>
                    </div>
                    <div className="buttons">
                            <button className="button-deny" onClick={closeModal}>Cancelar</button>
                            <button className="button-primary" onClick={() => saveAllergensAndNutConditions(res)}>Guardar</button>
                    </div>
                </div>
            )
            openModal();
        });
    }

    useEffect(() => {
        if (selectedRestaurant && selectedRestaurant !== lastSelectedRestaurant) {
            lastSelectedRestaurant = selectedRestaurant;
            getBusinessFamilies();
            getBusinessCategories();
        }
    }, [selectedRestaurant]);

    useEffect(() => {
        if (!selectedRestaurant) return;
        if (selectedCategory) {
            const language = localStorage.getItem("i18nextLng") || 'es-ES';
            getCategoryFoodsApi(selectedCategory._id, language).then((res) => {
                setSelectedCategoryFoods(res);
                setActualCategoryId(selectedCategory._id);
            }).catch((error) => {
                navigate('/admin-home');
            });
        }
    }, [selectedCategory]);

    const reloadCategories = () => {
        getBusinessCategories();
    };

    const setSelectedRestaurant = (restaurant) => {
        navigate(`/edit-products/${restaurant._id}`);
    }

    return <div style={editProductsStyles.main}>
        <div style={editProductsStyles.header}>
            <h2>Editar productos</h2> 
            <SelectRestaurantInput setSelectedRestaurant={setSelectedRestaurant} />
        </div>
        { selectedRestaurant ? (
            <div style={editProductsStyles.header}>
                <AddLanguage businessId={selectedRestaurant} setSelectedItemsHandler={setSelectedLanguages}/>
                { selectedLanguages.length > 0 ? <SelectLanguage reloadCategories={reloadCategories} selectedLanguages={selectedLanguages} /> : null }
            </div>
        ) : null}
        { selectedRestaurant && selectedLanguages.length > 0 ?
            <div>
                <div>
                    <div style={editProductsStyles.workZoneContainer}>
                        <span style={editProductsStyles.workZoneTitle}>Zonas de trabajo</span>
                        <input
                            type="text"
                            placeholder="Introducir nueva zona"
                            style={editProductsStyles.restaurantWorkZonesInput}
                            value={restaurantworkZonesInputValue}
                            onChange={handlerestaurantworkZonesInputChange}
                            onKeyDown={handlerestaurantworkZonesInputKeyDown}
                        />
                    </div>
                    <div style={editProductsStyles.restaurantWorkZonesChips}>
                        {restaurantworkZonesChips ? restaurantworkZonesChips.map((chip, index) => (
                            <div key={index} onClick={() => handlerestaurantworkZonesDeleteChip(index)} style={editProductsStyles.restaurantWorkZonesChip}>
                                {chip.name}
                                <button style={editProductsStyles.restaurantWorkZonesChipButton} onClick={() => handlerestaurantworkZonesDeleteChip(index)}>X</button>
                            </div>
                        )) : null}
                    </div>
                </div>
                <div style={editProductsStyles.verticalFlex}>
                    <span style={editProductsStyles.verticalFlexTitle}>Familias</span>
                    <div>
                        { selectedRestaurantFamilies.map((family) => {
                            return <FamilyItem 
                                key={family._id}
                                title={family.name} 
                                familyId={family._id} 
                                businessId={selectedRestaurant} 
                                base64Image={family.image_url} 
                                editNameFamily={editNameFamily}
                                deleteFamily={deleteFamily}
                                editFamily={editFamily}
                                onClickFamily={() => setSelectedFamily(family)}
                            />
                        })}
                        <AddFamilyItem addFamilyItemHandler={addFamilyItemHandler} />
                    </div>
                </div>
                <div style={editProductsStyles.verticalFlex}>
                    <span style={editProductsStyles.verticalFlexTitle}>Categorías</span>
                    <div>
                        { selectedRestaurantCategories.map((category) => {
                            return <CategoryItem 
                                key={category._id}
                                title={category.name} 
                                menuId={category._id} 
                                businessId={selectedRestaurant} 
                                base64Image={category.image_url} 
                                editNameCategory={editNameCategory}
                                deleteCategory={deleteCategory}
                                editCategory={editCategory}
                                onClickCategory={() => setSelectedCategory(category)}
                            />
                        })}
                        <AddCategoryItem addCategoryItemHandler={addCategoryItemHandler} />
                    </div>
                </div>
                { selectedCategory && selectedCategory._id === actualCategoryId ?
                    <div>
                        <div style={editProductsStyles.products_container}>
                            { selectedCategoryFoods.map((food) => {
                                return <ProductItem
                                    key={food._id}
                                    food={food}
                                    editProductName={editProductName}
                                    editProductPrice={editProductPrice}
                                    editProductPrice2={editProductPrice2}
                                    editProductDescription={editProductDescription}
                                    editProductImage={editProductImage}
                                    combinationsAndMenusModal={combinationsAndMenusModal}
                                    allergensAndNutCondModal={allergensAndNutCondModal}
                                    workZones={restaurantworkZonesChips}
                                    editProductWorkZone={editProductWorkZone}
                                    deleteProduct={deleteProductHandler}
                                />
                            })}
                            <AddProductItem addProductItemHandler={addProductItemHandler} />
                        </div>    
                    </div>
                    : null
                }
                <Coupons businessId={selectedRestaurant} />
            </div>
            : null    
        }
            <Modal isOpen={isModalOpen}>
                { modalContent }
            </Modal>
        
    </div>
}

export default EditProducts;