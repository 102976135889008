import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Stepper, Step, StepLabel, Button, Typography } from '@mui/material';
import { newClientAPI } from '../services/user.service';
import Step1CreateBusiness from '../components/step1CreateBusiness';
import Step2CreateBusiness from '../components/step2CreateBusiness';
const Step1CreateBusinessMemo = React.memo(Step1CreateBusiness);
const Step2CreateBusinessMemo = React.memo(Step2CreateBusiness);

const steps = ['Información de la empresa', 'Configuración de preferencias'];

const StepContent = ({ step, formData, setFormData, errors }) => {
  switch (step) {
    case 0:
      return <Step1CreateBusinessMemo formData={formData} setFormData={setFormData} errors={errors} />;
    case 1:
      return <Step2CreateBusinessMemo formData={formData} setFormData={setFormData} errors={errors} />;
    default:
      return <div>Unknown step</div>;
  }
};

const CreateBusiness = () => {

  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    // Step 1
    email: "",
    phone: "",
    companyName: "",
    fiscalName: "",
    fiscalAdmin: { name: "", surname: "" },
    cif: "",
    vat: "",
    localAddress: {
      street: "",
      zip: "",
      city: "",
      country: "",
    },
    fiscalAddress: {
      street: "",
      zip: "",
      city: "",
      country: "",
    },
    iban: "",
    commission: "",
    // Step 2
    banner: "",
    tips: {'tips-0': ""},
    nutConditions: {'nutConditions-0': ""},
    ticketLimit: { zone: "5", local: "15" },
  });

  const [errors, setErrors] = useState({});
  const isDefined = value => value !== undefined && value !== null && value !== "";
  const isValidEmail = email => /\S+@\S+\.\S+/.test(email);
  const isValidPhone = phone => /^\d{9}$/.test(phone);
  const isValidCIF = cif => /^[A-Z]\d{8}$/.test(cif);
  const isValidVAT = vat => /^[A-Z]{2}\d{8}$/.test(vat);
  const isValidIBAN = iban => /^[A-Z]{2}\d{2}\s?(\d{4}\s?){4}\d{2}$/.test(iban);
  const isValidCommission = commission => /^\d{1,2}(,\d{1,2})?$/.test(commission);
  const isValidZip = zip => /^\d{5}$/.test(zip);
  const isValidTemporalCode = temporalCode => temporalCode.length === 4;
  const isValidTicketLimit = ticketLimit => ticketLimit.zone > 0 && ticketLimit.local > 0;
  const isValidTip = tip => Number.isInteger(tip) && tip > 0 && tip <= 100;

  const handleErrors1 = () => {
    let newErrors = { ...errors };
    if (!isValidEmail(formData.email)) {
      newErrors.email = 'Correo electrónico no válido';
    } else {
      newErrors.email = '';
    }
    if (!isValidPhone(formData.phone)) {
      newErrors.phone = 'Teléfono no válido';
    } else {
      newErrors.phone = '';
    }
    if (!isDefined(formData.companyName)) {
      newErrors.companyName = 'Nombre de empresa no válido';
    } else {
      newErrors.companyName = '';
    }
    if (!isDefined(formData.fiscalName)) {
      newErrors.fiscalName = 'Nombre fiscal no válido';
    } else {
      newErrors.fiscalName = '';
    }
    if (!isDefined(formData.fiscalAdmin.name)) {
      newErrors['fiscalAdmin-name'] = 'Nombre de administrador fiscal no válido';
    } else {
      newErrors['fiscalAdmin-name'] = '';
    }
    if (!isDefined(formData.fiscalAdmin.surname)) {
      newErrors['fiscalAdmin-surname'] = 'Apellido de administrador fiscal no válido';
    } else {
      newErrors['fiscalAdmin-surname'] = '';
    }
    if (!isValidCIF(formData.cif)) {
      newErrors.cif = 'CIF no válido';
    } else {
      newErrors.cif = '';
    }
    if (!isValidVAT(formData.vat)) {
      newErrors.vat = 'NIF no válido';
    } else {
      newErrors.vat = '';
    }
    if (!isDefined(formData.localAddress.street)) {
      newErrors['localAddress-street'] = 'Calle no válida';
    } else {
      newErrors['localAddress-street'] = '';
    }
    if (!isValidZip(formData.localAddress.zip)) {
      newErrors['localAddress-zip'] = 'Código postal no válido';
    } else {
      newErrors['localAddress-zip'] = '';
    }
    if (!isDefined(formData.localAddress.city)) {
      newErrors['localAddress-city'] = 'Ciudad no válida';
    } else {
      newErrors['localAddress-city'] = '';
    }
    if (!isDefined(formData.localAddress.country)) {
      newErrors['localAddress-country'] = 'País no válido';
    } else {
      newErrors['localAddress-country'] = '';
    }
    if (!isDefined(formData.fiscalAddress.street)) {
      newErrors['fiscalAddress-street'] = 'Calle no válida';
    } else {
      newErrors['fiscalAddress-street'] = '';
    }
    if (!isValidZip(formData.fiscalAddress.zip)) {
      newErrors['fiscalAddress-zip'] = 'Código postal no válido';
    } else {
      newErrors['fiscalAddress-zip'] = '';
    }
    if (!isDefined(formData.fiscalAddress.city)) {
      newErrors['fiscalAddress-city'] = 'Ciudad no válida';
    } else {
      newErrors['fiscalAddress-city'] = '';
    }
    if (!isDefined(formData.fiscalAddress.country)) {
      newErrors['fiscalAddress-country'] = 'País no válido';
    } else {
      newErrors['fiscalAddress-country'] = '';
    }
    if (!isValidIBAN(formData.iban)) {
      newErrors.iban = 'IBAN no válido';
    } else {
      newErrors.iban = '';
    }
    if (!isValidCommission(formData.commission)) {
      newErrors.commission = 'Comisión no válida';
    } else {
      newErrors.commission = '';
    }
    if (!Object.values(newErrors).some(error => error !== "")) {
      newErrors = {
        ...newErrors, 
        email: "",
        phone: "",
        companyName: "",
        fiscalName: "",
        'fiscalAdmin-name': "",
        'fiscalAdmin-surname': "",
        cif: "",
        vat: "",
        'localAddress-street': "",
        'localAddress-zip': "",
        'localAddress-city': "",
        'localAddress-country': "",
        'fiscalAddress-street': "",
        'fiscalAddress-zip': "",
        'fiscalAddress-city': "",
        'fiscalAddress-country': "",
        iban: "",
        commission: ""
      };
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    setErrors(newErrors);
  };

  const handleErrors2 = () => {
    let newErrors = { ...errors };
    if (!isDefined(formData.banner)) {
      newErrors.banner = 'Banner no válido';
    } else {
      newErrors.banner = '';
    }
    Object.entries(formData.tips).forEach((tip, index) => {
      if (!isValidTip(+tip[1]) || tip === "") {
        newErrors[`tips-${index}`] = 'Propina no válida';
      } else {
        newErrors[`tips-${index}`] = '';
      }
    });
    Object.entries(formData.nutConditions).forEach((nut, index) => {
      if (!isDefined(nut[1])) {
        newErrors[`nutConditions-${index}`] = 'Condición nutricional no válida';
      } else {
        newErrors[`nutConditions-${index}`] = '';
      }
    });
    if (!isValidTicketLimit(formData.ticketLimit)) {
      newErrors.ticketLimit = 'Límite de tickets no válido';
    } else {
      newErrors.ticketLimit = '';
    }
    if (!Object.values(newErrors).some(error => error !== "")) {
      newErrors = {
        ...newErrors, 
        banner: "",
        'tips-0': "",
        'nutConditions-0': "",
        ticketLimit: ""
      };
      handleBusinessCreation();
    }
    setErrors(newErrors);
  };

  const handleBusinessCreation = () => {
    const tips = Object.values(formData.tips);
    const nutConditions = Object.values(formData.nutConditions).map(nut => {
      return { name: nut}
    });
    newClientAPI({
      email: formData.email,
      phone: formData.phone,
      companyName: formData.companyName,
      fiscalName: formData.fiscalName,
      fiscalAdmin: formData.fiscalAdmin,
      cif: formData.cif,
      vat: formData.vat,
      localAddress: formData.localAddress,
      fiscalAddress: formData.fiscalAddress,
      iban: formData.iban.replace(/\s+/g, ''),
      commission: formData.commission
    }, {
      banner: formData.banner,
      tips: tips,
      nutConditions: nutConditions,
      ticketLimit: formData.ticketLimit,
      temporalCode: undefined
    }
    ).then(response => {
      if (response.status === 200) {
        alert('Éxito en la creación');
        navigate('/admin-home');
      }
    });
  }

  const handleNext = () => {
    if (activeStep === 0) {
      //handleErrors1();
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else if (activeStep === 1) {
      //handleErrors2();
      handleBusinessCreation();
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const styles = {
    stepperContainer: {
      width: "90%",
      padding: "30px",
    },
  };

  return (
    <div style={styles.stepperContainer}>
      <Stepper activeStep={activeStep}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography>Pasos terminados</Typography>
            <Button onClick={handleBack}>Volver</Button>
          </div>
        ) : (
          <div>
            <StepContent step={activeStep} formData={formData} setFormData={setFormData} errors={errors} />
            <Button disabled={activeStep === 0} onClick={handleBack}>
            Volver
            </Button>
            <Button variant="contained" color="primary" onClick={handleNext}>
              {activeStep === steps.length - 1 ? 'Guardar' : 'Siguiente'}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateBusiness;
