// src/services/listZones.service.js

import axios from "../utils/axiosInstance";

export const listZonesAPI = async (businessId) => {
  const response = await axios.post("zones/getZones", {
    businessId: businessId,
  });
  return response;
};

export const postZonesAndTablesAPI = async (businessId, zones) => {
  const response = await axios.post("zones/postZonesAndTables", {
    businessId: businessId,
    zones: zones,
  });
  return response;
};

export const addZoneAPI = async (businessId, name) => {
  const response = await axios.post("zones/addZone", {
    businessId: businessId,
    name: name,
  });
  return response;
};

export const updateZoneAPI = async (zoneId, businessId, newName) => {
  const response = await axios.put("zones/updateZone", {
    zoneId: zoneId,
    businessId: businessId,
    name: newName,
  });
  return response;
};

export const deleteZoneAPI = async (zoneId, businessId) => {
  const response = await axios.delete("zones/deleteZone", {
    data: {
      zoneId: zoneId,
      businessId: businessId,
    },
  });
  return response;
};

export const addTableAPI = async (zoneId, businessId, length) => {
  try {
    const nextTableName =
      typeof length === "number" ? (length + 1).toString() : "1";

    const response = await axios.post("tables/addTable", {
      zoneId,
      businessId,
      name: nextTableName,
      qr_url: "QR",
      nfc_url: "NFC",
    });
    return response;
  } catch (error) {
    console.error("Error añadiendo la mesa:", error);
    throw error;
  }
};

export const updateTableAPI = async (
  tableId,
  zoneId,
  name,
  qr_url,
  nfc_url,
  visibility
) => {
  const response = await axios.put("tables/updateTable", {
    tableId: tableId,
    zoneId: zoneId,
    name: name,
    qr_url: qr_url,
    nfc_url: nfc_url,
    visible: visibility,
  });
  return response;
};

export const deleteTableAPI = async (tableId, zoneId, businessId) => {
  const response = await axios.delete("tables/deleteTable", {
    data: {
      tableId: tableId,
      zoneId: zoneId,
      businessId: businessId,
    },
  });
  return response;
};
