import React, { useState } from "react";
import SelectRestaurantInput from "../components/select-restaurant-input";
import styled, { createGlobalStyle } from "styled-components";
import Hidder from "../components/hidder";

import { ArrowLeftCircle } from "lucide-react";
import IncomingQueue from "../components/kitchen/incomingQueue";

const GlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
    font-family: -apple-system, 'PlusJakartaSans', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  }
`;

const generateRandomOrders = (count) => {
  const dishes = [
    {
      name: "Caña",
      companion: [
        {
          title: "TAMAÑO",
          products: [{ name: "CAÑA" }],
        },
      ],
    },
    {
      name: "Burger",
      companion: [
        {
          title: "TAMAÑO",
          products: [{ name: "GRANDE" }],
        },
        {
          title: "COCCIÓN",
          products: [{ name: "MEDIO" }],
        },
      ],
    },
    {
      name: "Pizza",
      companion: [
        {
          title: "TAMAÑO",
          products: [{ name: "FAMILIAR" }],
        },
        {
          title: "MASA",
          products: [{ name: "FINA" }],
        },
      ],
    },
  ];

  return Array.from({ length: count }, (_, i) => ({
    id: i + 1,
    ...JSON.parse(
      JSON.stringify(dishes[Math.floor(Math.random() * dishes.length)])
    ),
    inProduction: false,
  }));
};

export default function Kitchen() {
  const [selectedRestaurant, setSelectedRestaurant] = useState(null);
  const [incomingOrders, setIncomingOrders] = useState(
    generateRandomOrders(50)
  );
  const [productionOrders, setProductionOrders] = useState([]);

  const moveToProduction = (order) => {
    const existingProductionOrder = productionOrders.find(
      (po) => po.originalOrderId === order.id
    );

    if (existingProductionOrder) {
      // Update existing production order
      const updatedProductionOrders = productionOrders.map((po) => {
        if (po.originalOrderId === order.id) {
          return {
            ...po,
            companion: order.companion,
            inProduction: true,
          };
        }
        return po;
      });
      setProductionOrders(updatedProductionOrders);
    } else {
      // Create new production order
      const newProductionOrder = {
        ...order,
        startTime: new Date().toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        }),
        table: Math.floor(Math.random() * 20) + 1,
        inProduction: true,
        companion: order.companion.map((comp) => ({
          ...comp,
          inProduction: true,
        })),
        originalOrderId: order.id,
      };
      setProductionOrders([...productionOrders, newProductionOrder]);
    }

    setIncomingOrders(incomingOrders.filter((o) => o.id !== order.id));
  };

  const moveCompanionToProduction = (orderId, companionIndex) => {
    const orderIndex = incomingOrders.findIndex((o) => o.id === orderId);
    if (orderIndex === -1) return;

    const updatedIncomingOrders = [...incomingOrders];
    const order = { ...updatedIncomingOrders[orderIndex] };
    order.companion[companionIndex].inProduction = true;

    if (order.companion.every((comp) => comp.inProduction)) {
      moveToProduction(order);
    } else {
      updatedIncomingOrders[orderIndex] = order;
      setIncomingOrders(updatedIncomingOrders);

      const existingProductionOrder = productionOrders.find(
        (po) => po.originalOrderId === order.id
      );

      if (existingProductionOrder) {
        // Update existing production order
        const updatedProductionOrders = productionOrders.map((po) => {
          if (po.originalOrderId === order.id) {
            return {
              ...po,
              companion: [...po.companion, order.companion[companionIndex]],
            };
          }
          return po;
        });
        setProductionOrders(updatedProductionOrders);
      } else {
        // Create new production order for this companion
        const productionOrder = {
          id: `${order.id}-${companionIndex}`,
          name: order.name,
          startTime: new Date().toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }),
          table: Math.floor(Math.random() * 20) + 1,
          companion: [order.companion[companionIndex]],
          originalOrderId: order.id,
        };
        setProductionOrders([...productionOrders, productionOrder]);
      }
    }
  };

  const markAsCompleted = (order) => {
    setProductionOrders(productionOrders.filter((o) => o.id !== order.id));

    if (order.originalOrderId) {
      const incomingOrderIndex = incomingOrders.findIndex(
        (o) => o.id === order.originalOrderId
      );
      if (incomingOrderIndex !== -1) {
        const updatedIncomingOrders = [...incomingOrders];
        const updatedOrder = { ...updatedIncomingOrders[incomingOrderIndex] };
        updatedOrder.companion = updatedOrder.companion.map((comp) => ({
          ...comp,
          inProduction: false,
        }));
        updatedIncomingOrders[incomingOrderIndex] = updatedOrder;
        setIncomingOrders(updatedIncomingOrders);
      }
    }
  };

  const returnToIncoming = (order) => {
    setProductionOrders(productionOrders.filter((o) => o.id !== order.id));

    if (order.originalOrderId) {
      const incomingOrderIndex = incomingOrders.findIndex(
        (o) => o.id === order.originalOrderId
      );
      if (incomingOrderIndex !== -1) {
        const updatedIncomingOrders = [...incomingOrders];
        const updatedOrder = { ...updatedIncomingOrders[incomingOrderIndex] };
        updatedOrder.companion = updatedOrder.companion.map((comp) => {
          const productionComp = order.companion.find(
            (c) => c.title === comp.title
          );
          return productionComp ? { ...comp, inProduction: false } : comp;
        });
        updatedIncomingOrders.splice(incomingOrderIndex, 1);
        setIncomingOrders([updatedOrder, ...updatedIncomingOrders]);
      }
    } else {
      const {
        startTime,
        table,
        inProduction,
        originalOrderId,
        ...originalOrder
      } = order;
      originalOrder.companion = originalOrder.companion.map((comp) => ({
        ...comp,
        inProduction: false,
      }));
      setIncomingOrders([originalOrder, ...incomingOrders]);
    }
  };

  return (
    <>
      <Hidder />
      <GlobalStyle />
      <MainContainer>
        <Selector>
          <SelectRestaurantInput
            setSelectedRestaurant={setSelectedRestaurant}
          />
        </Selector>
        <IncomingQueueWrapper>
          <IncomingQueue
            incomingOrders={incomingOrders}
            moveToProduction={moveToProduction}
            moveCompanionToProduction={moveCompanionToProduction}
          />
        </IncomingQueueWrapper>
        <ProductionSection>
          <Title>En Producción</Title>
          <Table>
            <thead>
              <tr>
                <th>Pedido</th>
                <th>Hora de inicio</th>
                <th>Mesa</th>
                <th>Acción</th>
                <th>Devolver</th>
              </tr>
            </thead>
            <tbody>
              {productionOrders.map((order) => (
                <tr key={order.id}>
                  <td>
                    <ProductInfo>
                      <ProductName>{order.name}</ProductName>
                      {order.companion.map((comp, index) => (
                        <CompanionInfo key={index}>
                          {comp.title}: {comp.products[0].name}
                        </CompanionInfo>
                      ))}
                    </ProductInfo>
                  </td>
                  <td>{order.startTime}</td>
                  <td>{order.table}</td>
                  <td>
                    <Button onClick={() => markAsCompleted(order)}>
                      Listo
                    </Button>
                  </td>
                  <td>
                    <IconButton
                      onClick={() => returnToIncoming(order)}
                      aria-label="Devolver a cola de entrada"
                    >
                      <ArrowLeftCircle size={24} />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </ProductionSection>
      </MainContainer>
    </>
  );
}

const MainContainer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom; 0;
  display: flex;
  flex: 1;
  gap: 10px;
  width: 100vw;
  min-width: 100vw;
  height: 100%;
  max-height: 100vh;
  overflow: hidden;
`;

const Selector = styled.div`
  display: flex;
  position: fixed;
  top: 20px;
  right: 20px;
  flex-direction: column;
`;

const IncomingQueueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  left: 0;
  align-items: center;
  height: 100vh; // Altura completa
  min-width: 270px;
  max-width: 420px;
  width: 25vw; // Se ajusta en función del ancho de la ventana
  background: #f2f2f2;
  padding: 10px;
  padding-right: 10px;
  padding-left: 70px;
  gap: 10px;
  z-index: 10;

  @media (max-width: 768px) {
    width: 100%; // En pantallas pequeñas, ocupa todo el ancho
    position: relative;
    height: auto;
  }
`;

const ProductionSection = styled.div`
  flex-grow: 1;
  padding: 10px;
  padding-right: 20px;
`;

const Title = styled.h2`
  color: #333;
  margin-bottom: 15px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0px 0px 2px 0px rgba(145, 158, 171, 0.2),
    0px 12px 24px -4px rgba(145, 158, 171, 0.12);

  thead {
    background-color: #f2f2f2;
  }

  tbody tr {
    border-bottom: 1px solid #ddd;
  }

  tbody tr:last-child td {
    border-bottom: none;
  }

  th,
  td {
    padding: 12px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }

  th {
    cursor: pointer;
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  tr:hover {
    background-color: #f1f1f1;
  }
`;

const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProductName = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
`;

const CompanionInfo = styled.div`
  font-size: 0.9em;
  color: #666;
  margin-left: 10px;
`;

const Button = styled.button`
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
  border-radius: 3px;
  margin-top: 5px;
`;

const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4caf50;

  &:hover {
    color: #45a049;
  }
`;
