import React from 'react';
import { Button } from 'tamagui';
import { Trash2, Copy } from '@tamagui/lucide-icons';
import styled from 'styled-components';

export default function PosTicket({ ticket, setTicket }) {

    const deleteFoodFromTicket = (index) => {
        const newTicket = ticket.filter((item, i) => i !== index);
        setTicket(newTicket);
    }

    const duplicateFoodFromTicket = (item) => {
        setTicket((prev) => {
            const combinedItems = [...prev, item];
            const sortedItems = combinedItems.sort((a, b) => {
                if (a.companion.length < b.companion.length) return -1;
                if (a.companion.length > b.companion.length) return 1;
                if (a._id < b._id) return -1;
                if (a._id > b._id) return 1;
                return 0;
            });
            return sortedItems;
        });
    }

    const priceExtraReducer = (companion) => {
        return companion.reduce((acc, companion) => {
            const foodPriceExtra = +companion.foods[0]?.price_extra || 0;
            const categoryPriceExtra = +companion.categories[0]?.foods[0]?.price_extra || 0;
            return +acc + +foodPriceExtra + +categoryPriceExtra;
        }, 0);
    }

    return ticket.length > 0 ?
        ticket.map((item, index) => (
            <TicketMenuCard key={index}>
                <TicketMenuHeader>
                    <TicketMenuTitle>{item.name}</TicketMenuTitle>
                </TicketMenuHeader>
                <TicketMenuSubheader>
                    <PricesContainer>
                        <TicketMenuPrice>
                            { priceExtraReducer(item.companion) > 0 ? 
                                `${+priceExtraReducer(item.companion) + +item.price}€` 
                                : `${item.price}€`
                            }
                        </TicketMenuPrice>
                    </PricesContainer>
                    <TicketActionButtons>
                        <Button icon={Trash2} size="$lg" color="red" onPress={() => deleteFoodFromTicket(index)}></Button>
                        <Button icon={Copy} size="$lg" onPress={() => duplicateFoodFromTicket(item)}></Button>
                    </TicketActionButtons>
                </TicketMenuSubheader>
                {item.companion && item.companion.length > 0 && (
                <TicketMenuSection>
                    { item.companion?.map((companion, index) => (
                        <TicketMenuItem key={index}>
                            <TicketItemDetails>
                                <span>
                                    { companion.foods[0] && companion.foods[0].name ? 
                                        companion.foods[0].name
                                        : (companion.categories[0] && companion.categories[0].foods[0].name) ?
                                            companion.categories[0].foods[0].name
                                            : ''
                                    }
                                </span>
                                <TicketMenuPriceExtra>
                                    { companion.foods[0] && companion.foods[0].price_extra ? 
                                        '+' + companion.foods[0].price_extra + '€'
                                        : (companion.categories[0] && companion.categories[0].foods[0].price_extra) ?
                                            '+' + companion.categories[0].foods[0].price_extra + '€'
                                            : ''
                                    }
                                </TicketMenuPriceExtra>
                            </TicketItemDetails>
                        </TicketMenuItem>
                    ))}
                </TicketMenuSection>
                )}
            </TicketMenuCard>
        )) : null;
}

const TicketMenuCard = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid #BBC5AA;
    border-radius: 10px;
    padding: 10px;
    background-color: #fff;
`;

const TicketMenuHeader = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
`;

const TicketMenuTitle = styled.span`
    font-size: 18px;
    font-weight: 800;
`;

const TicketMenuSubheader = styled.div`
    display: flex;
    justify-content: space-between;
`;

const PricesContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const TicketMenuPrice = styled.span`
    font-size: 20px;
    font-weight: 400;
    color: #242424;
`;

const TicketMenuPriceExtra = styled.span`
    font-size: 16px;
    font-weight: 400;
    color: #e0362b;
    margin-left: 8px;
`;

const TicketMenuSection = styled.div`
    border-top: 1px solid #eee;
    padding-top: 16px;
`;

const TicketMenuItem = styled.div`
    margin-bottom: 16px;
`;

const TicketItemDetails = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
`;

const TicketActionButtons = styled.div`
    display: flex;
    gap: 8px;
`;
