import { createSlice } from "@reduxjs/toolkit";

const businessSlice = createSlice({
  name: "business",
  initialState: {
    selectedBusinesses: [],
    zones: [],
    menus: [],
    allergences: [],
    tickets: [],
    foods: [],
    profile: {
      email: "", // esto estará en la tabla de mongo "clients", no en "businesses"
      phone: "", // esto estará en la tabla de mongo "clients", no en "businesses"
      fiscalName: "",
      fiscalAdmin: { name: "", surname: "" },
      cif: "",
      vat: "",
      localAddress: {
        street: "",
        zip: "",
        city: "",
        country: "",
      },
      fiscalAddress: {
        street: "",
        zip: "",
        city: "",
        country: "",
      },
      iban: "",
      commission: "",
    },
    settings: {
      banner: "",
      tips: [],
      nutConditions: [],
      type: "",
      ticketLimit: { zone: "", local: "" },
    },
  },
  reducers: {
    setSelectedBusiness: (state, action) => {
      state.selectedBusinesses = action.payload.data;
    },
    setBusinessZones: (state, action) => {
      state.zones = action.payload;
    },
    setMenus: (state, action) => {
      state.menus = action.payload;
    },
    setTickets: (state, action) => {
      state.tickets = action.payload;
    },
    setFoods: (state, action) => {
      state.foods = action.payload;
    },
    setProfile: (state, action) => {
      state.profile = action.payload;
    },
    setSettings: (state, action) => {
      state.settings = action.payload;
    },
    setAllergences: (state, action) => {
      state.allergences = action.payload;
    },
  },
});

export const {
  setSelectedBusiness,
  setBusinessZones,
  setMenus,
  setTickets,
  setFoods,
  setProfile,
  setSettings,
  setAllergences,
} = businessSlice.actions;
export default businessSlice.reducer;
