import { X } from '@tamagui/lucide-icons'
import {
  Adapt,
  Button,
  Dialog,
  Fieldset,
  Input,
  Label,
  Sheet,
  Unspaced,
  XStack,
} from 'tamagui'

export function ProductDialog({ setFamilies, selectedFamily, selectedCategory }) {
  return <DialogInstance setFamilies={setFamilies} selectedFamily={selectedFamily} selectedCategory={selectedCategory} />
}

function DialogInstance({ setFamilies, selectedFamily, selectedCategory }) {
  return (
    <Dialog modal>
      <Dialog.Trigger asChild>
        <Button>Añadir producto</Button>
      </Dialog.Trigger>
      <Adapt when="sm" platform="touch">
        <Sheet animation="medium" zIndex={200000} modal dismissOnSnapToBottom>
          <Sheet.Frame padding="$4" gap="$4">
            <Adapt.Contents />
          </Sheet.Frame>
          <Sheet.Overlay
            animation="lazy"
            enterStyle={{ opacity: 0 }}
            exitStyle={{ opacity: 0 }}
          />
        </Sheet>
      </Adapt>
      <Dialog.Portal>
        <Dialog.Overlay
          key="overlay"
          animation="slow"
          opacity={0.5}
          enterStyle={{ opacity: 0 }}
          exitStyle={{ opacity: 0 }}
        />

        <Dialog.Content
          bordered
          elevate
          key="content"
          animateOnly={['transform', 'opacity']}
          animation={[
            'quicker',
            {
              opacity: {
                overshootClamping: true,
              },
            },
          ]}
          enterStyle={{ x: 0, y: -20, opacity: 0, scale: 0.9 }}
          exitStyle={{ x: 0, y: 10, opacity: 0, scale: 0.95 }}
          gap="$4"
        >
          <Dialog.Title>Añadir producto</Dialog.Title>
          <Dialog.Description>
            Añada un nuevo producto para añadir valor a su carta.
          </Dialog.Description>
          <Fieldset gap="$4" horizontal>
            <Label width={160} justifyContent="flex-end" htmlFor="name">
              Nombre
            </Label>
            <Input flex={1} id="name" defaultValue="" />
          </Fieldset>
          <XStack alignSelf="flex-end" gap="$4">
          <Dialog.Close displayWhenAdapted asChild>
              <Button theme="active" aria-label="Close">
                Cerrar
              </Button>
            </Dialog.Close>
            <Dialog.Close displayWhenAdapted asChild>
              <Button 
                theme="active" 
                aria-label="Close"
                onClick={() => {
                  setFamilies((families) => {
                    const newFamilies = [...families];
                    const familyIndex = newFamilies.findIndex(
                      (family) => family.name === selectedFamily
                    );
                    const categoryIndex = newFamilies[familyIndex].categories.findIndex(
                      (category) => category.name === selectedCategory
                    );
                    newFamilies[familyIndex].categories[categoryIndex].products.push({
                      name: document.getElementById('name').value,
                    });
                    return newFamilies;
                  })
                }}
                >
                Añadir
              </Button>
            </Dialog.Close>
          </XStack>

          <Unspaced>
            <Dialog.Close asChild>
              <Button
                position="absolute"
                top="$3"
                right="$3"
                size="$2"
                circular
                icon={X}
              />
            </Dialog.Close>
          </Unspaced>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog>
  )
}
