// src/services/ticket.service.js

import axios from "../utils/axiosInstance";

export const getTicketsApi = async (businessIds) => {
  const response = await axios.post("tickets/getTickets", {
    businessIds: businessIds,
  });
  return response;
};

export const posAddTicket = async (businessId, zoneId, tableId, notes, name, coupon, ticket) => {
  const response = await axios.post("tickets/posAddTicket", {
    businessId: businessId,
    zoneId: zoneId,
    tableId: tableId,
    notes: notes,
    name: name, 
    coupon: coupon,
    ticket: ticket,
  });
  return response;
};

export const refundTicketsApi = async (ticketIds) => {
  console.log("ticketIds:      ----", ticketIds)
  try {
    const response = await axios.post('refundTickets', { ticketIds });
    return response;
  } catch (error) {
    console.error("Error calling refundTickets API", error);
    throw error;
  }
};

export const cancelTicketApi = async (ticketIds) => {
  try {
    const response = await axios.post('cancelTickets', { ticketIds });
    return response;
  } catch (error) {
    console.error("Error calling cancelTickets API", error);
    throw error;
  }
};

export const deleteTicketApi = async (ticketIds) => {
  try {
    const response = await axios.post('deleteTickets', { ticketIds });
    return response;
  } catch (error) {
    console.error("Error calling deleteTickets API", error);
    throw error;
  }
};