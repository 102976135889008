import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Text, View, Button } from "tamagui";
import { ArrowUpLeft, Trash2 } from "@tamagui/lucide-icons";
import axios from "axios";
import {
  updateTableAPI,
  deleteTableAPI,
  addTableAPI,
} from "../../services/listZones.service";

const Table = ({ table, zoneId, businessId, onTableDeleted }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState(table.name || "");
  const inputRef = useRef(null);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const handleBlur = async () => {
    setIsEditing(false);
    if (name !== table.name) {
      try {
        await updateTableAPI(table._id, zoneId, name, "", "", true);
      } catch (error) {
        console.error("Error actualizando el nombre de la mesa:", error);
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleBlur();
    }
  };

  const handleDelete = async () => {
    const confirmDelete = window.confirm(
      "¿Estás seguro de que quieres eliminar esta mesa?"
    );

    if (confirmDelete) {
      try {
        await deleteTableAPI(table._id, zoneId, businessId);
        if (onTableDeleted) {
          onTableDeleted(table._id, zoneId);
        }
      } catch (error) {
        console.error("Error eliminando la mesa:", error);
      }
    }
  };

  const handleDownloadQRCode = async () => {
    const options = {
      method: "GET",
      url: "https://qrcode-monkey.p.rapidapi.com/qr/custom",
      params: {
        data: `https://m.ibero.app/${businessId}/${table._id}`,
        size: "1200",
        file: "svg",
        download: "false",
        config:
          '{"bgColor": "transparent", "bodyColor": "#000000", "body":"circle", "eye": "frame13", "eyeBall": "ball15", "erf1": ["fv"], "erf2": ["fv","fh"], "brf1": ["fv"], "brf2": ["fv","fh"], "logo":"https://ibero.app/wp-content/drive/ICONO.png", "logoMode": "clean"}',
      },
      headers: {
        "X-RapidAPI-Key": "99f9d8750emsha6eb5312e0f2bf8p19be00jsnac8e893f17d1",
        "X-RapidAPI-Host": "qrcode-monkey.p.rapidapi.com",
      },
      responseType: "blob", // Asegúrate de recibir la respuesta como un blob
    };

    try {
      const response = await axios.request(options);
      const contentType = response.headers["content-type"];

      let format = "svg";
      if (contentType.includes("image/png")) {
        format = "png";
      }

      const link = document.createElement("a");
      let url;

      if (format === "svg") {
        url = URL.createObjectURL(
          new Blob([response.data], { type: "image/svg+xml" })
        );
      } else {
        url = URL.createObjectURL(response.data);
      }

      link.href = url;
      link.download = `${businessId}_${table.name || "defaultName"}.${format}`;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Limpia el objeto URL después de su uso
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error descargando el QR Code:", error);
    }
  };

  return (
    <TableContainer>
      <TableCard>
        <View
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="space-between"
        >
          <View style={{ minWidth: "40%" }}>
            <Text>Imagen qr</Text>
          </View>
          <View style={{ maxWidth: "60%" }} flexDirection="column" gap="8px">
            <GrayText>Mesa</GrayText>
            {isEditing ? (
              <EditableInput
                ref={inputRef}
                value={name}
                onChange={(e) => setName(e.target.value)}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                placeholder="Nombre de la mesa"
              />
            ) : (
              <BoldText
                fontSize="14px"
                onClick={() => setIsEditing(true)}
                style={{ cursor: "pointer" }}
              >
                {name || "Nombre de la mesa"}
              </BoldText>
            )}
            <View flexDirection="row" gap="8px">
              <Button
                style={{
                  background: "#E6FFFA",
                  padding: "5px",
                  borderRadius: "50%",
                  height: "fit-content",
                  alignSelf: "center",
                }}
                icon={<ArrowUpLeft color={"#13DEB9"} size="$1" />}
              ></Button>
              <GrayText fontSize="12px" marginTop="4px">
                400 visitas en la última semana
              </GrayText>
            </View>
            <View flexDirection="row" gap="8px">
              <Button
                style={{
                  background: "#EBF3FE",
                  padding: "5px",
                  borderRadius: "5px",
                  height: "fit-content",
                  alignSelf: "center",
                }}
                onClick={handleDownloadQRCode}
              >
                <span>Descargar QR</span>
              </Button>
              <Button
                style={{
                  background: "#FFDDDD",
                  padding: "$1",
                  borderRadius: "5px",
                  height: "fit-content",
                  alignSelf: "center",
                }}
                icon={<Trash2 color={"#FF4D4F"} size="$1" />}
                onClick={handleDelete}
              >
                <span>Eliminar</span>
              </Button>
            </View>
          </View>
        </View>
      </TableCard>
    </TableContainer>
  );
};

const AddTable = ({ zone, businessId, onTableAdded }) => {
  const handleAddTable = async () => {
    try {
      const nextTableName = zone.tables.length + 1;
      const response = await addTableAPI(zone._id, businessId, nextTableName);

      const newTable = {
        _id: response.data._id,
        name: `${nextTableName}`,
        zoneId: zone._id,
      };

      if (onTableAdded) {
        onTableAdded(newTable);
      }
    } catch (error) {
      console.error("Error añadiendo la mesa:", error);
    }
  };

  return (
    <TableContainer>
      <TableCard onClick={handleAddTable}>
        <Text1>Añadir mesa</Text1>
      </TableCard>
    </TableContainer>
  );
};

export { Table, AddTable };

const TableContainer = styled.div`
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 100%; /* Ajusta el tamaño del elemento dentro de un grid */

  @media (min-width: 600px) {
    flex: 1 1 calc(50% - 10px); /* Dos columnas en pantallas medianas */
  }

  @media (min-width: 900px) {
    flex: 1 1 calc(33.33% - 10px); /* Tres columnas en pantallas grandes */
  }

  @media (min-width: 1200px) {
    flex: 1 1 calc(25% - 10px); /* Cuatro columnas en pantallas más grandes */
  }
`;

const TableCard = styled.div`
  border-radius: 15px;
  padding: 20px;
  border: 1px solid #000;
  color: #000;
  background: white;
  min-height: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const Text1 = styled.span`
  font-family: "PlusJakartaSans", sans-serif;
  font-size: 18px;
`;

const GrayText = styled(Text1)`
  color: #7c8fac;
  font-size: 14px;
`;

const BoldText = styled.span`
  font-family: "PlusJakartaSansSemibold", sans-serif;
  font-size: 24px;
`;

const EditableInput = styled.input`
  font-family: "PlusJakartaSansSemibold", sans-serif;
  font-size: 24px;
  border: none;
  outline: none;
  background: transparent;
  width: 100%;
  box-sizing: border-box;
  color: #000; // Asegúrate de que el texto sea visible
  placeholder: "Nombre";
`;
