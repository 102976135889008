const editProductsStyles = {
    main: {
        padding: '0',
    },
    products_container: {
        display: 'flex',
        width: '100%',
        flexWrap: 'wrap',
        gap: '20px',
        justifyContent: 'center',
    },
    restaurantWorkZonesInput: { 
        width: '200px', 
        marginTop: '5px',
        marignBottom: '5px',
        padding: '5px', 
        border: '1px solid gray',
        borderRadius: '5px',
    },
    restaurantWorkZonesChips: { 
        width: 'fit-content', 
        display: 'flex',
        flexWrap: 'wrap',
        gap: '5px',
    },
    restaurantWorkZonesChip: { 
        width: 'fit-content',
        alignItems: 'center',
        marginTop: '5px',
        marignBottom: '5px',
        padding: '5px',
        paddingLeft: '10px',
        border: '1px solid #ccc',
        borderRadius: '20px',
        display: 'flex',
        gap: '5px',
    },
    restaurantWorkZonesChipButton: {
        background: 'red',
        color: 'white',
        border: 'none',
        borderRadius: '50%',
        width: '30px',
        height: '30px',
        cursor: 'pointer',
    },
    workZoneContainer: {
        display: 'flex',
        flexDirection: 'row',
        padding: '10px',
        gap: '10px',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
    },
    workZoneTitle: {
        fontSize: '18px',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 40px',
    },
    header_right: {
        display: 'flex',
        gap: '20px',
        alignItems: 'center',
    },
    restaurantsListMainContainer: {
        position: 'absolute',
        zIndex: '1',
        right: '10px',
        top: '10px',
        backgroundColor: 'white',
    },
    verticalFlex: {
        display: 'flex',
        flexDirection: 'column',
    },
    verticalFlexTitle: {
        marginTop: '15px',
        fontSize: '30px',
        fontWeight: 'bold',
    }
}

export default editProductsStyles;