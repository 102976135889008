import axios from "../utils/axiosInstance";

export const getAllBusinessesAPI = async (userId) => {
  const response = await axios.get("businesses/getAllBusinesses?userId=" + userId);
  return response?.data?.resp;
}

export const getClientBusinessesAPI = async (selectedClient) => {
  const params = {
    client: selectedClient
  }
  const response = await axios.get("businesses/getClientBusinesses", {params});
  return response;
};

export const getBusinessInfoAPI = async (businessId) => {
  const response = await axios.post("businesses/getBusinessInfo", {
    businessId: businessId,
  });
  return response;
};

export const updateBusinessAPI = async (
  businessId,
  updatedBusiness,
  temporalCodes
) => {
  const response = await axios.put("businesses/updateBusiness", {
    businessId: businessId,
    updatedBusiness: updatedBusiness,
    temporalCodes: temporalCodes,
  });
  return response;
};

export const updateBusinessSelectedDate = async (
  businessId,
  selectedDate,
  isEnd
) => {
  const response = await axios.put("businesses/updateBusinessSelectedDate", {
    businessId: businessId,
    selectedDate: selectedDate,
    isEnd: isEnd
  });
  return response;
}

export const getRestaurantWorkZonesAPI = async (businessId) => {
  const params = {
    businessId: businessId,
  }
  const response = await axios.get("businesses/getRestaurantWorkZones", {params});
  return response.data.resp.work_zones;
}

export const createRestaurantWorkZonesAPI = async (
  businessId,
  work_zone
) => {
  const response = await axios.put("businesses/createRestaurantWorkZones", {
    businessId: businessId,
    work_zone: work_zone
  });
  return response.data.resp;
}

export const deleteRestaurantWorkZonesAPI = async (
  businessId,
  work_zone
) => {
  const response = await axios.delete("businesses/deleteRestaurantWorkZones", {
    data: {
      businessId: businessId,
      work_zone: work_zone
    }
  });
  return response.data.resp;
}

export const getBusinessLanguagesAPI = async (businessId) => {
  const response = await axios.get("businesses/getBusinessLanguages?businessId=" + businessId);
  return response.data.resp;
}

export const putBusinessLanguagesAPI = async (businessId, languages) => {
  const response = await axios.put("businesses/putBusinessLanguages", {
    businessId: businessId,
    languages: languages
  });
  return response.data.resp;
}

export const sendDay = async (businessId, emails) => {
  if (!businessId) throw new Error('BusinessId is required');
  if (!emails) throw new Error('Email is required');
  const response = await axios.post("/businesses/sendDay", { "businessId": businessId, "emails": emails });
  return response.data?.resp ? response.data?.resp : true;
}

export const closeDay = async (businessId, emails, fvn) => {
  if (!businessId) throw new Error('BusinessId is required');
  if (!emails) throw new Error('Email is required');
  const response = await axios.post("/businesses/closeDay", { "businessId": businessId, "emails": emails, "fvn": fvn });
  return response.data?.resp ? response.data?.resp : true;
}

export const getPeriodByBusinessIdAndPeriodId = async (businessId, periodId, emails) => {
  if (!businessId) throw new Error('BusinessId is required');
  if (!emails) throw new Error('Email is required');
  if (!periodId) throw new Error('PeriodId is required');
  const response = await axios.post("/businesses/getPeriodByBusinessIdAndPeriodId", { "businessId": businessId, "periodId": periodId, "emails": emails });
  return response.data?.resp ? response.data?.resp : true;
}

export const getFvnHistory = async (businessId, loadIndex, batchSize) => {
  if (!businessId) throw new Error('BusinessId is required');
  const response = await axios.get("/businesses/getFvnHistory?businessId="+businessId+"&loadIndex="+loadIndex+"&batchSize="+batchSize);
  return response.data.resp;
}

export const updatePeriodName = async (businessId, periodId, text) => {
  if (!businessId) throw new Error('BusinessId is required');
  if (!periodId) throw new Error('PeriodId is required');
  if (!text) throw new Error('Text is required');
  const response = await axios.put("/businesses/updatePeriodName", { "businessId": businessId, "periodId": periodId, "text": text });
  return response.data;
}

export const getBusinessAutoCloseData = async (businessId) => {
  if (!businessId) throw new Error('BusinessId is required');
  const response = await axios.get("/businesses/getBusinessAutoCloseData?businessId="+businessId);
  return response?.data?.resp;
}

export const setBusinessAutoCloseData = async (businessId, data, emails) => {
  if (!businessId) throw new Error('BusinessId is required');
  if (!data) throw new Error('Data is required');
  if (!emails) throw new Error('Emails is required');
  const response = await axios.post("/businesses/setBusinessAutoCloseData", { "businessId": businessId, "data": data, "emails": emails });
  return response?.data;
}

export const getFvnEmails = async (businessId) => {
  if (!businessId) throw new Error('BusinessId is required');
  const response = await axios.get("/businesses/getFvnEmails?businessId="+businessId);
  return response?.data?.resp;
}

export const updateFvnEmails = async (businessId, emails) => {
  if (!businessId) throw new Error('BusinessId is required');
  if (!emails) throw new Error('Emails is required');
  const response = await axios.put("/businesses/updateFvnEmails", { "businessId": businessId, "emails": emails });
  return response?.data;
}

export const sendFVNs = async (businessId, emails, fvns) => {
  if (!businessId) throw new Error('BusinessId is required');
  if (!emails) throw new Error('Emails is required');
  if (!fvns) throw new Error('FVNs is required');
  const response = await axios.post("/businesses/sendFVNs", { "businessId": businessId, "emails": emails, "fvns": fvns });
  return response?.data;
}

export const downloadFVNs = async (businessId, fvns) => {
  if (!businessId) throw new Error('BusinessId is required');
  if (!fvns) throw new Error('FVNs is required');
  const response = await axios.post("/businesses/downloadFVNs", { "businessId": businessId, "fvns": fvns });
  return response?.data;
}

export const updatePeriodStatus = async (businessId, periodId, status) => {
  if (!businessId) throw new Error('BusinessId is required');
  if (!periodId) throw new Error('PeriodId is required');
  if (!status) throw new Error('Status is required');
  const response = await axios.put("/businesses/updatePeriodStatus", { "businessId": businessId, "periodId": periodId, "status": status });
  return response?.data;
}

export const updatePeriodsStatuses = async (businessId, periodsIds, status) => {
  if (!businessId) throw new Error('BusinessId is required');
  if (!periodsIds) throw new Error('periodsIds is required');
  if (!status) throw new Error('status is required');
  const response = await axios.put("/businesses/updatePeriodsStatuses", { "businessId": businessId, "periodsIds": periodsIds, "status": status });
  return response?.data;
}

export const validateExistsEmail = async (email) => {
  if (!email) throw new Error('Email is required');
  const response = await axios.post("/businesses/validateExistsEmail", { "email": email });
  return response?.data;
}

export const registerSendValidationCodeToEmail = async (email) => {
  if (!email) throw new Error('Email is required');
  const response = await axios.post("/businesses/registerSendValidationCodeToEmail", { "email": email });
  return response?.data;
}

export const validateCodeFromDashRegister = async (email, code) => {
  if (!email) throw new Error('Email is required');
  if (!code) throw new Error('Code is required');
  const response = await axios.post("/businesses/validateCodeFromDashRegister", { "email": email, "code": code });
  return response?.data;
}

export const registerUserFromDash = async (name, email, wantNews) => {
  if (!name) throw new Error('Name is required');
  if (!email) throw new Error('Email is required');
  if (!wantNews) throw new Error('WantNews is required');
  const response = await axios.post("/businesses/registerUserFromDash", { "name": name, "email": email, "wantNews": wantNews });
  return response?.data;
}

export const registerBusinessFromDash = async (submitData, user, foodFamilies) => {
  if (!submitData) throw new Error('SubmitData is required');
  if (!user) throw new Error('User is required');
  if (!foodFamilies) throw new Error('FoodFamilies is required');
  const response = await axios.post("/businesses/registerBusinessFromDash", { "business": submitData, "client": user, "foodFamilies": foodFamilies });
  return response?.data;
}

export const getSelectedBusinessFamilies = async (businessId) => {
  if (!businessId) throw new Error('BusinessId is required');
  const response = await axios.get("/businesses/getSelectedBusinessFamilies?businessId=" + businessId);
  return response?.data?.resp;
}