import { config as configBase } from '@tamagui/config/v3';
import { createFont, createTamagui, Tabs } from 'tamagui';

export const tamaguiConfig = createTamagui({
  ...configBase,
  fonts: {
    body: createFont({
      family: 'PlusJakartaSans, Helvetica, Arial, sans-serif',
      size: {
        $4: 12,
        xs: 12,
        sm: 14,
        md: 16,
        lg: 20,
        xl: 24,
      },
      lineHeight: {
        $4: 16,
        xs: 16,
        sm: 20,
        md: 24,
        lg: 28,
        xl: 32,
      },
      weight: {
        light: 300,
        regular: 400,
        semiBold: 600,
        bold: 700,
      },
      letterSpacing: {
        $4: 0.5,
        xs: -0.5,
        sm: 0,
        md: 0.5,
        lg: 1,
        xl: 1.5,
      },
    }),
    heading: createFont({
      family: 'PlusJakartaSans, Helvetica, Arial, sans-serif',
      size: {
        $4: 18,
        xs: 18,
        sm: 22,
        md: 26,
        lg: 32,
        xl: 40,
      },
      lineHeight: {
        $4: 24,
        xs: 24,
        sm: 28,
        md: 32,
        lg: 40,
        xl: 48,
      },
      weight: {
        light: 300,
        regular: 400,
        semiBold: 600,
        bold: 700,
      },
      letterSpacing: {
        $4: 0,
        xs: 0,
        sm: 0.5,
        md: 1,
        lg: 1.5,
        xl: 2,
      },
    }),
  }
});

export default tamaguiConfig;

export type Conf = typeof tamaguiConfig;

declare module 'tamagui' {
  interface TamaguiCustomConfig extends Conf {}
}