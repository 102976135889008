


// IN A FUTURE IT WOULD BE FANTASTIC TO HASH THE DATA BEFORE STORING IT



export const storeStringData = (key, value) => {
  try {
    localStorage.setItem(key, value);
  } catch (e) {
    console.error(e);
  }
};

export const deleteData = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    console.error(e);
  }
};

export const storeObjectData = (key, value) => {
  try {
    const jsonValue = JSON.stringify(value);
    localStorage.setItem(key, jsonValue);
  } catch (e) {
    console.error(e);
  }
};

export const getStringData = (key) => {
  try {
    const value = localStorage.getItem(key);
    return value && value !== "undefined" ? value : null;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const getObjectData = (key) => {
  try {
    const jsonValue = localStorage.getItem(key);
    return jsonValue !== null ? JSON.parse(jsonValue) : null;
  } catch (e) {
    console.error(e);
    return null;
  }
};