// src/features/loadingSlice/loadingSlice.js

import { createSlice } from "@reduxjs/toolkit";

const loadingSlice = createSlice({
  name: "loading",
  initialState: { status: false, responseStatus: 'loading' },
  reducers: {
    setLoading: (state, action) => {
      state.status = action.payload;
    },
    setResponseStatus: (state, action) => {
      state.responseStatus = action.payload;
    },
  },
});

export const { setLoading, setResponseStatus } = loadingSlice.actions;
export default loadingSlice.reducer;
