import React from 'react';
import { Input, XStack, Button } from 'tamagui'

function debounce(func, delay) {
    let debounceTimer;
    return function() {
        const context = this;
        const args = arguments;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
}

const Family = ({ name, isSelected, onClick, setSelectedFamily, setFamilies }) => {

    const handleDelete = () => {
        if (isSelected) {
            setSelectedFamily(null);
        }
        setFamilies(families => families.filter(family => family.name !== name));
    };

    return (
        <XStack>
            <XStack 
                alignItems="center" 
                onClick={onClick} 
                style={{ 
                    cursor: 'pointer',
                    backgroundColor: isSelected ? 'lightblue' : 'transparent',
                    height: '100px',
                    width: '120px',
                    borderRadius: '10px',
                    border: '1px solid black',
                }}
                >
                <Input 
                    flex={1} 
                    size={'$4'} 
                    defaultValue={name} 
                    style={{ 
                        textAlign: 'center',
                        cursor: 'pointer', 
                        backgroundColor: isSelected ? 'lightblue' : 'transparent',
                        height: 'auto',
                        width: '120px',
                        border: 'none',
                        outline: 'none',
                        outlineWidth: '0 !important'
                    }}
                    onChange={debounce((e) => {
                        setFamilies(families => {
                            setSelectedFamily(e.target.value);
                            return families.map(family => {
                                if (family.name === name) {
                                    return {
                                        ...family,
                                        name: e.target.value
                                    };
                                }
                                return family;
                            });
                        });
                    }, 1000)}
                />
            </XStack>
            <Button 
                onPress={handleDelete} 
                size={'$1'}
                style={{ 
                    position: 'absolute',
                    height: '20px',
                    width: '20px',
                    top: '5px',
                    right: '5px',
                    backgroundColor: 'red',
                    color: 'white',
                    borderRadius: '50%',
                }}
            >
                X
            </Button>
        </XStack>
    );
};

export default Family;