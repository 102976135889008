import React, { useState } from "react";
import { Button, Input, Text, YStack, Stack, Image } from 'tamagui';
import { registerBusinessFromDash } from '../../services/business.service';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logIn } from "../../features/authSlice/authSlice";

const Step3 = () => {

  const [formData, setFormData] = useState({
    headerImage: null,
    phone: '',
    cif: '',
    companyName: '',
    fiscalName: '',
    admin: {
      name: '',
      surname: ''
    },
    fiscalAddress: {
      street: '',
      zip: '',
      city: '',
      country: ''
    },
    localAddress: {
      street: '',
      zip: '',
      city: '',
      country: ''
    }
  });

  const [errors, setErrors] = useState({});
  
  const foodFamilies = JSON.parse(localStorage.getItem("families"));
  const preuser = JSON.parse(localStorage.getItem("preuser"));

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e, name, parent) => {
    const value = e.target.value;
    if (parent) {
      setFormData({
        ...formData,
        [parent]: {
          ...formData[parent],
          [name]: value
        }
      });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const handleFileChange = (e) => {
    const { files } = e.target;
    if (files && files[0]) {
      const file = files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData({
          ...formData,
          headerImage: file,
          headerImagePreview: reader.result
        });
      };
      reader.readAsDataURL(file);
    }
  };
  
  const handleSubmit = async () => {
    let newErrors = {};
    if (!formData.phone) newErrors.phone = 'Teléfono es requerido';
    if (!formData.companyName) newErrors.companyName = 'Nombre de la empresa es requerido';
    if (!formData.fiscalName) newErrors.fiscalName = 'Nombre fiscal es requerido';
    if (!formData.cif) newErrors.cif = 'CIF es requerido';
    if (!formData.admin.name || !formData.admin.surname) newErrors.admin = 'Nombre y apellido del administrador son requeridos';
    if (!formData.fiscalAddress.street || !formData.fiscalAddress.zip || !formData.fiscalAddress.city || !formData.fiscalAddress.country) newErrors.fiscalAddress = 'Todos los campos de la dirección fiscal son requeridos';
    if (!formData.localAddress.street || !formData.localAddress.zip || !formData.localAddress.city || !formData.localAddress.country) newErrors.localAddress = 'Todos los campos de la dirección del local son requeridos';
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    const submitData = {};
    for (let key in formData) {
      submitData[key] = formData[key];
    }
    try {
      const resp = await registerBusinessFromDash(submitData, preuser, foodFamilies);
      console.log(resp.resp.status);
      if (resp.resp.status === 200) {
        localStorage.removeItem('preuser');
        localStorage.removeItem('families');
        dispatch(logIn(resp.resp.user));
        navigate("/admin-home");
      } else if (resp.resp.status === 400) {
        let newErrors = {};
        newErrors.cif = 'El CIF ya está en uso';
        setErrors(newErrors);
      } else {
        alert('Error al guardar los datos, intentelo más tarde.');
      }
    } catch (error) {
      console.error(error);
      alert('Error al guardar los datos, intentelo más tarde.');
    }
  };

  return (
    <YStack>
      {preuser?._id && (
        <Stack>
          <Text>Ya tenemos tu carta digital preparada { preuser?.name }</Text>
          <Text>Vamos a terminar de guardar los datos necesarios para que funcione tu nueva carta digital:</Text>
          <input
            type="file"
            accept="image/*"
            name="headerImage"
            onChange={handleFileChange}
          />
          {formData.headerImagePreview && (
            <img
              src={formData.headerImagePreview}
              style={{ width: 100, height: 100, marginTop: 10 }}
              alt="Imagen de encabezado"
            />
          )}
          <Input
            placeholder="Teléfono"
            value={formData.phone}
            onChange={(e) => handleChange(e, 'phone')}
            required
          />
          {errors.phone && <Text style={{ color: 'red' }}>{errors.phone}</Text>}
          <Input
            placeholder="Nombre de la empresa"
            value={formData.companyName}
            onChange={(e) => handleChange(e, 'companyName')}
            required
          />
          {errors.companyName && <Text style={{ color: 'red' }}>{errors.companyName}</Text>}
          <Input
            placeholder="Nombre fiscal"
            value={formData.fiscalName}
            onChange={(e) => handleChange(e, 'fiscalName')}
            required
          />
          {errors.fiscalName && <Text style={{ color: 'red' }}>{errors.fiscalName}</Text>}
          <Input
            placeholder="CIF"
            value={formData.cif}
            onChange={(e) => handleChange(e, 'cif')}
            required
          />
          {errors.cif && <Text style={{ color: 'red' }}>{errors.cif}</Text>}
          <Text>Administrador</Text>
          <Input
            placeholder="Nombre"
            value={formData.admin.name}
            onChange={(e) => handleChange(e, 'name', 'admin')}
            required
          />
          <Input
            placeholder="Apellido"
            value={formData.admin.surname}
            onChange={(e) => handleChange(e, 'surname', 'admin')}
            required
          />
          {errors.admin && <Text style={{ color: 'red' }}>{errors.admin}</Text>}

          <Text>Dirección Fiscal</Text>
          <Input
            placeholder="Calle"
            value={formData.fiscalAddress.street}
            onChange={(e) => handleChange(e, 'street', 'fiscalAddress')}
            required
          />
          <Input
            placeholder="Código Postal"
            value={formData.fiscalAddress.zip}
            onChange={(e) => handleChange(e, 'zip', 'fiscalAddress')}
            required
          />
          <Input
            placeholder="Ciudad"
            value={formData.fiscalAddress.city}
            onChange={(e) => handleChange(e, 'city', 'fiscalAddress')}
            required
          />
          <Input
            placeholder="País"
            value={formData.fiscalAddress.country}
            onChange={(e) => handleChange(e, 'country', 'fiscalAddress')}
            required
          />
          {errors.fiscalAddress && <Text style={{ color: 'red' }}>{errors.fiscalAddress}</Text>}

          <Text>Dirección del Local</Text>
          <Input
            placeholder="Calle"
            value={formData.localAddress.street}
            onChange={(e) => handleChange(e, 'street', 'localAddress')}
            required
          />
          <Input
            placeholder="Código Postal"
            value={formData.localAddress.zip}
            onChange={(e) => handleChange(e, 'zip', 'localAddress')}
            required
          />
          <Input
            placeholder="Ciudad"
            value={formData.localAddress.city}
            onChange={(e) => handleChange(e, 'city', 'localAddress')}
            required
          />
          <Input
            placeholder="País"
            value={formData.localAddress.country}
            onChange={(e) => handleChange(e, 'country', 'localAddress')}
            required
          />
          {errors.localAddress && <Text style={{ color: 'red' }}>{errors.localAddress}</Text>}

          <Button onPress={handleSubmit}>Guardar</Button>
        </Stack>
      )}
    </YStack>
  );
};

export default Step3;
