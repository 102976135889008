import axios from "../utils/axiosInstance";

export const getFoodApi = async (foodId) => {
  const response = await axios.post("foods/getFood", {
    foodId: foodId,
  });
  return response;
};

export const getFoodsApi = async (businessId) => {
  const response = await axios.post("foods/getFoods", {
    businessId: businessId,
  });
  return response.data.foods;
};

export const addFoodApi = async (menuId, businessId, food) => {
  const response = await axios.post("foods/addFood", {
    menuId: menuId,
    businessId: businessId,
    food: food,
  });
  return response;
};

export const addCompanionApi = async (foodId) => {
  const response = await axios.post("foods/addCompanion", {
    foodId: foodId,
  });
  return response;
};
export const addCompanionFoodApi = async (foodId, index) => {
  const response = await axios.post("foods/addCompanionFood", {
    foodId: foodId,
    index: index,
  });
  return response;
};

export const deleteFoodApi = async (foodId, menuId, businessId) => {
  const response = await axios.delete("foods/deleteFood", {
    data: { 
      foodId: foodId, 
      menuId: menuId, 
      businessId: businessId
    },
  });
  return response;
};

export const updateFoodApi = async (foodId, updatedFood) => {
  const response = await axios.put("foods/updateFood", {
    foodId: foodId,
    updatedFood: updatedFood,
  });
  return response;
};

export const updateFoodNameAPI = async (name, foodId, language) => {
  const response = await axios.put("foods/updateFoodName", {
    name: name,
    foodId: foodId,
    language: language
  });
  return response;
}

export const updateFoodPriceAPI = async (price, foodId) => {
  const formattedPrice = price.replace(',', '.');
  
  const response = await axios.put("foods/updateFoodPrice", {
    price: formattedPrice,
    foodId: foodId,
  });
  
  return response;
}

export const updateFoodPrice2API = async (price, foodId) => {
  const formattedPrice = price.replace(',', '.');
  
  const response = await axios.put("foods/updateFoodPrice2", {
    price: formattedPrice,
    foodId: foodId,
  });
  
  return response;
}

export const updateFoodDescriptionAPI = async (description, foodId, language) => {
  const response = await axios.put("foods/updateFoodDescription", {
    description: description,
    foodId: foodId,
    language: language
  });
  return response;
}

export const updateFoodImageAPI = async (image, foodId) => {
  const response = await axios.put("foods/updateFoodImage", {
    image: image,
    foodId: foodId,
  });
  return response;
}

export const getAllergenceAndNutCondApi = async (businessId, foodId) => {
  const params = {
    businessId: businessId,
    foodId: foodId
  };
  const response = await axios.get("foods/getAllergensAndNutCond", {params});
  return response.data.data;
};

export const putAllergenceAndNutCondApi = async (food) => {
  const response = await axios.put("foods/putAllergensAndNutCond", {
    food: food
  });
  return response.data.data;
}

export const getCombinationsAndMenusApi = async (businessId, foodId, companionId, language) => {
  const params = {
    businessId: businessId,
    foodId: foodId,
    companionId: companionId,
    language: language
  };
  const response = await axios.get("foods/getCombinationsAndMenus", {params});
  return response.data.data;
}

export const editProductWorkZoneApi = async (workZoneId, foodId) => {
  const response = await axios.put("foods/editProductWorkZone", {
    foodId: foodId,
    workZoneId: workZoneId
  });
  return response;
}

export const updateFoodSectionAPI = async (foodId, section, selectedRestaurant, language) => {
  const response = await axios.put("foods/updateFoodSection", {
    foodId: foodId,
    section: section,
    selectedRestaurant: selectedRestaurant,
    language: language
  });
  return response;
}

export const createFoodInCategoryAPI = async (category_id, food, language, selectedRestaurant) => {
  const response = await axios.post("foods/createFoodInCategory", {
    category_id: category_id,
    food: food,
    language: language,
    selectedRestaurant: selectedRestaurant
  });
  return response.data.category;
}

export const deleteFoodSectionAPI = async (foodId, sectionId, businessId) => {
  const response = await axios.delete("foods/deleteFoodSection", {data: {
    foodId: foodId,
    sectionId: sectionId,
    businessId: businessId
  }});
  return response;
}

export const POSGetFoodCompanionsAndCategoriesFoodsNames = async (companionFoodsIds, companionCategoryFoodsIds) => {
  const response = await axios.get("foods/POSGetFoodCompanionsAndCategoriesFoodsNames", {params: {
    companionFoodsIds: companionFoodsIds,
    companionCategoryFoodsIds: companionCategoryFoodsIds ? companionCategoryFoodsIds : []
  }});
  return response.data.data;
}