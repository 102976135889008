import axios from "../utils/axiosInstance";

export const signInAPI = async (email, password) => {
  const response = await axios.post("users/login", {
    email: email,
    password: password,
  });
  return response;
};

export const forgotPasswordAPI = async (email) => {
  const response = await axios.post("users/forgotPassword", {
    email: email,
  });
  return response;
};

export const newClientAPI = async (profile, settings) => {
  const response = await axios.post("users/addDashLocalClient", {
    profile: {
      ...profile,
      phone:
        profile?.phone && profile?.phone !== "" ? profile?.phone : undefined,
      email:
        profile?.email && profile?.email !== "" ? profile?.email : undefined,
    },
    settings
  });

  return response;
};

export const newFranchiseAPI = async (profile, settings) => {
  const response = await axios.post("users/addFranchiseClient", {
    profile: {
      ...profile,
      phone:
        profile?.phone && profile?.phone !== "" ? profile?.phone : undefined,
      email:
        profile?.email && profile?.email !== "" ? profile?.email : undefined,
    },
    settings: {
      banner: "",
      nutConditions: [
        { name: settings.nutConditions.nut1 },
        { name: settings.nutConditions.nut2 },
        { name: settings.nutConditions.nut3 },
      ],
      tips: [settings.tips.tip1, settings.tips.tip2, settings.tips.tip3],
      temporalCode:
        settings.temporalCode.length > 0 ? settings.temporalCode : undefined,
    },
  });

  return response;
};

export const searchClientsAPI = async () => {
  const response = await axios.get("users/searchClients");  
  return response;
};

export const forgotPasswordStep1API = async (email) => {
  const response = await axios.post("users/forgotPasswordStep1", {
    email: email,
  });
  return response;
};

export const forgotPasswordStep2API = async (email, code, password) => {
  const response = await axios.post("users/forgotPasswordStep2", {
    email: email,
    code: code,
    password: password,
  });
  return response;
};