import { getStringData } from "./storage";



export const isAuthBoolean = async () => {
    const token = await getStringData("token");
    // localStorage.getItem('user');
    if (token) {
        return token;
    } else {
        return false;
    }
};

