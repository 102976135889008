import React, { useState, useRef } from "react";
import { useEffect } from "react";

const CategoryItem = ({ title, base64Image, menuId, editNameCategory, editCategory, onClickCategory }) => {
    const categoryItemStyle = {
      position: 'relative',
      display: 'inline-block',
      width: '150px',
      height: '150px',
      margin: '10px 10px 10px 0px',
      overflow: 'hidden',
      borderRadius: '20px',
      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    };
  
    const imageStyle = {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    };
  
    const categoryItemContentStyle = {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      padding: '10px',
      background: 'rgba(0, 0, 0, 0.5)',
      color: 'white',
      textAlign: 'center',
    };
  
    const categoryItemTitleStyle = {
      fontSize: '1.5em',
      fontWeight: 'bold',
      backgroundColor: 'transparent',
      color: 'white',
      border: 0,
      outline: 'none'
    };
  
    const buttonStyle = {
      background: 'red',
      color: 'white',
      border: 'none',
      borderRadius: '50%',
      width: '30px',
      height: '30px',
      cursor: 'pointer',
    };
  
    const editButtonStyle = {
      ...buttonStyle,
    };

    const categoryButtonsStyle = {
        position: 'absolute',
        top: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'right',
        width: '93%',
    };
  
    const [imageUrl, setImageUrl] = useState(`data:image/jpeg;base64,${base64Image}`);
    const [categoryTitle, setCategoryTitle] = useState(title);

    const timeoutIdRef = useRef(null);

    const setTitle = (title) => {
      setCategoryTitle(title);
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
      timeoutIdRef.current = setTimeout(() => {
        editNameCategory(title, menuId);
        timeoutIdRef.current = null; 
      }, 1300);
    }

    const editCategoryHandler = (menuId) => {
      editCategory(menuId);
    }

    useEffect(() => {
      setCategoryTitle(title);
    }, [title]);

    useEffect(() => {
      setImageUrl(`data:image/jpeg;base64,${base64Image}`);
    }, [base64Image]);

    return (
      <div style={categoryItemStyle}>
        <div style={categoryButtonsStyle}>
          <button onClick={() => editCategoryHandler(menuId)} style={editButtonStyle}>
            <img src="/assets/icons/fi-rr-pencil.svg" alt="edit" style={{width: '17px', height: '17px'}}/>
          </button>
        </div>
        <img src={imageUrl} alt={title} style={imageStyle} onClick={onClickCategory}/>
        <div style={categoryItemContentStyle}>
          <input 
            style={categoryItemTitleStyle}
            type="text"
            value={categoryTitle}
            onChange={(event) => setTitle(event.target.value)}
          ></input>
        </div>
      </div>
    );
  };
  
  export default CategoryItem;