import React from 'react';

const SideVerticalStepper = ({ step }) => {
  return (
	<div 
		style={{
			display: 'flex',
			flexDirection: 'column',
			fontSize: '0.8rem',
			minWidth: '150px',
		}}
	>
	  <span style={{ fontWeight: step === 1 ? 'bold' : 'normal' }}>1. CREA TU CARTA</span>
	  <span style={{ fontWeight: step === 2 ? 'bold' : 'normal' }}>2. GUARDA TU CARTA</span>
	  <span style={{ fontWeight: step === 3 ? 'bold' : 'normal' }}>3. DEFINE TU IMAGEN</span>
	</div>
  );
};

export default SideVerticalStepper;