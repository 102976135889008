// src/App.js

import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { TamaguiProvider } from 'tamagui';
import tamaguiConfig from './tamagui.config.ts';
import store from "./redux/store";
import { Navigate } from 'react-router-dom';
import { getObjectData } from "./helpers/storage";

// Screens components
import Login from "./pages/login";
import ForgotPassword from "./pages/forgotPassword";
import Register from "./pages/register";
import AdminHome from "./pages/adminHome";
import EditProducts from "./pages/editProducts";
import EditZonesAndTables from "./pages/editZonesAndTables";
import CreateBusiness from "./pages/createBusiness";
import RestaurantFVNs from "./pages/restaurantFVN";
import LeftDrawer from "./components/leftDrawer";
import POS from "./pages/pos";
import TICKETS from "./pages/tickets";
import KITCHEN from "./pages/kitchen";
import Loader from "./components/loadingAnimation.jsx";

const globalCSS = `
  body {
    user-select: none;
    -webkit-user-select: none;
    max-width: 100%;
    overflow-x: hidden;
  }
`;

const PrivateRoute = ({ children }) => {
  return getObjectData("user") ? 
    <>
      <LeftDrawer />
      <div className="private-route-children">
        {children}
      </div>
    </> : <Navigate to="/" />;
};

const PublicRoute = ({ children }) => {
  return getObjectData("user") ? <Navigate to="/admin-home" /> : children;
};

const App = () => {
  return (
    <Provider store={store}>
      <Loader />
      <TamaguiProvider config={tamaguiConfig}>
        <Router>
          <style>{globalCSS}</style>
          <Routes>
            <Route exact path="/" element={<PublicRoute><Login /></PublicRoute>} />
            <Route exact path="/forgot-password" element={<PublicRoute><ForgotPassword /></PublicRoute>} />
            <Route exact path="/register" element={<PublicRoute><Register /></PublicRoute>} />
            <Route exact path="/admin-home" element={<PrivateRoute><AdminHome /></PrivateRoute>} />
            <Route exact path="/create-business" element={<PrivateRoute><CreateBusiness /></PrivateRoute>} />
            <Route exact path="/edit-zones-and-tables" element={<PrivateRoute><EditZonesAndTables /></PrivateRoute>} />
            <Route exact path="/edit-products" element={<PrivateRoute><EditProducts /></PrivateRoute>} />
            <Route exact path="/edit-products/:id" element={<PrivateRoute><EditProducts /></PrivateRoute>} />
            <Route exact path="/restaurant-fvn" element={<PrivateRoute><RestaurantFVNs /></PrivateRoute>} />
            <Route exact path="/pos" element={<PrivateRoute><POS /></PrivateRoute>} />
            <Route exact path="/tickets" element={<PrivateRoute><TICKETS /></PrivateRoute>} />
            <Route exact path="/kitchen" element={<PrivateRoute><KITCHEN /></PrivateRoute>} />
            <Route path="*" element={<Navigate replace to="/admin-home" />} />
          </Routes>
        </Router>
      </TamaguiProvider>
    </Provider>
  );
};

export default App;
