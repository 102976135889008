import axios from "../utils/axiosInstance";

export const getBusinessCoupon = async (businessId, code) => {
  const response = await axios.get(`coupons/getBusinessCoupon`, {
    params: {
      businessId: businessId, 
      code: code
    },
  });
  return response.data;
};

export const getBusinessCoupons = async (businessId) => {
  const response = await axios.get("coupons/getBusinessCoupons", {
    params: {
      businessId: businessId,
    },
  });
  return response;
};

export const addCoupon = async (coupon) => {
  const response = await axios.post("coupons/addCoupon", {
    coupon: coupon,
  });
  return response;
};

export const updateCoupon = async (coupon) => {
  const response = await axios.put("coupons/updateCoupon", {
    coupon: coupon,
  });
  return response;
};

export const deleteCoupon = async (couponId) => {
    const response = await axios.delete("coupons/deleteCoupon", {
        params: {
        couponId: couponId,
        },
    });
    return response;
};