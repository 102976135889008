import axios from "../utils/axiosInstance";

export const getMenusAPI = async (businessId, language) => {
  const response = await axios.post("menus/getMenus", {
    businessId: businessId,
    language: language,
  });
  return response.data.menus;
};

export const getCategoryFoodsApi = async (menuId, language) => {
  const params = {
    menuId: menuId,
    language: language
  }
  const response = await axios.get("menus/getCategoryFoods", {params});
  return response.data.foods;
};

export const addMenuAPI = async (businessId, name, description) => {
  const response = await axios.post("menus/addMenu", {
    businessId: businessId,
    name: name,
    description: description,
  });
  return response;
};

export const deleteMenuAPI = async (menuId, businessId) => {
  const response = await axios.delete("menus/deleteMenu", {
    data: { businessId: businessId, menuId: menuId },
  });
  return response;
};

export const updateMenuAPI = async (name, menuId, businessId, language, image) => {
  const response = await axios.put("menus/updateMenu", {
    businessId: businessId,
    menuId: menuId,
    name: name,
    language: language,
    image_url: image,
  });
  return response;
};
export const updateMenuFamilyAPI = async (menuId, familyId) => {
  const response = await axios.put("menus/updateMenuFamily", {
    menuId: menuId,
    familyId: familyId,
  });
  return response;
};

export const getCategoriesFoodsApi = async (menusIds) => {
  const params = {
    menusIds: menusIds
  }
  const response = await axios.get("menus/getCategoriesFoods", {params});
  return response.data.categoriesFoods;
};

export const createCategoryAPI = async (businessId, name, image, language) => {
  const response = await axios.post("menus/createCategory", {
    businessId: businessId,
    name: name,
    image_url: image,
    language: language,
  });
  return response.data.category;
};

export const updateCategoryOpenningTimesAPI = async (categoryId, openning_times) => {
  const response = await axios.put("menus/updateCategoryOpenningTimes", {
    categoryId: categoryId,
    openning_times: openning_times,
  });
  return response;
}