import React from "react";

const FamilyItem = ({addFamilyItemHandler}) => {
    const familyItemStyle = {
        position: 'relative',
        display: 'inline-block',
        width: '150px',
        height: '150px',
        marginTop: '10px',
        marginBottom: '10px',
        overflow: 'hidden',
        borderRadius: '20px',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
    };

    const familyItemImageStyle = {
        marginTop: '10px',
        width: '70%', 
        height: '70%'
    }

    return (
      <div style={familyItemStyle} onClick={addFamilyItemHandler}>
        <span>Añadir Familia</span>
        <img src="https://www.svgrepo.com/show/2087/plus.svg" style={familyItemImageStyle}/>
      </div>
    );
  };
  
  export default FamilyItem;