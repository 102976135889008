import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const FoodItem = ({ food, selectFood }) => {
    const videoRef = useRef(null);

    const isBase64 = food.image_url?.startsWith('/9j/');
    const isImageURL = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.webp'].some(extension => food.image_url?.toLowerCase().endsWith(extension));
    // const isVideoURL = ['.mp4', '.webm', '.ogg'].some(extension => food.image_url?.toLowerCase().endsWith(extension));

    useEffect(() => {
        const handleIntersection = (entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    videoRef.current?.play();
                } else {
                    videoRef.current?.pause();
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersection, { threshold: 0.5 });
        
        if (videoRef.current) {
            observer.observe(videoRef.current);
        }

        return () => {
            if (videoRef.current) {
                observer.unobserve(videoRef.current);
            }
        };
    }, []);

    return (
        <FoodContainer onClick={() => selectFood(food)} key={food._id}>
            <Overlay>
                <FoodTextContainer>
                    <FoodTitle>{food.name}</FoodTitle>
                    <FoodPrice>{food.price}</FoodPrice>
                </FoodTextContainer>
            </Overlay>
            {food.image_url && (
                isBase64 || isImageURL ? (
                    <FoodImage 
                        src={isBase64 ? `data:image/jpeg;base64,${food.image_url}` : food.image_url} 
                        alt={food.name.toUpperCase()}
                    />
                ) :
                // isVideoURL ? (
                //     <FoodVideo 
                //         ref={videoRef}
                //         muted
                //         loop
                //         playsInline
                //     >
                //         <source src={food.image_url} type={`video/${food.image_url.split('.').pop()}`} />
                //         Your browser does not support the video tag.
                //     </FoodVideo>
                // ) :
                null
            )}
        </FoodContainer>
    );
};

export default FoodItem;

const FoodContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100px;
    border-radius: 10px;
    background: #F0F0F0;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 0px 0px 2px 0px rgba(145, 158, 171, 0.20), 0px 12px 24px -3px rgba(145, 158, 171, 0.12);
`;

const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4); /* Fondo semitransparente */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
`;

const FoodTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    z-index: 2;
`;

const FoodTitle = styled.span`
    font-size: 18px;
    font-weight: 900;
    display: -webkit-box;
    -webkit-line-clamp: 2;  /* Limita el texto a 2 líneas */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
`;

const FoodPrice = styled.span`
    font-size: 14px;
    font-weight: 400;
    line-height: 19.1px;
`;

const FoodImage = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0; /* Imagen en el fondo */
`;

const FoodVideo = styled.video`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0; /* Video en el fondo */
`;
