import React, { useEffect, useState } from 'react';
import { getAllBusinessesAPI } from '../services/business.service';
import { getObjectData } from "../helpers/storage";

const restaurantsListMainContainer = {
  maxWidth: '230px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '10px',
  height: "fit-content",
  position: 'relative',
  zIndex: '2'
};

const restaurantsListStyle = {
  maxHeight: '200px',
  width: '100%',
  overflowY: 'auto',
  marginTop: '5px',
  marginBottom: '5px',
};

const restaurantStyle = {
  display: 'flex',
  flexDirection: 'column',
  padding: '10px',
  borderTop: '1px solid #ccc',
  cursor: 'pointer',
  fontSize: '12px',
};

const restaurantSearchInputStyle = {
  width: 'calc(100% - 21px)',
  padding: '10px',
  border: 'none',
  borderRadius: "10px",
  outline: 'none',
};

const dropbtnClose = {
  color: 'black',
  backgroundColor: "white",
  padding: '10px 20px',
  cursor: 'pointer',
  border: "1px solid black",
  borderRadius: "20px",
  outline: 'none',
  width: '100%',
};

const dropbtnOpen = {
  color: 'black',
  backgroundColor: "white",
  padding: '10px 20px',
  cursor: 'pointer',
  border: "1px solid black",
  borderRadius: "20px",
  outline: 'none',
  width: '100%',
};

const caretDown = {
  display: 'inline-block',
  marginLeft: '5px',
  width: '0',
  height: '0',
  verticalAlign: 'middle',
  borderTop: '4px solid',
  borderRight: '4px solid transparent',
  borderLeft: '4px solid transparent',
};

const caretUp = {
  display: 'inline-block',
  marginLeft: '5px',
  width: '0',
  height: '0',
  verticalAlign: 'middle',
  borderBottom: '4px solid',
  borderRight: '4px solid transparent',
  borderLeft: '4px solid transparent',
};

const absoluteStyle = {
  position: 'absolute',
  top: '100%',
  marginTop: "5px",
  left: '0',
  zIndex: '2',
  backgroundColor: 'white',
  border: '1px solid #ccc',
  borderRadius: "10px",
  width: '100%',
};

const font14 = {
  fontSize: '14px',
};

const SelectRestaurantInput = ({setSelectedRestaurant}) => {
  const [openSelectedRestaurant, setOpenSelectedRestaurant] = useState(false);
  const [search, setSearch] = useState('');
  const [options, setOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);

  useEffect(() => {
    getAllBusinessesAPI(getObjectData("user")._id).then((res) => { 
      setOptions(res);
      res.find((business) => {
        if (business._id === JSON.parse(localStorage.getItem("selectedBusinessId"))?._id) {
          setSelectedRestaurant(business);
          setSearch(business.companyName);
        }
      });
    });
  }, []);

  useEffect(() => {
    if (options.length > 0) {
      setFilteredOptions(options?.filter(option => {
        return option?.companyName ? 
          option.companyName.toLowerCase().includes(search.toLowerCase()) 
          : false
      }));
    }
  }, [options, search]);

  const setNewBusiness = (business) => {
    setSearch(business.companyName);
    setSelectedRestaurant(business);
    localStorage.setItem("selectedBusinessId", JSON.stringify({_id: business._id, name: business.companyName}));
    setOpenSelectedRestaurant(false);
  };

  return (
    <div style={restaurantsListMainContainer}>
        <button style={openSelectedRestaurant ? dropbtnOpen : dropbtnClose} onClick={() => setOpenSelectedRestaurant(!openSelectedRestaurant)}>
          ELEGIR LOCAL <span style={openSelectedRestaurant ? caretUp : caretDown}></span>
        </button>
        { openSelectedRestaurant ?
          <div style={absoluteStyle}>
            <input
              type="text"
              placeholder="Buscar restaurantes o franquicias..."
              value={search}
              style={restaurantSearchInputStyle}
              onChange={(e) => setSearch(e.target.value)}
            />
            <div style={restaurantsListStyle}>
              {filteredOptions.map((option, index) => (
                <div style={restaurantStyle} key={index} onClick={() => setNewBusiness(option)}>
                  <span style={font14}>
                    <strong>{option.companyName}</strong>
                  </span>
                  <span>
                    {option?.localAddress?.street}, {option?.localAddress?.city}, {option?.localAddress?.country}
                  </span>
                  <span>
                    {option?.type}
                  </span>
                </div>
              ))}
            </div>
          </div>
        : null}      
    </div>
  );
};

export default SelectRestaurantInput;