import React, { useState } from "react";
import { Button, Input, Text, XStack, YStack, Stack } from 'tamagui';
import { validateExistsEmail, registerSendValidationCodeToEmail, validateCodeFromDashRegister, registerUserFromDash } from '../../services/business.service';

const Step2 = ({ setStep }) => {
  
  const [formStep, setFormStep] = useState(1);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [wantNews, setWantNews] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [errors, setErrors] = useState({});

  const handleWantsNews = async (wantsToRecieveNews) => {
    setWantNews(wantsToRecieveNews);
    await handleNextStep();
  };

  const handleNextStep = async () => {
    if (await validateStep()) {
      if (formStep === 3) {
        const response = await registerSendValidationCodeToEmail(email);
        if (response.resp.status !== 200) {
          console.error('Error enviando correo con código de verificación... Vuelva a intentarlo más tarde.'); // TODO show error in UI
          return;
        }
      } else if (formStep === 4) {
        const registeredUser = await registerUserFromDash(
          name,
          email,
          wantNews
        );
        const user = registeredUser.resp.user;
        localStorage.setItem('preuser', JSON.stringify(user));
        setStep(3);
        return;
      }
      setFormStep(prevStep => prevStep + 1);
    }
  };

  const handlePrevStep = () => {
    if (formStep === 1) {
      setStep(1);
    }
    setFormStep(prevStep => prevStep - 1);
  };

  const validateStep = async () => {
    let stepErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (formStep === 1 && !name.trim()) {
      stepErrors.name = 'El nombre es requerido';
    } else if (formStep === 2 && !email.trim()) {
      stepErrors.email = 'El correo es requerido';
    } else if (formStep === 2 && !emailRegex.test(email)) {
      stepErrors.email = 'El correo no es válido';
    } else if (formStep === 2) {
      const response = await validateExistsEmail(email);
      if (response.resp.status !== 200) {
        stepErrors.email = 'El correo ya está en uso';
      }
    } else if (formStep === 4 && verificationCode.length !== 6) {
      stepErrors.verificationCode = 'El código de verificación debe tener 6 dígitos';
    } else if (formStep === 4) {
      const response = await validateCodeFromDashRegister(email, verificationCode);
      if (response.resp.status === 400) {
        stepErrors.verificationCode = response.resp.message;
      } else if (response.resp.status !== 200) {
        stepErrors.verificationCode = 'El código de verificación no es válido';
      }
    }
    setErrors(stepErrors);
    return Object.keys(stepErrors).length === 0;
  };

  return (
    <YStack>
      {formStep === 1 && (
        <Stack>
          <Text>Encantado, soy María, el asistente de ibero.app</Text>
          <Text>¿Cómo te llamas?</Text>
          <Input value={name} onChangeText={setName} placeholder="Tu nombre" />
          {errors.name && <Text style={{ color: 'red' }}>{errors.name}</Text>}
        </Stack>
      )}
      {formStep === 2 && (
        <Stack>
          <Text>Hola {name}, dinos tu correo para guardar la carta.</Text>
          <Text>¿Cuál es tu correo?</Text>
          <Input value={email} onChangeText={setEmail} placeholder="Tu correo" />
          {errors.email && <Text style={{ color: 'red' }}>{errors.email}</Text>}
        </Stack>
      )}
      {formStep === 3 && (
        <Stack>
          <Text>Perfecto. Tenemos un boletín de noticias relevantes y actualizaciones.</Text>
          <Text>¿Te gustaría correos informativos?</Text>
          <Button onPress={() => handleWantsNews(true)}>Sí, quiero recibir correos</Button>
          <Button onPress={() => handleWantsNews(false)}>No quiero recibir noticias</Button>
        </Stack>
      )}
      {formStep === 4 && (
        <Stack>
          <Text>Genial. Vamos a verificar tu correo electrónico, lo hacemos por seguridad.</Text>
          <Text>Introduce los 6 dígitos enviado a {email};</Text>
          <Input value={verificationCode} onChangeText={setVerificationCode} placeholder="Código de verificación" />
          {errors.verificationCode && <Text style={{ color: 'red' }}>{errors.verificationCode}</Text>}
        </Stack>
      )}
      <XStack>
        <Button onPress={handlePrevStep}>Volver</Button>
        {formStep < 5 && <Button onPress={handleNextStep}>Siguiente</Button>}
      </XStack>
    </YStack>
  );
};

export default Step2;
