import React from 'react';
import styled from 'styled-components';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  z-index: 2;
`;

const ModalContainer = styled.div`
  background-color: white;
  padding: 20px;
  margin: 10% auto;
  width: fit-content;
  min-width: 320px;
  max-height: 70%;
  border-radius: 12px;
  box-shadow: 0px 0px 2px 0px rgba(145, 158, 171, 0.2),
    0px 12px 24px -4px rgba(145, 158, 171, 0.12);
  overflow-y: auto;
`;

const Modal = ({ isOpen, children }) => {
    if (!isOpen) return null;
    return (
        <ModalOverlay>
            <ModalContainer>
                {children}
            </ModalContainer>
        </ModalOverlay>
    );
}

export default Modal;
