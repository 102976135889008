import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { forgotPasswordStep1API, forgotPasswordStep2API } from "../services/user.service";
import { useNavigate } from "react-router-dom";

const Login = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [step, setStep] = useState(1);

	const [email, setEmail] = useState("");
	const [validationCode, setValidationCode] = useState("");
	const [password, setPassword] = useState("");
	const [password2, setPassword2] = useState("");

	const [isValidEmail, setIsValidEmail] = useState(true);
	const [isValidCode, setIsValidCode] = useState(true);
	const [isValidPwd, setIsValidPwd] = useState(true);
	const [isValidPwd2, setIsValidPwd2] = useState(true);

	const [errMsg, setErrMsg] = useState("");
	const [codeErrMsg, setCodeErrMsg] = useState("");
	const [passErrMsg, setPassErrMsg] = useState("");
	const [pass2ErrMsg, setPass2ErrMsg] = useState("");

	const [formSubmitted, setFormSubmitted] = useState(false);

	const emailRef = useRef();
	const codeRef = useRef();
	const passwordRef = useRef();
	const password2Ref = useRef();


	const inputStyleEmail = {
		margin: "5px 0",
		padding: "10px",
		border: formSubmitted
		? isValidEmail
			? "0px"
			: "1px solid red"
		: "0px",
		borderRadius: "20px",
		boxShadow: "0 0 50px rgba(0, 0, 0, 0.05)",
	};

	const inputStyleCode = {
		margin: "5px 0",
		padding: "10px",
		border: formSubmitted
		? isValidCode
			? "0px"
			: "1px solid red"
		: "0px",
		borderRadius: "20px",
		boxShadow: "0 0 50px rgba(0, 0, 0, 0.05)",
	};

	const inputStylePwd = {
		margin: "5px 0",
		padding: "10px",
		border: formSubmitted
		? isValidPwd
			? "0px"
			: "1px solid red"
		: "0px",
		borderRadius: "20px",
		boxShadow: "0 0 50px rgba(0, 0, 0, 0.05)",
	};

	const inputStylePwd2 = {
		margin: "5px 0",
		padding: "10px",
		border: formSubmitted
		? isValidPwd2
			? "0px"
			: "1px solid red"
		: "0px",
		borderRadius: "20px",
		boxShadow: "0 0 50px rgba(0, 0, 0, 0.05)",
	};

	const buttonStyle = {
		marginTop: "10px",
		padding: "8px 15px",
		border: "none",
		borderRadius: "20px",
		backgroundColor: "#007bff",
		color: "white",
		cursor: "pointer",
		boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
	};

	const errorMsgStyle = {
		color: "red",
		margin: "5px 0",
	};

	const handleInputChange = (type, value) => {
		switch (type) {
		case "EMAIL":
			setEmail(value);
			setIsValidEmail(
				/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)
			);
			break;
		case "VALIDATION_CODE":
			setValidationCode(value);
			setIsValidCode(value.length >= 6);
			break;
		case "PWD":
			setPassword(value);
			setIsValidPwd(value.length >= 6);
			setIsValidPwd2(password2.length >= 6 && value === password2);
			break;
		case "PWD2":
			setPassword2(value);
			setIsValidPwd2(value.length >= 6 && value === password);
			break;
		default:
			break;
		}
	};

	const handleFormSubmission = async () => {
		setFormSubmitted(true);
			try {
				switch (step) {
					case 1:
						if(isValidEmail && email !== "") {
							forgotPasswordStep1API(email).then((response) => {
								if (response.status === 200) {
									setStep(2);
								}
							}).catch((err) => {
								if (err.response.status === 404) {
									setIsValidEmail(false);
									setErrMsg("Correo no encontrado");
								}
							});
						} else {
							setErrMsg("Correo no válido");
						}
						break;
					case 2:
						if (isValidCode && isValidPwd && validationCode && validationCode !== "" && password && password !== "" && isValidPwd2 && password2 && password2 !== "" && password === password2) {
							forgotPasswordStep2API(email, validationCode, password).then((response) => {
								if (response.status === 200) {
									navigate("/login");
								}
							}).catch((err) => {
								if (err.response.status === 404) {
									return setCodeErrMsg("Código inválido");
								}
							});
						} else {
							if (!isValidCode || !validationCode || validationCode === "") {
								setIsValidCode(false);
								setCodeErrMsg("Código inválido");
							}
							if (!isValidPwd || !password || password === "") {
								setIsValidPwd(false);
								setPassErrMsg("Contraseña inválida");
							}
							if (!isValidPwd2 || !password2 || password2 === "" || password !== password2) {
								setIsValidPwd2(false);
								setPass2ErrMsg("Contraseña inválida");
							}
						}
						break;
					default:
						break;
				}
			} catch (err) {
				console.error(err);
			}
	};

	return (
		<div>
			<span style={{cursor: 'pointer', margin: '30px'}} onClick={() => navigate("/")}>Volver</span>
			<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				height: "80vh",
			}}
			>
				<div
					style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					width: "400px",
					}}
				>
					<h1>Recuperar contraseña</h1>
					{step === 1 && (
						<div>
							<p>Ingresa tu correo electrónico para recuperar tu contraseña</p>
							<input
							ref={emailRef}
							placeholder="Correo electrónico"
							onChange={(e) => handleInputChange("EMAIL", e.target.value)}
							value={email}
							style={inputStyleEmail}
							type="email"
							/>
							{!isValidEmail && <p style={errorMsgStyle}>{errMsg}</p>}
							<button style={buttonStyle} onClick={handleFormSubmission}>
							Enviar
							</button>
						</div>
					)}
					{step === 2 && (
						<div>
							<p>Ingresa el código de validación que te hemos enviado a tu correo electrónico</p>
							<div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly'}}>
								<div style={{display: 'flex', flexDirection: 'row', gap: '30px', alignItems: 'center'}}>
									<input
										ref={codeRef}
										placeholder="Código de validación"
										onChange={(e) => handleInputChange("VALIDATION_CODE", e.target.value)}
										value={validationCode}
										style={inputStyleCode}
										type="text"
									/>
									{!isValidCode && <p style={errorMsgStyle}>{codeErrMsg}</p>}
								</div>
								<div style={{display: 'flex', flexDirection: 'row', gap: '30px', alignItems: 'center'}}>
									<input
										ref={passwordRef}
										placeholder="Nueva contraseña"
										onChange={(e) => handleInputChange("PWD", e.target.value)}
										value={password}
										style={inputStylePwd2}
										type="password"
									/>
									{!isValidPwd && <p style={errorMsgStyle}>{passErrMsg}</p>}
								</div>
								<div style={{display: 'flex', flexDirection: 'row', gap: '30px', alignItems: 'center'}}>
									<input
										ref={password2Ref}
										placeholder="Confirmar contraseña"
										onChange={(e) => handleInputChange("PWD2", e.target.value)}
										value={password2}
										style={inputStylePwd2}
										type="password"
									/>
									{!isValidPwd2 && <p style={errorMsgStyle}>{pass2ErrMsg}</p>}
								</div>
							</div>

							<button style={buttonStyle} onClick={handleFormSubmission}>
							Enviar
							</button>
						</div>
					)}
				</div>
			</div>
		</div>	
	);
};

export default Login;
