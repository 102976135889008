import axios from "../utils/axiosInstance";

export const getBusinessFamiliesAPI = async (businessId) => {
    const response = await axios.get("family/getBusinessFamilies", {
      params: {
        businessId: businessId,
      },
    });
    console.log(response.data.families);
    return response.data.families;
};

export const createFamilyAPI = async (businessId, name, image, language) => {
    const response = await axios.post("family/createFamily", {
      businessId: businessId,
      name: name,
      image_url: image,
      language: language,
    });
    return response.data.family;
  };
  
export const updateFamilyAPI = async (name, familyId, businessId, language, image) => {
    const response = await axios.put("family/updateFamily", {
        businessId: businessId,
        familyId: familyId,
        name: name,
        language: language,
        image_url: image,
    });
    return response.data.family;
};

export const deleteFamilyAPI = async (familyId, businessId) => {
    const response = await axios.delete("family/deleteFamily", {
        data: {
        businessId: businessId,
        familyId: familyId,
        }
    });
    return response;
}