import { configureStore } from "@reduxjs/toolkit";
import auth from "../features/authSlice/authSlice";
import business from "../features/businessSlice/businessSlice";
import loadingReducer from '../features/loadingSlice/loadingSlice';
import client from "../features/clientSlice/clientSlice";
import ticketReducer from "../features/ticketSlice/ticketSlice";


const store = configureStore({
  reducer: {
    auth,
    business,
    loading: loadingReducer,
    client,
    tickets: ticketReducer,
  },
  devTools: process.env.NODE_ENV !== "production",
});

export default store;