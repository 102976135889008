import React, { useState, useEffect } from "react";
import { 
    getBusinessCoupons,
    addCoupon,
    updateCoupon,
    deleteCoupon
} from '../../services/coupon.service';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import moment from 'moment';

const styles = {
    couponsInput: { 
        width: '200px', 
        marginTop: '5px',
        marignBottom: '5px',
        padding: '5px', 
        border: '1px solid gray',
        borderRadius: '5px',
    },
    couponsInputSmall: {
        width: '100px', 
        marignBottom: '5px',
        padding: '5px', 
        border: '1px solid gray',
        borderRadius: '5px',
    },
    couponsChips: { 
        width: 'fit-content', 
        display: 'flex',
        flexWrap: 'wrap',
        gap: '5px',
    },
    couponsChip: { 
        width: 'fit-content',
        alignItems: 'center',
        marginTop: '5px',
        marignBottom: '5px',
        padding: '5px',
        paddingLeft: '10px',
        border: '1px solid #ccc',
        borderRadius: '20px',
        display: 'flex',
        gap: '5px',
    },
    couponsChipsData: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: '14px',
    },
    couponsChipButton: {
        background: 'red',
        color: 'white',
        border: 'none',
        borderRadius: '50%',
        width: '30px',
        height: '30px',
        cursor: 'pointer',
    },
    couponsContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        padding: '10px',
        gap: '10px',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
    },
    checkboxContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: '5px',
        alignItems: 'center'
    },
    couponsTitle: {
        fontSize: '18px',
    },
    togetherContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: '10px',
    }
};

const Coupons = ({businessId}) => {
    const [couponsChips, setCouponsChips] = useState([]);
    const [couponNameInputValue, setCouponNameInputValue] = useState('');
    const [hasCouponPercentage, setHasCouponPercentage] = useState(true);
    const [couponPercentageInputValue, setCouponPercentageInputValue] = useState('');
    const [hasCouponAmount, setHasCouponAmount] = useState(false);
    const [couponAmountInputValue, setCouponAmountInputValue] = useState('');
    const [couponActive, setCouponActive] = useState(true);
    const [hasCouponDateLimit, setHasCouponDateLimit] = useState(false);
    const [dateInit, setDateInit] = useState(moment());
    const [dateEnd, setDateEnd] = useState(moment().add(1, 'days'));
    const [couponMinApplyPriceInputValue, setCouponMinApplyPriceInputValue] = useState(0);
    const [couponMaxAmountInputValue, setCouponMaxAmountInputValue] = useState(1000);
    const [hasCouponMaxUses, setHasCouponMaxUses] = useState(false);
    const [couponMaxUsesInputValue, setCouponMaxUsesInputValue] = useState(0);
    const [isEditCouponId, setIsEditCouponId] = useState(null);
    useEffect(() => {
        getBusinessCoupons(businessId).then((response) => {
            setCouponsChips(response.data.coupons);
        });
    }, [businessId]);

    const handleCouponNameInputChange = (e) => {
        setCouponNameInputValue(e.target.value);
    }

    const handleCouponPercentageInputChange = (e) => {
        setCouponPercentageInputValue(e.target.value);
        setCouponAmountInputValue('');
    }

    const handleCouponAmountInputChange = (e) => {
        setCouponAmountInputValue(e.target.value);
        setCouponPercentageInputValue('');
    }

    const handleCouponMinApplyPriceInputChange = (e) => {
        setCouponMinApplyPriceInputValue(e.target.value);
    }

    const handleCouponMaxAmountInputChange = (e) => {
        setCouponMaxAmountInputValue(e.target.value);
    }

    const handleCouponMaxUsesInputChange = (e) => {
        setCouponMaxUsesInputValue(e.target.value);
    }

    const handleAddCoupon = () => {
        if (!couponNameInputValue || (
            (!couponPercentageInputValue || +couponPercentageInputValue < 0 || +couponPercentageInputValue > 100) 
            && (!couponAmountInputValue || +couponAmountInputValue < 0))) {
            return;
        }
        addCoupon({
            business: businessId,
            code: couponNameInputValue, 
            has_percentage: hasCouponPercentage,
            percentage: couponPercentageInputValue,
            has_amount: hasCouponAmount,
            amount: couponAmountInputValue,
            active: couponActive,
            has_date_limit: hasCouponDateLimit,
            date_init: dateInit,
            date_end: dateEnd,
            min_apply_price: couponMinApplyPriceInputValue,
            max_amount: couponMaxAmountInputValue,
            max_uses: hasCouponMaxUses ? couponMaxUsesInputValue : 0,
        }).then((response) => {
            if (!response.data.error) {
                setCouponsChips([...couponsChips, response.data.coupon]);
                cleanData();
            } else {
                alert('El cupón ya existe');
            }
        });
    }

    const handleEditCoupon = () => {
        if (!couponNameInputValue || (
            (!couponPercentageInputValue || +couponPercentageInputValue < 0 || +couponPercentageInputValue > 100) 
            && (!couponAmountInputValue || +couponAmountInputValue < 0))) {
            return;
        }
        updateCoupon({
            _id: isEditCouponId,
            business: businessId,
            code: couponNameInputValue,
            has_percentage: hasCouponPercentage,
            percentage: couponPercentageInputValue,
            has_amount: hasCouponAmount,
            amount: couponAmountInputValue,
            active: couponActive,
            has_date_limit: hasCouponDateLimit,
            date_init: dateInit,
            date_end: dateEnd,
            min_apply_price: couponMinApplyPriceInputValue,
            max_amount: couponMaxAmountInputValue,
            max_uses: hasCouponMaxUses ? couponMaxUsesInputValue : 0,
            }).then((response) => {
                if (!response.data.error) {
                    setCouponsChips([...couponsChips.filter((c) => c._id !== response.data.coupon._id), response.data.coupon]);
                    cleanEditData();
                } else {
                    alert('El cupón ya existe');
                }
            });
    }

    const cleanEditData = () => {
        setIsEditCouponId(null);
        cleanData();
    }

    const cleanData = () => {
        setCouponNameInputValue('');
        setCouponPercentageInputValue('');
        setCouponAmountInputValue('');
        setCouponActive(true);
        setHasCouponDateLimit(false);
        setDateInit(moment());
        setDateEnd(moment().add(1, 'days'));
        setCouponMinApplyPriceInputValue(0);
        setCouponMaxAmountInputValue(1000);
        setCouponMaxUsesInputValue(1000);
    }

    const handleCouponsDeleteChip = (index) => {
        deleteCoupon(couponsChips[index]._id).then(() => {
            const newChips = couponsChips.filter((chip, chipIndex) => chipIndex !== index);
            setCouponsChips(newChips);
        });
    }

    const handleCouponsChipEdit = (index, _id) => {
        setIsEditCouponId(_id);
        const chip = couponsChips[index];
        setCouponNameInputValue(chip.code);
        setHasCouponPercentage(chip.has_percentage);
        setCouponPercentageInputValue(chip.percentage);
        setHasCouponAmount(chip.has_amount);
        setCouponAmountInputValue(chip.amount);
        setCouponActive(chip.active);
        setHasCouponDateLimit(chip.has_date_limit);
        setDateInit(moment(chip.date_init));
        setDateEnd(moment(chip.date_end));
        setCouponMinApplyPriceInputValue(chip.min_apply_price);
        setCouponMaxAmountInputValue(chip.max_amount);
        setHasCouponMaxUses(chip.max_uses > 0);
        setCouponMaxUsesInputValue(chip.max_uses);
    }

    return (
        <div>
            <div style={styles.couponsContainer}>
                <span style={styles.couponsTitle}>Añadir cupón</span>
                <input
                    type="text"
                    placeholder="Código del cupón"
                    style={styles.couponsInput}
                    value={couponNameInputValue}
                    onChange={handleCouponNameInputChange}
                />
                <div style={styles.checkboxContainer}>
                    <span>Porcentaje</span>
                    <label className="switch">
                        <input 
                            type="checkbox" 
                            value={hasCouponPercentage}
                            checked={hasCouponPercentage}
                            onChange={() => {
                                setCouponMaxAmountInputValue(hasCouponPercentage ? null: 1000);
                                setHasCouponAmount(hasCouponPercentage)
                                setHasCouponPercentage(!hasCouponPercentage);
                                setCouponPercentageInputValue('');
                                setCouponAmountInputValue('');
                            }} 
                        />
                        <span className="slider round"></span>
                    </label>
                    {hasCouponPercentage ? (
                        <input
                            type="number"
                            placeholder="Porcentaje"
                            style={styles.couponsInputSmall}
                            value={couponPercentageInputValue}
                            onChange={handleCouponPercentageInputChange}
                        />
                    ) : null}
                    
                </div>
                <div style={styles.checkboxContainer}>
                    <span>Cantidad €</span>
                    <label className="switch">
                        <input 
                            type="checkbox" 
                            value={hasCouponAmount}
                            checked={hasCouponAmount}
                            onChange={() => {
                                setCouponMaxAmountInputValue(hasCouponPercentage ? null: 1000);
                                setHasCouponAmount(hasCouponPercentage);
                                setHasCouponPercentage(!hasCouponPercentage);
                                setCouponPercentageInputValue('');
                                setCouponAmountInputValue('');
                            }} 
                        />
                        <span className="slider round"></span>
                    </label>
                    {hasCouponAmount ? (
                        <input
                            type="number"
                            placeholder="Cantidad"
                            style={styles.couponsInputSmall}
                            value={couponAmountInputValue}
                            onChange={handleCouponAmountInputChange}
                        />
                    ) : null}
                </div>
                <div>
                    <span>Activo</span>
                    <label className="switch">
                        <input 
                            type="checkbox" 
                            value={couponActive}
                            checked={couponActive}
                            onChange={() => {setCouponActive(!couponActive)}} 
                        />
                        <span className="slider round"></span>
                    </label>
                </div>
                <div style={styles.togetherContainer}>
                    <span>Rango de fecha</span>
                    <label className="switch">
                        <input 
                            type="checkbox" 
                            value={hasCouponDateLimit}
                            checked={hasCouponDateLimit}
                            onChange={() => {
                                setHasCouponDateLimit(!hasCouponDateLimit);
                            }} 
                        />
                        <span className="slider round"></span>
                    </label>
                    {hasCouponDateLimit ? (
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <div style={styles.datetimepickers}>
                                <DateTimePicker
                                    label="Fecha de inicio"
                                    value={dateInit}
                                    onChange={(date) => setDateInit(date)}
                                    maxDateTime={dateEnd}
                                />
                                <DateTimePicker
                                    label="Fecha de fin"
                                    value={dateEnd}
                                    onChange={(date) => setDateEnd(date)}
                                    minDateTime={dateInit}
                                />
                            </div>
                        </LocalizationProvider>                        
                    ) : null}
                </div>
                <div style={styles.togetherContainer}>
                    <span>Precio mínimo €</span>
                    <input
                        type="text"
                        placeholder="Precio mínimo"
                        style={styles.couponsInputSmall}
                        value={couponMinApplyPriceInputValue}
                        onChange={handleCouponMinApplyPriceInputChange}
                    />
                </div>
                {hasCouponPercentage ? (
                    <div style={styles.togetherContainer}>
                        <span>Cantidad máxima €</span>
                        <input
                            type="text"
                            placeholder="Cantidad máxima"
                            style={styles.couponsInputSmall}
                            value={couponMaxAmountInputValue}
                            onChange={handleCouponMaxAmountInputChange}
                        />
                    </div>
                ) : null}    
                <div style={styles.checkboxContainer}>
                    <span>Usos máximos</span>
                    <label className="switch">
                        <input 
                            type="checkbox" 
                            value={hasCouponMaxUses}
                            checked={hasCouponMaxUses}
                            onChange={() => {
                                setHasCouponMaxUses(!hasCouponMaxUses);
                                setCouponMaxUsesInputValue(1000);
                            }} 
                        />
                        <span className="slider round"></span>
                    </label>
                    {hasCouponMaxUses ? (
                        <input
                            type="number"
                            placeholder="Cantidad"
                            style={styles.couponsInputSmall}
                            value={couponMaxUsesInputValue}
                            onChange={handleCouponMaxUsesInputChange}
                        />
                    ) : null}
                </div>            
                <button className="button-primary" onClick={()=> {
                    if (isEditCouponId) {
                        handleEditCoupon();
                    } else {
                        handleAddCoupon();
                    } 
                }}>
                    {isEditCouponId ? 'Editar' : 'Añadir'}
                </button>
                {isEditCouponId ? (
                    <button className="button-deny" onClick={cleanEditData}>Crear nuevo</button>
                ) : null}
            </div>
            <div style={styles.couponsChips}>
                {couponsChips ? couponsChips.map((chip, index) => (
                    <div key={index} onClick={() => handleCouponsChipEdit(index, chip._id)} style={styles.couponsChip}>
                        <div style={styles.couponsChipsData}>
                            {chip.code}, {chip.percentage ? (chip.percentage + '%') : (chip.amount + '€')}
                        </div>
                        <button style={styles.couponsChipButton} onClick={() => handleCouponsDeleteChip(index)}>X</button>
                    </div>
                )) : null}
            </div>
        </div>
    );
}

export default Coupons;