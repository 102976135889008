import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { signInAPI } from "../services/user.service";
import { logIn } from "../features/authSlice/authSlice";
import { useNavigate } from "react-router-dom";
import axiosInstance, { setServer } from "../utils/axiosInstance"; // Importa AxiosInstance y la función setServer

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPwd, setIsValidPwd] = useState(true);
  const [errMsg, setErrMsg] = useState("");
  const [passErrMsg, setPassErrMsg] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isInvalidConvination, setIsInvalidConvination] = useState(false);
  const emailRef = useRef();
  const passwordRef = useRef();


  const [server, setServerLocal] = useState("real");

  const inputStyle = {
    margin: "5px 0",
    padding: "10px",
    border: formSubmitted
      ? isValidEmail && isValidPwd
        ? "0px"
        : "1px solid red"
      : "0px",
    borderRadius: "20px",
    boxShadow: "0 0 50px rgba(0, 0, 0, 0.05)",
  };

  const buttonStyle = {
    marginTop: "10px",
    padding: "8px 15px",
    border: "none",
    borderRadius: "20px",
    backgroundColor: "#007bff",
    color: "white",
    cursor: "pointer",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
  };

  const errorMsgStyle = {
    color: "red",
    margin: "5px 0",
  };

  const forgorPasswordStyle = {
    cursor: "pointer",
    margin: "5px 0",
  };

  const selector = {
    margin: "20px 0"
  };

  const handleInputChange = (type, value) => {
    setIsInvalidConvination(false);
    switch (type) {
      case "EMAIL":
        setEmail(value);
        if (formSubmitted) {
          setIsValidEmail(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value));
        }
        break;
      case "PWD":
        setPassword(value);
        if (formSubmitted) {
          setIsValidPwd(value.length >= 6);
        }
        break;
      default:
        break;
    }
  };

  const handleFormSubmission = async () => {
    setFormSubmitted(true);
    if (isValidEmail && isValidPwd) {
      try {
        const res = await signInAPI(email, password);
        if (res) {
          dispatch(logIn(res.data));
          navigate("/admin-home");
        }
      } catch (err) {
        setIsInvalidConvination(true);
      }
    } else {
      setErrMsg("Correo no válido");
      setPassErrMsg("La contraseña debe tener mínimo 6 caracteres");
    }
  };

  const forgotPassword = () => {
    navigate("/forgot-password");
    //forgotPasswordAPI(email);
  };

  const handleServerChange = (selectedServer) => {
    setServerLocal(selectedServer);
    setServer(selectedServer); // Actualiza el servidor en AxiosInstance.js
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "400px",
        }}
      >
        <input
          ref={emailRef}
          placeholder="Correo electrónico"
          onChange={(e) => handleInputChange("EMAIL", e.target.value)}
          value={email}
          style={inputStyle}
          type="email"
        />
        {!isValidEmail && <p style={errorMsgStyle}>{errMsg}</p>}
        <input
          ref={passwordRef}
          placeholder="Contraseña"
          onChange={(e) => handleInputChange("PWD", e.target.value)}
          value={password}
          style={inputStyle}
          type="password"
        />
        {!isValidPwd && <p style={errorMsgStyle}>{passErrMsg}</p>}
        {isInvalidConvination && (
          <p style={errorMsgStyle}>Correo o contraseña incorrectos</p>
        )}
        <span style={forgorPasswordStyle} onClick={forgotPassword}>
          ¿Olvidaste la contraseña?
        </span>
        <button onClick={handleFormSubmission} style={buttonStyle}>
          Entrar
        </button>
        {/* Selector de servidor */}
        <select style={selector} onChange={(e) => handleServerChange(e.target.value)}>
          <option value="real">Real</option>
          <option value="test">Test</option>
        </select>
      </div>
    </div>
  );
};

export default Login;
