// src/utils/axiosInstance.js

import axios from 'axios';
import { getStringData } from '../helpers/storage';
import { setLoading, setResponseStatus } from '../features/loadingSlice/loadingSlice';
import store from '../redux/store';

let server = 'real';

const instance = axios.create({
  baseURL: getBaseUrl(),
});

instance.interceptors.request.use(
  async (config) => {
    let user = await getStringData('user');
    if (user) {
      user = JSON.parse(user);
      if (user.token) {
        config.headers.Authorization = `Bearer ${user.token}`;
      }
    }
    store.dispatch(setLoading(true)); // Muestra el loader
    store.dispatch(setResponseStatus('loading')); // Estado de carga
    return config;
  },
  (error) => {
    store.dispatch(setLoading(false)); // Oculta el loader
    store.dispatch(setResponseStatus('error')); // Estado de error
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    store.dispatch(setLoading(false)); // Oculta el loader
    store.dispatch(setResponseStatus('success')); // Estado de éxito
    return response;
  },
  (error) => {
    store.dispatch(setLoading(false)); // Oculta el loader
    store.dispatch(setResponseStatus('error')); // Estado de error
    return Promise.reject(error);
  }
);

export const setServer = (selectedServer) => {
  server = selectedServer;
  instance.defaults.baseURL = getBaseUrl();
};

function getBaseUrl() {
  //return "http://localhost:3500/api/";
  return server === "real" ? "https://menu.ibero.app/api/" : "https://menu-test.ibero.app/api/"; // http://localhost:3500/api/
}

export default instance;