import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

export default function Hidder() {
  const navigate = useNavigate();

  return (
    <HidderContainer>
      <Card>
        <Title>PRÓXIMAMENTE DISPONIBLE</Title>
        <CardTitle>EN DESARROLLO 🚀</CardTitle>
      </Card>
      <StatGrid>
        <Card onClick={() => navigate("/edit-zones-and-tables")}>
          Editar zonas y mesas
        </Card>
        <Card onClick={() => navigate("/edit-products")}>Editar carta</Card>
        <Card onClick={() => navigate("/pos")}>POS</Card>
      </StatGrid>
    </HidderContainer>
  );
}

const HidderContainer = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  gap: 20px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  overflow: hidden;
  z-index: 100;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h1`
  font-size: 2rem;
  color: #2c3e50;
  margin-bottom: 1rem;
`;

const Card = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0px 0px 2px 0px rgba(145, 158, 171, 0.2),
    0px 12px 24px -4px rgba(145, 158, 171, 0.12);
`;

const CardTitle = styled.h2`
  font-size: 1.2rem;
  color: #34495e;
  margin-bottom: 1rem;
`;

const StatGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-bottom: 2rem;
`;
