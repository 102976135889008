import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useTranslation } from 'react-i18next';

const spain = "/assets/flags/es-ES.png";
const england = "/assets/flags/en-US.png";
const france = "/assets/flags/fr-FR.png";
const germany = "/assets/flags/de-DE.png";
const italy = "/assets/flags/it-IT.png";
const portugal = "/assets/flags/pt.png";
const russia = "/assets/flags/ru rus.png";
const china = "/assets/flags/zh-CN.png";

const countries = [
  { value: "es-ES", label: "ESPAÑOL", image: spain },
  { value: "en-US", label: "INGLES", image: england },
  { value: "fr-FR", label: "FRANCES", image: france },
  { value: "de-DE", label: "ALEMAN", image: germany },
  { value: "it-IT", label: "ITALIANO", image: italy },
  { value: "pt", label: "PORTUGUES", image: portugal },
  { value: "ru rus", label: "RUSO", image: russia },
  { value: "zh-CN", label: "CHINO", image: china },
];

const customStyles = {
  dropdownIndicator: (base) => ({
    ...base,
    padding: 0,
    height: "24px",
    borderTopRightRadius: "100%",
    borderBottomRightRadius: "100%",
    zIndex: "1",
    width: "70%",
    backgroundColor: '#374957',
    color: 'white',
    alignSelf: 'baseline',
    alignItems: 'center',
  }),
  indicatorSeparator: () => ({ display: "none" }),
  control: () => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  }),
  valueContainer: () => ({
    padding: 0,
    display: "flex",
    marginRight: "-2px",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    width: "30px",
    padding: 0,
    margin: 0,
  }),
  menu: ({ width, ...css }) => ({
    ...css,
    backgroundColor: "#FFFFFF",
    zIndex: "1",
    right: "0",
  }),
};

export default function SelectLanguage({reloadCategories, selectedLanguages}) {
    const [selectedLanguage, setSelectedLanguage] = useState(null);
  
    const { i18n } = useTranslation();
    const changeLanguage = (language) => {
      //i18n.changeLanguage(language);
    };
  
    function handleLanguageChange(selectedOption) {
      setSelectedLanguage(selectedOption);
      localStorage.setItem("i18nextLng", selectedOption.value);
      changeLanguage(selectedOption.value);
      reloadCategories();
    }
  
    useEffect(() => {
      const language = localStorage.getItem("i18nextLng") || 'es-ES';
      if (language) {
        setSelectedLanguage(
          countries.find((country) => country.value === language)
        );
        changeLanguage(language);
      }
    }, []);
  
    return (
      <>
        <Select
          value={selectedLanguage}
          options={countries.filter(c => selectedLanguages.includes(c.value))}
          styles={customStyles}
          isSearchable={false}
          onChange={handleLanguageChange}
          formatOptionLabel={({ image, label }, { context }) => {
            if (context === "value") {
              return (
                <img
                  src={image}
                  alt={label}
                  style={{
                    width: 35,
                    height: 24,
                    objectFit: "cover",
                    borderTopLeftRadius: "20px",
                    borderBottomLeftRadius: "20px",
                    zIndex: "5",
                    padding: 0,
                  }}
                />
              );
            } else if (context === "menu") {
              return (
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                  <p
                    style={{
                      fontWeight: "800",
                      fontSize: "10px",
                      lineHeight: "12.5px",
                      borderRadius: "20px",
                      zIndex: "5",
                      fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {label}
                  </p>
                  <img
                    src={image}
                    alt={label}
                    style={{
                      width: 35,
                      height: 23,
                      objectFit: "cover",
                      padding: 0,
                      borderRadius: "5px",
                      zIndex: "5",
                    }}
                  />
                </div>
              );
            }
          }}
        />
      </>
    );
}