// src/features/ticketSlice/ticketSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getTicketsApi,
  refundTicketsApi,
  cancelTicketApi,
  deleteTicketApi,
} from "../../services/ticket.service";

// Thunk para obtener los tickets
export const fetchTickets = createAsyncThunk(
  "tickets/fetchTickets",
  async (restaurantId, { rejectWithValue }) => {
    try {
      const response = await getTicketsApi([restaurantId]);
      if (response.data && Array.isArray(response.data.tickets)) {
        return response.data.tickets;
      } else if (Array.isArray(response.data)) {
        return response.data;
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error fetching tickets:", error);
      return rejectWithValue("Error fetching tickets. Please try again.");
    }
  }
);

// Thunk para reembolsar un ticket
export const refundTicket = createAsyncThunk(
  "tickets/refundTicket",
  async (ticketId, { rejectWithValue }) => {
    try {
      const response = await refundTicketsApi([ticketId]);
      if (response.status === 200) {
        return ticketId;
      } else {
        return rejectWithValue("Error refunding ticket.");
      }
    } catch (error) {
      console.error("Error refunding ticket:", error);
      return rejectWithValue("Error refunding ticket. Please try again.");
    }
  }
);

// Thunk para cancelar un ticket
export const cancelTicket = createAsyncThunk(
  "tickets/cancelTicket",
  async (ticketId, { rejectWithValue }) => {
    try {
      const response = await cancelTicketApi(ticketId);
      if (response.status === 200) {
        return ticketId;
      } else {
        return rejectWithValue("Error canceling ticket.");
      }
    } catch (error) {
      console.error("Error canceling ticket:", error);
      return rejectWithValue("Error canceling ticket. Please try again.");
    }
  }
);

// Thunk para eliminar un ticket
export const deleteTicket = createAsyncThunk(
  "tickets/deleteTicket",
  async (ticketId, { rejectWithValue }) => {
    try {
      const response = await deleteTicketApi(ticketId);
      if (response.status === 200) {
        return ticketId;
      } else {
        return rejectWithValue("Error deleting ticket.");
      }
    } catch (error) {
      console.error("Error deleting ticket:", error);
      return rejectWithValue("Error deleting ticket. Please try again.");
    }
  }
);

const ticketSlice = createSlice({
  name: "tickets",
  initialState: {
    tickets: [],
    loading: false,
    error: null,
  },
  reducers: {
    // Si necesitas acciones adicionales, agrégalas aquí
  },
  extraReducers: (builder) => {
    // Manejar fetchTickets
    builder
      .addCase(fetchTickets.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTickets.fulfilled, (state, action) => {
        state.loading = false;
        state.tickets = action.payload;
      })
      .addCase(fetchTickets.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Manejar refundTicket
      .addCase(refundTicket.fulfilled, (state, action) => {
        const ticketId = action.payload;
        state.tickets = state.tickets.map((ticket) =>
          ticket._id === ticketId
            ? { ...ticket, refunded: true, delivered: true }
            : ticket
        );
      })
      .addCase(refundTicket.rejected, (state, action) => {
        state.error = action.payload;
      })
      // Manejar cancelTicket
      .addCase(cancelTicket.fulfilled, (state, action) => {
        const ticketId = action.payload;
        state.tickets = state.tickets.map((ticket) =>
          ticket._id === ticketId ? { ...ticket, cancelled: true } : ticket
        );
      })
      .addCase(cancelTicket.rejected, (state, action) => {
        state.error = action.payload;
      })
      // Manejar deleteTicket
      .addCase(deleteTicket.fulfilled, (state, action) => {
        const ticketId = action.payload;
        state.tickets = state.tickets.filter(
          (ticket) => ticket._id !== ticketId
        );
      })
      .addCase(deleteTicket.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

export default ticketSlice.reducer;
