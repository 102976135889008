import { createSlice } from "@reduxjs/toolkit";

const clientSlice = createSlice({
  name: "client",
  initialState: {
    id: "",
  },
  reducers: {
    setClient: (state, action) => {
      state.id = action.payload;
    }
  },
});

export const {
  setClient,
} = clientSlice.actions;
export default clientSlice.reducer;
