import React, { useEffect, useState } from "react";
import SideVerticalStepper from "../components/register/components/sideVerticalStepper";
import Step1 from "../components/register/step1";
import Step2 from "../components/register/step2";
import Step3 from "../components/register/step3";

// TODO: If less than X pixels, change to horizontal stepper or don't show it at all

const Register = () => {
  
    const [step, setStep] = useState(1);

    const savedFamilies = JSON.parse(localStorage.getItem("families"));
    const preuser = JSON.parse(localStorage.getItem("preuser"));

    useEffect(() => {
        if (preuser) {
            setStep(3);
        } else if (savedFamilies) {
            setStep(2);
        }
    }, []);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: "0 5rem",
                alignItems: "center",
                minHeight: "80vh",
            }}
        >
            <SideVerticalStepper step={step} />
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    width: "100%",
                }}
            >
                {
                    step === 1
                        ? <Step1 />
                        : step === 2
                            ? <Step2 setStep={setStep} />
                            : <Step3 />
                }   
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "1rem",
                    }}
                >
                    {step === 3 && <button onClick={() => setStep(step - 1)}>Back</button>}
                    {step === 1 && <button onClick={() => setStep(step + 1)}>Next</button>}
                </div>       
            </div>
        </div>
    );
};

export default Register;
