import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { isAuthBoolean } from "../../helpers/auth";
import {
  getObjectData,
  storeObjectData,
  deleteData
} from "../../helpers/storage";

export const getToken = createAsyncThunk(
  "auth/getToken",
  async (_, thunkAPI) => {
    try {
      const isAuth = await isAuthBoolean();
      if (isAuth) {
        return isAuth;
      } else {
        return false;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getUser = createAsyncThunk(
    'auth/getUser',
    async (_, thunkAPI) => {
        try {
            const user = await getObjectData('user');
            if (user) {
                return user;
            } else {
                return false;
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const authSlice = createSlice({
  name: "auth",
  initialState: {},
  reducers: {
    logIn: (state, action) => {
      storeObjectData("user", action.payload);
      state.isAuth = true;
    },
    logOut: (state) => {
      deleteData("user");

      state.isAuth = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getToken.pending, (state, action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getToken.fulfilled, (state, action) => {
        state.isLoading = false;
        state.token = action.payload;
        state.isAuth = true;
      })
      .addCase(getToken.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.isAuth = false;
      });
  },
  
});

export const { logIn, logOut, logInSuperAdAsClient } = authSlice.actions;

export default authSlice.reducer;
