import React from "react";
import styled from "styled-components";
import { ArrowRight, Clock } from "lucide-react";

export default function IncomingQueue({
  incomingOrders,
  moveToProduction,
  moveCompanionToProduction,
}) {
  return (
    <IncomingColumn>
      <Title>Cola de Entrada</Title>
      <OrderList>
        {incomingOrders.map((order) => (
          <OrderItem key={order.id}>
            <ProductName>
              {order.name}
              <Button onClick={() => moveToProduction(order)}>Marcha</Button>
            </ProductName>
            {order.companion.map((comp, index) => (
              <CompanionInfo key={index}>
                <span>
                  {comp.title}: {comp.products[0].name}
                </span>
                {comp.inProduction ? (
                  <IconButton disabled>
                    <Clock size={16} />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => moveCompanionToProduction(order.id, index)}
                    aria-label="Mover companion a producción"
                  >
                    <ArrowRight size={16} />
                  </IconButton>
                )}
              </CompanionInfo>
            ))}
          </OrderItem>
        ))}
      </OrderList>
    </IncomingColumn>
  );
}

const IncomingColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 10px;
`;

const Title = styled.h2`
  color: #333;
  margin-bottom: -10px;
`;

const OrderList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 0;
  overflow-y: auto;
  gap: 10px;
`;

const OrderItem = styled.li`
  background-color: #ffffff;
  padding: 10px;
  border-radius: 5px;
`;

const ProductName = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CompanionInfo = styled.div`
  font-size: 0.9em;
  color: #666;
  margin-left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Button = styled.button`
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  cursor: pointer;
  border-radius: 3px;
`;

const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4caf50;

  &:hover {
    color: #45a049;
  }
`;
