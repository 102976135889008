import React from "react";
import styled from "styled-components";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import Hidder from "../components/hidder";

// Datos de ejemplo (reemplazar con datos reales de tu backend)
const salesData = [
  { name: "Lun", ventas: 4000 },
  { name: "Mar", ventas: 3000 },
  { name: "Mié", ventas: 2000 },
  { name: "Jue", ventas: 2780 },
  { name: "Vie", ventas: 1890 },
  { name: "Sáb", ventas: 2390 },
  { name: "Dom", ventas: 3490 },
];

const topDishesData = [
  { name: "Plato 1", value: 400 },
  { name: "Plato 2", value: 300 },
  { name: "Plato 3", value: 300 },
  { name: "Plato 4", value: 200 },
];

const customerData = [
  { name: "Ene", clientes: 400 },
  { name: "Feb", clientes: 600 },
  { name: "Mar", clientes: 800 },
  { name: "Abr", clientes: 1000 },
  { name: "May", clientes: 1200 },
  { name: "Jun", clientes: 1500 },
];

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

export default function AdminHome() {
  return (
    <>
      <Hidder />
      <DashboardContainer>
        <Title>Inicio</Title>

        <StatGrid>
          <StatCard>
            <StatValue>€15,230</StatValue>
            <StatLabel>Ingresos Totales (Este Mes)</StatLabel>
          </StatCard>
          <StatCard>
            <StatValue>1,230</StatValue>
            <StatLabel>Pedidos Totales (Este Mes)</StatLabel>
          </StatCard>
          <StatCard>
            <StatValue>€12.38</StatValue>
            <StatLabel>Valor Promedio del Pedido</StatLabel>
          </StatCard>
          <StatCard>
            <StatValue>32</StatValue>
            <StatLabel>Nuevos Clientes (Esta Semana)</StatLabel>
          </StatCard>
        </StatGrid>

        <GridContainer>
          <Card>
            <CardTitle>Ventas Semanales</CardTitle>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={salesData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="ventas" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </Card>

          <Card>
            <CardTitle>Platos Más Populares</CardTitle>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={topDishesData}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                  label={({ name, percent }) =>
                    `${name} ${(percent * 100).toFixed(0)}%`
                  }
                >
                  {topDishesData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </Card>

          <Card>
            <CardTitle>Crecimiento de Clientes</CardTitle>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={customerData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="clientes" stroke="#82ca9d" />
              </LineChart>
            </ResponsiveContainer>
          </Card>

          <Card>
            <CardTitle>Horas Pico de Pedidos</CardTitle>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart
                data={[
                  { hora: "12pm", pedidos: 20 },
                  { hora: "1pm", pedidos: 35 },
                  { hora: "2pm", pedidos: 40 },
                  { hora: "3pm", pedidos: 25 },
                  { hora: "4pm", pedidos: 15 },
                  { hora: "5pm", pedidos: 10 },
                  { hora: "6pm", pedidos: 30 },
                  { hora: "7pm", pedidos: 45 },
                  { hora: "8pm", pedidos: 50 },
                  { hora: "9pm", pedidos: 40 },
                  { hora: "10pm", pedidos: 20 },
                ]}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="hora" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="pedidos" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
          </Card>
        </GridContainer>
      </DashboardContainer>
    </>
  );
}

const DashboardContainer = styled.div`
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  font-family: "PlusJakartaSans", sans-serif;
`;

const Title = styled.h1`
  font-size: 2rem;
  color: #2c3e50;
  margin-bottom: 1rem;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
`;

const Card = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0px 0px 2px 0px rgba(145, 158, 171, 0.2),
    0px 12px 24px -4px rgba(145, 158, 171, 0.12);
`;

const CardTitle = styled.h2`
  font-size: 1.2rem;
  color: #34495e;
  margin-bottom: 1rem;
`;

const StatGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  margin-bottom: 2rem;
`;

const StatCard = styled.div`
  background-color: #f0f4f8;
  border-radius: 8px;
  padding: 1rem;
  text-align: center;
`;

const StatValue = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 0.5rem;
`;

const StatLabel = styled.p`
  font-size: 0.9rem;
  color: #7f8c8d;
`;
