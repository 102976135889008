import React, { useEffect, useState } from "react";
import SelectRestaurantInput from "../components/select-restaurant-input";
import {
  listZonesAPI,
  postZonesAndTablesAPI,
  deleteZoneAPI,
  deleteTableAPI,
} from "../services/listZones.service";
import { debounce } from "lodash";
import axios from "axios";

import { Card, CardContent } from "@mui/material";
import { Checkboxes } from "../components/ui/forms/checkboxes/common/checkboxParts";
import { Text, View, Button } from "tamagui";
import { ArrowUpLeft, Check, Download, Trash2 } from "@tamagui/lucide-icons";
import TamaguiSelect from "../components/tamagui-select";

import { Table, AddTable } from "../components/editZonesAndTable/table"; // Importa el nuevo componente

const styles = {
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100vh",
    paddingTop: "40px",
    paddingBottom: "200px",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "93%",
    marginBottom: "20px",
  },
  headerLeft: {
    display: "flex",
    flexDirection: "column",
  },
  zonesAndTablesContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "93%",
  },
  zoneHeader: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "20px",
    justifyContent: "space-between",
    fontSize: "23px",
  },
  zoneHeaderDescription: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  zoneContainer: {
    marginBottom: "20px",
    width: "100%",
    overflow: "visible",
    height: "100%",
    borderRadius: "20px",
    boxShadow: "0px 12px 24px 0px #919EAB33, 0px 0px 2px 0px #919EAB33",
  },
  tablesContainer: {
    display: "grid",
    width: "100%",
  },
  table: {
    margin: "1px",
    padding: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  addTableButton: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  text1: {
    fontFamily: "PlusJakartaSans",
    fontSize: "14px",
    letter: "-2%",
  },
  grayColor: {
    color: "#7C8FAC",
  },
  usage: {
    fontSize: "16px",
  },
  headerDescriptionContainer: {
    display: "flex",
    gap: "100px",
  },
  textBold: {
    fontFamily: "PlusJakartaSansSemibold",
    fontSize: "24px",
  },
  selectedTablesContainer: {
    width: "88%",
    position: "fixed",
    bottom: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    boxShadow: "0px 12px 24px 0px #919EAB33, 0px 0px 2px 0px #919EAB33",
    background: "#ffffff",
    borderRadius: "20px",
  },
  selectedTablesSpacing: {
    padding: "20px 10px 10px 10px",
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
  selectedTDescriptionContainer: {
    display: "flex",
    gap: "10px",
  },
  selectedTDescription: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
  selectedTButtonsContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(3, minmax(370px, 1fr))",
    gap: "10px",
    width: "100%",
  },
  selectedTCard: {
    borderRadius: "20px",
    padding: "10px",
    alignItems: "center",
    border: "1px solid #000000",
    color: "#000000",
    minHeight: "73px",
    background: "white",
  },
  selectedTCardDelete: {
    background: "#FF7171",
    color: "white",
    fontSize: "24px",
    "&:hover": {
      color: "black",
    },
  },
  button: {
    borderRadius: "25px",
    border: "1px solid #000000",
    color: "#7C8FAC",
    height: "fit-content",
    padding: "12px",
  },
};

const EditZonesAndTables = () => {
  const [selectedRestaurant, setSelectedRestaurant] = useState(null);
  const [selectedTables, setSelectedTables] = useState([]);
  const [zones, setZones] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [editedName, setEditedName] = useState("");
  const [availableTables, setAvailableTables] = useState({});

  useEffect(() => {
    if (selectedRestaurant) {
      getZonesAndTables(selectedRestaurant);
    }
  }, [selectedRestaurant]);

  useEffect(() => {
    if (selectedRestaurant && zones.length > 0) {
      debouncedSaveZones();
    }
  }, [zones]);

  //--------------------------------//
  //          LLAMADAS API          //
  //--------------------------------//

  const debouncedSaveZones = debounce(() => {
    postZonesAndTablesAPI(selectedRestaurant._id, zones)
      .then((response) => {
        if (response.status === 200) {
          console.log("Cambios guardados exitosamente");
        } else {
          console.error("Error al guardar cambios");
        }
      })
      .catch((error) => {
        console.error("Error al hacer la petición a la API", error);
      });
  }, 1000);

  const getZonesAndTables = (selectedRestaurant) => {
    listZonesAPI(selectedRestaurant._id).then((response) => {
      setZones(response.data.zones);
    });
  };

  const restartAvailableTables = () => {
    const newAvailableTables = { ...availableTables };
    for (const key in newAvailableTables) {
      newAvailableTables[key] = false;
    }
    setAvailableTables(newAvailableTables);
  };

  const postZonesAndTables = () => {
    postZonesAndTablesAPI(selectedRestaurant._id, zones).then((response) => {
      if (response.status === 200) {
        getZonesAndTables(selectedRestaurant);
      }
    });
  };

  //--------------------------------//
  //         EDITAR LA ZONA         //
  //--------------------------------//

  const handleZoneNameChange = (e, index) => {
    setZones(
      zones.map((zone, i) => {
        if (i === index) {
          return { ...zone, name: e.target.value };
        }
        return zone;
      })
    );
    setEditIndex(null);
    setEditedName("");
  };

  const handleNameClick = (index) => {
    setEditIndex(index);
    setEditedName(zones[index].name);
  };

  const handleNameBlur = (index) => {
    handleZoneNameChange({ target: { value: editedName } }, index);
  };

  const handleAddZone = () => {
    setZones([...zones, { name: "DEFINE UN NOMBRE", tables: [] }]);
  };

  const deleteZoneHandler = (zoneId, zoneIndex) => {
    const userConfirmed = window.confirm(
      "¿Seguro que desea borrar esta zona y TODAS sus mesas?"
    );

    if (userConfirmed) {
      if (zoneId) {
        deleteZoneAPI(zoneId, selectedRestaurant._id).then((response) => {
          if (response.status === 200) {
            getZonesAndTables(selectedRestaurant);
          }
        });
      } else {
        const newZones = zones.filter(
          (zone) => zones.indexOf(zone) !== zoneIndex
        );
        setZones(newZones);
      }
    }
  };

  //--------------------------------//
  //         EDITAR LA MESA         //
  //--------------------------------//

  const handleTableAdded = (newTable) => {
    setZones((prevZones) =>
      prevZones.map((zone) => {
        if (zone._id === newTable.zoneId) {
          return {
            ...zone,
            tables: [...zone.tables, newTable],
          };
        }
        return zone;
      })
    );
  };  

  const handleTableDeleted = (tableId, zoneId) => {
    setZones((prevZones) =>
      prevZones.map((zone) => {
        if (zone._id === zoneId) {
          return {
            ...zone,
            tables: zone.tables.filter((table) => table._id !== tableId),
          };
        }
        return zone;
      })
    );
  };

  const handleDeleteSelectedTables = () => {
    const userConfirmed = window.confirm(
      "¿Seguro que desea borrar TODAS las mesas seleccionadas?"
    );

    if (userConfirmed) {
      selectedTables.forEach((table) => {
        const { _id: tableId, zoneId } = table;

        if (tableId) {
          deleteTableAPI(tableId, zoneId, selectedRestaurant._id).then(
            (response) => {
              if (response.status === 200) {
                getZonesAndTables(selectedRestaurant);
              }
            }
          );
        }
      });

      setSelectedTables([]);
      restartAvailableTables();
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log("URL copied to clipboard:", text);
      })
      .catch((error) => {
        console.error("Failed to copy URL to clipboard", error);
      });
  };

  const handleDownloadQRCode = async (text, zoneName, tableName) => {
    const options = {
      method: "GET",
      url: "https://qrcode-monkey.p.rapidapi.com/qr/custom",
      params: {
        data: text,
        size: "1200",
        file: "svg",
        download: "false",
        config:
          '{"bgColor": "transparent", "bodyColor": "#000000", "body":"circle", "eye": "frame13", "eyeBall": "ball15", "erf1": ["fv"], "erf2": ["fv","fh"], "brf1": ["fv"], "brf2": ["fv","fh"], "logo":"https://ibero.app/wp-content/drive/ICONO.png", "logoMode": "clean"}',
      },
      headers: {
        "X-RapidAPI-Key": "99f9d8750emsha6eb5312e0f2bf8p19be00jsnac8e893f17d1",
        "X-RapidAPI-Host": "qrcode-monkey.p.rapidapi.com",
      },
    };

    try {
      const response = await axios.request(options);

      let format = "svg";

      if (response.headers["content-type"] === "image/png") {
        format = "png";
      }

      const link = document.createElement("a");
      if (format === "svg") {
        link.href = `data:image/svg+xml,${encodeURIComponent(response.data)}`;
      } else {
        link.href = URL.createObjectURL(new Blob([response.data]));
      }
      link.download = `${selectedRestaurant.companyName}_${zoneName}_${tableName}.${format}`;

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
    } catch (error) {
      console.error(error);
    }
  };

  const onSelectedTable = () => {
    const selected = [...selectedTables];
    selected.push({ id: 1 });
    setSelectedTables(selected);
  };

  const toggleValues = debounce((values) => {
    setAvailableTables((prev) => ({ ...prev, ...values }));
    const allTables = zones.map((z) => z.tables).flat();
    setSelectedTables(
      allTables.filter((t) => {
        if (values[t._id] && values[t._id] == true) {
          return true;
        }
        return false;
      })
    );
  }, 10);

  return (
    <div style={styles.main}>
      <div style={styles.header}>
        <div style={styles.headerLeft}>
          <span
            style={{
              ...styles.text1,
              ...styles.grayColor,
              marginBottom: "10px",
            }}
          >
            Gestión de zonas y mesas
          </span>

          {selectedRestaurant ? (
            <div style={styles.headerDescriptionContainer}>
              <span style={styles.textBold}>{zones.length} Zonas</span>
              <Button
                style={{ ...styles.button, background: "transparent" }}
                onClick={() => handleAddZone()}
              >
                Añadir Zona
              </Button>
            </div>
          ) : null}
        </div>
        <SelectRestaurantInput setSelectedRestaurant={setSelectedRestaurant} />
      </div>
      {selectedRestaurant ? (
        <div style={styles.zonesAndTablesContainer}>
          {zones.map((zone, zindex) => (
            <Card key={zone._id} style={styles.zoneContainer}>
              <CardContent>
                <div style={styles.zoneHeader}>
                  <div style={styles.zoneHeaderDescription}>
                    {editIndex === zindex ? (
                      <input
                        type="text"
                        value={editedName}
                        onChange={(e) => setEditedName(e.target.value)}
                        onBlur={() => handleNameBlur(zindex)}
                        autoFocus
                      />
                    ) : (
                      <span
                        style={{ ...styles.text1, ...styles.grayColor }}
                        onClick={() => handleNameClick(zindex)}
                      >
                        {zone.name}
                      </span>
                    )}
                    <span style={styles.textBold}>
                      {zone.tables.length} Mesas
                    </span>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Button
                        style={{
                          background: "#E6FFFA",
                          padding: "8px",
                          borderRadius: "50%",
                          height: "100%",
                        }}
                        icon={<ArrowUpLeft color={"#13DEB9"} size="$1" />}
                      ></Button>
                      <span
                        style={{
                          ...styles.grayColor,
                          ...styles.text1,
                          ...styles.usage,
                        }}
                      >
                        18% uso
                      </span>
                    </div>
                  </div>
                  <Button
                    icon={<Trash2 size="$1" />}
                    onClick={() => deleteZoneHandler(zone._id, zindex)}
                    style={{
                      color: "black",
                      padding: "6px",
                      border: "1px solid black",
                      borderRadius: "50%",
                      background: "transparent",
                      height: "fit-content",
                    }}
                  />
                </div>
                <div style={styles.tablesContainer}>
                  <Checkboxes
                    minWidth="100%"
                    values={availableTables}
                    onValuesChange={(values) => toggleValues(values)}
                    gap="$3"
                    $sm={{
                      paddingVertical: "$6",
                    }}
                  >
                    <Checkboxes.FocusGroup
                      style={{
                        display: "grid",
                        gridTemplateColumns:
                          "repeat(auto-fill, minmax(300px, 1fr))",
                        gap: "10px",
                      }}
                      width="100%"
                      flexDirection="row"
                      gap="$3"
                      flexWrap="wrap"
                    >
                      {zone.tables.map((table, tindex) => (
                        <Table
                          key={table._id}
                          table={table}
                          zoneId={zone._id}
                          businessId={selectedRestaurant._id}
                          onTableDeleted={handleTableDeleted}
                        />
                      ))}
                      <AddTable
                        zone={zone}
                        businessId={selectedRestaurant._id}
                        onTableAdded={handleTableAdded}
                      />
                    </Checkboxes.FocusGroup>
                  </Checkboxes>
                </div>
              </CardContent>
            </Card>
          ))}
        </div>
      ) : null}
      {selectedTables && selectedTables.length > 0 ? (
        <div style={styles.selectedTablesContainer}>
          <div style={styles.selectedTablesSpacing}>
            <div style={styles.selectedTDescriptionContainer}>
              <div style={styles.selectedTDescription}>
                <span style={{ ...styles.text1, ...styles.grayColor }}>
                  Seleccionadas
                </span>
                <span style={styles.textBold}>
                  {selectedTables.length} Mesas
                </span>
              </div>
              <Button
                style={{
                  ...styles.button,
                  background: "transparent",
                  marginTop: "10px",
                }}
                onClick={() => {
                  setSelectedTables([]);
                  restartAvailableTables();
                }}
              >
                Deseleccionar
              </Button>
            </div>
            <div style={styles.selectedTButtonsContainer}>
              <Button style={styles.selectedTCard}>
                <View flexDirection="column" justifyContent="center">
                  <span style={{ ...styles.text1, ...styles.grayColor }}>
                    Comprar QRs
                  </span>
                  <span style={styles.textBold}>
                    {selectedTables.length * 6}€
                  </span>
                </View>
              </Button>
              <Button
                icon={<Download size="$1" />}
                style={styles.selectedTCard}
              >
                <span style={styles.textBold}>Descargar QRs</span>
              </Button>
              <Button
                onClick={() => handleDeleteSelectedTables()}
                style={{
                  ...styles.selectedTCard,
                  ...styles.selectedTCardDelete,
                }}
              >
                <span>Borrar mesas</span>
              </Button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default EditZonesAndTables;
