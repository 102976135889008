import React, { useState, useEffect, useRef, useCallback } from "react";
import { getSelectedBusinessFamilies } from '../services/business.service';
import { getZoneTables } from '../services/zones.service';
import { posAddTicket } from '../services/ticket.service';
import { getBusinessCoupon } from "../services/coupon.service";
import { Input, Select, TextArea } from 'tamagui';
import SelectRestaurantInput from "../components/select-restaurant-input";
import PosCompanionModal from '../components/pos/dialogs/pos-companion.modal';
import PosTicket from "../components/pos/components/pos_ticket";
import FoodItem from "../components/pos/components/pos_product";
import styled, { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

const POS = () => {
    const [selectedRestaurant, setSelectedRestaurant] = useState(null);
    const [selectedBusinessFamilies, setSelectedBusinessFamilies] = useState(() => {
        const savedFamilies = localStorage.getItem('selectedBusinessFamilies');
        return savedFamilies ? JSON.parse(savedFamilies) : null;
    });
    const [selectedBusinessOldMenus, setSelectedBusinessOldMenus] = useState(() => {
        const savedMenus = localStorage.getItem('selectedBusinessOldMenus');
        return savedMenus ? JSON.parse(savedMenus) : null;
    });
    const [selectedBusinessNewMenusCount, setSelectedBusinessNewMenusCount] = useState(0);
    const [selectedBusinessZones, setSelectedBusinessZones] = useState(() => {
        const savedZones = localStorage.getItem('selectedBusinessZones');
        return savedZones ? JSON.parse(savedZones) : [];
    });
    const [selectedBusinessZone, setSelectedBusinessZone] = useState(null);
    const [selectedBusinessTables, setSelectedBusinessTables] = useState(null);
    const [selectedTable, setSelectedTable] = useState(null);

    const [selectedFood, setSelectedFood] = useState(null);
    const [selectedFoodHasCompanion, setSelectedFoodHasCompanion] = useState(false);

    const [notes, setNotes] = useState("");
    const [name, setName] = useState("");
    const [coupon, setCoupon] = useState("");
    const [couponDiscount, setCouponDiscount] = useState(null);
    const [cartTotal, setCartTotal] = useState(0);

    const [ticket, setTicket] = useState([]);

    const timeoutRef = useRef(null);

    let lastSelectedRestaurant = null;
    let menuColor = "hsl(0, 60%, 70%)";

    const familyRefs = useRef([]);
    const menuRefs = useRef([]);
    const menusScrollRef = useRef(null);

    const familiesScrollRef = useRef(null);


    const foodRefs = useRef([]);

    useEffect(() => {
        if (selectedRestaurant && selectedRestaurant !== lastSelectedRestaurant) {
            menuColor = "hsl(0, 60%, 70%)";
            lastSelectedRestaurant = selectedRestaurant;
            const cachedFamilies = localStorage.getItem(`selectedBusinessFamilies_${selectedRestaurant._id}`);
            const cachedMenus = localStorage.getItem(`selectedBusinessOldMenus_${selectedRestaurant._id}`);
            const cachedZones = localStorage.getItem(`selectedBusinessZones_${selectedRestaurant._id}`);
            if (cachedFamilies) {
                setSelectedBusinessFamilies(JSON.parse(cachedFamilies));
                let selectedBusinessNewMenusCountTemp = 0;
                JSON.parse(cachedFamilies).forEach((family) => {
                    selectedBusinessNewMenusCountTemp += family.menus.length;
                });
                setSelectedBusinessNewMenusCount(selectedBusinessNewMenusCountTemp);
            }
            if (cachedMenus) {
                setSelectedBusinessOldMenus(JSON.parse(cachedMenus));
            }
            if (cachedZones) {
                setSelectedBusinessZones(JSON.parse(cachedZones));
            }
            getSelectedBusinessFamilies(selectedRestaurant._id).then((response) => {
                let selectedBusinessNewMenusCountTemp = 0;
                response.families.forEach((family) => {
                    family.menus.forEach((menu) => {
                        menu.color = menuColor;
                        selectedBusinessNewMenusCountTemp += 1;
                    });
                    family.color = menuColor;
                    menuColor = generateNextColor(menuColor);
                });
                setSelectedBusinessNewMenusCount(selectedBusinessNewMenusCountTemp);
                setSelectedBusinessFamilies(response.families);
                response.menus.forEach((menu) => {
                    menu.color = menuColor;
                    menuColor = generateNextColor(menuColor);
                });
                setSelectedBusinessOldMenus(response.menus); 
                setSelectedBusinessZones(response.zones);
                const fams = response.families.map((family) => {
                    const { image_url, ...restFamily } = family;
                    const menus = restFamily.menus.map((menu) => {
                        const { image_url, ...restMenu } = menu;
                        const foods = restMenu.foods.map((food) => {
                            const { image_url, ...restFood } = food;
                            return restFood;
                        });
                        return { ...restMenu, foods };
                    });
                    return { ...restFamily, menus };
                });
                const menus = response.menus.map((menu) => {
                    const { image_url, ...restMenu } = menu;
                    const foods = restMenu.foods.map((food) => {
                        const { image_url, ...restFood } = food;
                        return restFood;
                    });
                    return { ...restMenu, foods };
                });
                const zones = response.zones.map((zone) => {
                    const { image_url, ...rest } = zone;
                    return rest;
                });
                const totalDataSize = new Blob([JSON.stringify(fams), JSON.stringify(menus), JSON.stringify(zones)]).size;
                if (checkStorageQuota(totalDataSize)) {
                    localStorage.setItem(`selectedBusinessFamilies_${selectedRestaurant._id}`, JSON.stringify(fams));
                    localStorage.setItem(`selectedBusinessOldMenus_${selectedRestaurant._id}`, JSON.stringify(menus));
                    localStorage.setItem(`selectedBusinessZones_${selectedRestaurant._id}`, JSON.stringify(zones));
                }
            }).catch((error) => {
                console.error("Error fetching business families:", error);
            });
        }
    }, [selectedRestaurant]);

    function checkStorageQuota(dataSize) {
        const storageUsed = new Blob(Object.values(localStorage)).size;
        const quotaLimit = 5 * 1024 * 1024;
        return (storageUsed + dataSize) <= quotaLimit;
    }

    useEffect(() => {
        if (selectedBusinessZone) {
            getZoneTables(selectedBusinessZone).then((response) => {
                setSelectedBusinessTables(response.zones);
            }).catch((error) => {
                console.error("Error fetching zone tables:", error);
            });
        }
    }, [selectedBusinessZone]);
    
    const generateNextColor = (prevColor) => {
        const hsl = prevColor.match(/hsl\((\d+),\s*(\d+)%,\s*(\d+)%\)/);
        let hue = parseInt(hsl[1]);
        let saturation = parseInt(hsl[2]);
        let lightness = parseInt(hsl[3]);
        hue = (hue + 15) % 360;
        return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    };

    const scrollToMenu = (familyIndex) => {
        if (familiesScrollRef.current && menusScrollRef.current) {
            const familyElement = familiesScrollRef.current.children[familyIndex];
            if (familyElement) {
                const offset = 80;
                
                // Función para desplazar la familia
                const scrollFamily = new Promise((resolve) => {
                    familiesScrollRef.current.scrollTo({
                        left: familyElement.offsetLeft - offset,
                        behavior: 'smooth'
                    });
                    resolve();
                });
    
                // Encuentra la primera categoría de esta familia
                const family = selectedBusinessFamilies[familyIndex];
                const firstMenuId = family.menus[0]?._id;
                
                if (firstMenuId) {
                    const menuElement = [...menusScrollRef.current.children].find(el => el.dataset.menuId === firstMenuId);
                    
                    if (menuElement) {
                        // Función para desplazar la categoría
                        const scrollMenu = new Promise((resolve) => {
                            menusScrollRef.current.scrollTo({
                                left: menuElement.offsetLeft - offset,
                                behavior: 'smooth'
                            });
                            resolve();
                        });
    
                        // Ejecuta ambos desplazamientos en paralelo
                        Promise.all([scrollFamily, scrollMenu]).then(() => {
                            // Opcional: puedes realizar alguna acción adicional aquí si es necesario
                        });
                    }
                }
            }
        }
    };
       

    const scrollToFood = (menuIndex) => {
        if (foodRefs.current[menuIndex]) {
            // Realiza el desplazamiento inicial
            foodRefs.current[menuIndex].scrollIntoView({ behavior: "smooth", block: "start" });
    
            setTimeout(() => {
                const offset = 180;
                const element = foodRefs.current[menuIndex];
                const container = document.querySelector('#foods-container');
    
                if (container) {
                    const elementTop = element.getBoundingClientRect().top + container.scrollTop;
                    
                    // Ajusta el scrollTop del contenedor (FoodsContainer) en lugar del window
                    container.scrollTo({
                        top: elementTop - offset,
                        behavior: 'smooth'
                    });
                }
            }, 0);
        }
    };
    

    const selectFood = (food) => {
        if (food.companion.length > 0) {
            setSelectedFood(food);
            setSelectedFoodHasCompanion(true);
        } else {
            setSelectedFood(null);
            setSelectedFoodHasCompanion(false);
            setTicket((prev) => {
                const combinedItems = [...prev, food];
                const sortedItems = combinedItems.sort((a, b) => {
                    if (a.companion.length < b.companion.length) return -1;
                    if (a.companion.length > b.companion.length) return 1;
                    if (a._id < b._id) return -1;
                    if (a._id > b._id) return 1;
                    return 0;
                });
                return sortedItems;
            });
        }
    };

    const ticketValue = () => {
        let total = 0;
        ticket.forEach(item => {
            total += +item.price;
            if (item.companion && item.companion.length > 0) {
                item.companion.forEach(companionItem => {
                    total += companionItem.foods && companionItem.foods[0] && companionItem.foods[0].price_extra ? 
                        +companionItem.foods[0].price_extra 
                        : companionItem.categories && companionItem.categories[0] && companionItem.categories[0].foods[0].price_extra ? 
                            companionItem.categories[0].foods[0].price_extra 
                            : 0;
                });
            }
        });
        return Math.floor(total * 100) / 100; 
    };

    const cleanTicket = (ticket) => {
        return ticket.map(item => {
            const { image_url, ...cleanItem } = item;    
            if (cleanItem.companion && cleanItem.companion.length > 0) {
                cleanItem.companion = cleanItem.companion.map(companionItem => {
                    if (companionItem.foods && companionItem.foods.length > 0) {
                        companionItem.foods = companionItem.foods.map(foodItem => {
                            const { image_url, ...cleanFoodItem } = foodItem;
                            return cleanFoodItem;
                        });
                    }
                    if (companionItem.categories && companionItem.categories.length > 0) {
                        companionItem.categories = companionItem.categories.map(categoryItem => {
                            if (categoryItem.foods && categoryItem.foods.length > 0) {
                                categoryItem.foods = categoryItem.foods.map(foodItem => {
                                    const { image_url, ...cleanFoodItem } = foodItem;
                                    return cleanFoodItem;
                                });
                            }
                            return categoryItem;
                        });
                    }
                    return companionItem;
                });
            }
            return cleanItem;
        });
    };

    const setCouponHandler = (code) => {
        setCoupon(code);
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            checkCoupon(code);
        }, 1000);
    };

    const checkCoupon = (code) => {
        getBusinessCoupon(selectedRestaurant._id, code).then((response) => {
            console.log("Coupon response:", response);
            setCouponDiscount(response.coupon);
        }).catch((error) => {
            console.error("Error fetching coupon:", error);
        });
    };

    useEffect(() => {
        if (couponDiscount) {
            if (couponDiscount.min_apply_price <= ticketValue()) {
                if (couponDiscount.has_percentage) {
                  if (couponDiscount.max_amount) {
                    if (couponDiscount.max_amount < ticketValue() * (couponDiscount.percentage / 100)) {
                      setCartTotal((ticketValue() - couponDiscount.max_amount).toFixed(2));
                    } else {
                        setCartTotal(
                        (ticketValue() * ((100 - couponDiscount.percentage) / 100)).toFixed(2)
                      );
                    }
                  } else {
                    setCartTotal(
                      (ticketValue() * ((100 - couponDiscount.percentage) / 100)).toFixed(2)
                    );
                  }
                } else if (coupon.has_amount) {
                    setCartTotal((ticketValue() - couponDiscount.amount).toFixed(2));
                }
              } else {
                setCartTotal(ticketValue().toFixed(2));
              }
        } else {
            setCartTotal(ticketValue());
        }
    }, [ticket, couponDiscount]);

    const addTicket = () => {
        const cleanedTicket = cleanTicket(ticket);
        posAddTicket(selectedRestaurant._id, selectedBusinessZone, selectedTable, notes, name, couponDiscount, cleanedTicket);
        setNotes("");
        setTicket([]);
    };

    const [modalVisible, setModalVisible] = useState(false);
    const [transactionResult, setTransactionResult] = useState(null);
    const [resultText, setResultText] = useState('');
    const [tip, setTip] = useState('');
    const [timer, setTimer] = useState(null);

    const handleDeepLink = useCallback(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const status = queryParams.get('status');

        if (status) {
        setModalVisible(true);

        setTransactionResult({
            status: status,
            message: status === 'success' ? 'Pago correcto' : 'Error en el pago',
        });
        }
    }, []);

    useEffect(() => {
        handleDeepLink();

        const handlePopState = () => {
        handleDeepLink();
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
        window.removeEventListener('popstate', handlePopState);
        };
    }, [handleDeepLink]);

    const payTicket = () => {
        clearTimeout(timer);

        const amount = Math.round(parseFloat(resultText) * 100);
        const roundedTip = tip ? Math.round(parseFloat(tip) * 100) : 0;
        const isvAmount = Math.round(parseFloat(amount) * 0.4);

        const uri =
        `vivapayclient://pay/v1` +
        `?appId=com.ibero.app.iberowaiter` +
        `&action=sale` +
        `&amount=${amount}` +
        `&tipAmount=${roundedTip}` +
        `&show_receipt=false` +
        `&show_transaction_result=false` +
        `&show_rating=false` +
        `&ISV_amount=${isvAmount}` +
        `&ISV_clientId=${process.env.ISV_CLIENT_ID}` +
        `&ISV_clientSecret=${process.env.ISV_CLIENT_SECRET}` +
        `&paymentMethod=CardPresent` +
        `&callback=${encodeURIComponent(window.location.href)}`;

        window.location.href = uri;
    };

    return (
        <>
            <GlobalStyle />
            <MainContainer>
                <ContainerLeft>
                    <FamiliesHeader>
                        <FamiliesHorizontalScroll ref={familiesScrollRef}>
                            {selectedBusinessFamilies && selectedBusinessFamilies.map((family, familyIndex) => (
                                <div
                                    key={family._id}
                                    style={{ ...styles.familyCard, background: `linear-gradient(to bottom, #F9F9F9 75%, ${family.color} 25%)` }}
                                    onClick={() => scrollToMenu(familyIndex)}
                                >
                                    <span style={styles.familyCardTitle}>{family.name}</span>
                                </div>
                            ))}
                        </FamiliesHorizontalScroll>
                        <MenusHorizontalScroll ref={menusScrollRef}>
                            {selectedBusinessFamilies && selectedBusinessFamilies.map((family) =>
                                family.menus.map((menu) => (
                                    <div
                                        key={menu._id}
                                        style={{ ...styles.menuCard, background: menu.color }}
                                        data-menu-id={menu._id}
                                    >
                                        <span style={styles.menuCardTitle}>{menu.name}</span>
                                    </div>
                                ))
                            )}
                            {selectedBusinessOldMenus && selectedBusinessOldMenus.map((menu, menuIndex) => (
                                <div
                                    key={menu._id}
                                    style={{ ...styles.menuCard, background: menu.color }}
                                    onClick={() => scrollToFood(menuIndex + selectedBusinessNewMenusCount)}
                                >
                                    <span style={styles.menuCardTitle}>{menu.name}</span>
                                </div>
                            ))}
                        </MenusHorizontalScroll>
                    </FamiliesHeader>
                    <FoodsContainer id="foods-container"> 
                        {selectedBusinessFamilies && selectedBusinessFamilies.map((family, menuIndex) => 
                            family.menus.map((menu) => (
                                <div key={menu._id} style={styles.vertical} ref={el => foodRefs.current[menuIndex] = el}>
                                    <MenuTitle key={menu.id} bgColor={menu.color}>
                                        <span>{menu.name.toUpperCase()}</span>
                                    </MenuTitle>
                                    <FoodWrapper>
                                        {menu.foods.map((food) => (
                                            <FoodItem 
                                                key={food._id} 
                                                food={food} 
                                                selectFood={selectFood} 
                                            />
                                        ))}
                                    </FoodWrapper>
                                </div>
                            ))
                        )}
                        {selectedBusinessOldMenus && selectedBusinessOldMenus.map((menu, menuIndex) => (
                            <div key={menu._id} style={styles.vertical} ref={el => foodRefs.current[menuIndex + selectedBusinessNewMenusCount] = el}>
                                <MenuTitle key={menu.id} bgColor={menu.color}>
                                    <span>{menu.name.toUpperCase()}</span>
                                </MenuTitle>
                                <FoodWrapper>
                                    {menu.foods.map((food) => (
                                        <FoodItem 
                                            key={food._id} 
                                            food={food} 
                                            selectFood={selectFood} 
                                        />
                                    ))}
                                </FoodWrapper>
                            </div>
                        ))}
                    </FoodsContainer>
                </ContainerLeft>
                <ContainerRight>
                    <SelectRestaurantInput setSelectedRestaurant={setSelectedRestaurant} />
                    {selectedRestaurant && (
                        <div style={styles.containerRightVertical}>
                            <div style={styles.containerRightVerticalSelector}>
                                <Select onValueChange={(value) => {
                                    setSelectedBusinessZone(value);
                                    setSelectedTable(null);
                                    }}>
                                    <Select.Trigger style={styles.zoneSelector}>
                                        <Select.Value placeholder="Zona" />
                                    </Select.Trigger>
                                    <Select.Content>
                                        <Select.ScrollUpButton />
                                        <Select.Viewport>
                                            <Select.Group>
                                                {selectedBusinessZones.map((zone) => (
                                                    <Select.Item key={zone._id} value={zone._id}>
                                                        <Select.ItemText>{zone.name}</Select.ItemText>
                                                    </Select.Item>
                                                ))}
                                            </Select.Group>
                                        </Select.Viewport>
                                        <Select.ScrollDownButton />
                                    </Select.Content>
                                </Select>
                                <div
                                    style={{
                                        ...styles.noTableCard,
                                        border: !selectedTable ? "2px solid #BBC5AA" : "2px solid transparent"
                                    }}
                                    onClick={() => setSelectedTable(null)}
                                >
                                    <span>Sin mesa</span>
                                </div>
                            </div>
                            <div style={styles.tablesContainer}>
                                {selectedBusinessTables && selectedBusinessTables.map((table) => (
                                    <div 
                                        key={table._id} 
                                        style={{
                                            ...styles.tableCard,
                                            border: selectedTable === table._id ? "2px solid #BBC5AA" : "2px solid transparent"
                                        }}
                                        onClick={() => setSelectedTable(table._id)}
                                    >
                                        <span>{table.name}</span>
                                    </div>
                                ))}
                            </div>
                            { selectedBusinessZone && selectedTable && (
                                <div style={styles.tableAndZoneTitle}>
                                    <span>MESA {selectedBusinessTables.find(table => selectedTable === table._id).name} - {selectedBusinessZones.find(zone => selectedBusinessZone === zone._id).name}</span>
                                </div>
                            )}       
                            <div style={styles.inputsContainer}>
                                <TextArea 
                                    placeholder="Notas..." 
                                    style={styles.notesTextArea} 
                                    value={notes}
                                    onChange={event => {

                                        setNotes(event.target.value)
                                    }} 
                                />
                                <div style={styles.inputContainerColumn}>
                                    <Input 
                                        placeholder="Nombre" 
                                        style={styles.nameInput} 
                                        value={name} 
                                        onChange={event => setName(event.target.value)} 
                                    />
                                    <Input 
                                        placeholder="Cupón" 
                                        style={styles.nameInput} 
                                        value={coupon} 
                                        onChange={event => setCouponHandler(event.target.value)} 
                                    />
                                </div>
                            </div>
                            { ticket.length > 0 && (
                                <div style={styles.ticketsContainer}>
                                    <PosTicket ticket={ticket} setTicket={setTicket} />
                                </div>
                            )}
                            { ticket.length > 0 && (
                                <div style={styles.payButtonContainer}>
                                    { selectedBusinessZone && selectedTable && (
                                        <button style={styles.addButton} onClick={addTicket}>AÑADIR</button>
                                    )}
                                    <button 
                                        style={styles.payButton} 
                                        onClick={payTicket}
                                    >
                                        COBRAR&nbsp;&nbsp;&nbsp;
                                        { +ticketValue() === +cartTotal ? 
                                            `${ticketValue()} €`
                                            : (
                                                <span
                                                    style={{
                                                        color: "red"
                                                    }}
                                                >
                                                    {`${cartTotal} €`}
                                                </span>
                                            )
                                        }
                                    </button>
                                </div>
                            )}
                        </div>
                    )}
                </ContainerRight>
            </MainContainer>
            <PosCompanionModal 
                isOpen={selectedFoodHasCompanion} 
                setIsOpen={setSelectedFoodHasCompanion} 
                selectedFood={selectedFood} 
                setTicket={setTicket} 
            />
        </>
    );
}

export default POS;


const MainContainer = styled.div`
  display: flex;
  flex: 1;
  gap: 20px;
  width: calc(100vw - 70px);
  height: 100%;
  max-height: 100vh;
  overflow: hidden;
`;

// FOOD STYLED COMPONENTS DIV
const ContainerLeft = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  height: 100vh;
  min-width: 0;
  overflow: hidden;
`;

const FoodsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  height: 100%;
  padding-top: 200px;
  padding-bottom: 200px;
  width: 100%;
  overflow-y: scroll
`;

const FoodWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 20px;
  width: 100%;
`;

const FamiliesHeader = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0px;
  left: 0px;
  padding-top: 20px;
  padding-bottom: 10px;
  gap: 10px;
  width: 100%;
  max-width: 100vw;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 0px 2px 0px rgba(145, 158, 171, 0.2),
    0px 12px 24px -4px rgba(145, 158, 171, 0.12);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  z-index: 9;
`;

const FamiliesHorizontalScroll = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
  padding-left: 80px;
  padding-right: 80vw;
  min-height: 50px;
  overflow-x: scroll;
`;

const MenusHorizontalScroll = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
  padding-left: 80px;
  padding-right: 450px;
  min-height: 50px;
  overflow-x: scroll;
`;

const MenuTitle = styled.div`
  display: flex;
  font-size: 18px;
  font-weight: bold;
  height: 32px;
  border-radius: 20px;
  padding: 3px 10px;
  align-items: center;
  box-shadow: 0px 0px 2px 0px rgba(145, 158, 171, 0.2),
    0px 12px 24px -4px rgba(145, 158, 171, 0.12);
  background-color: ${({ bgColor }) => bgColor || '#f2f2f2'};

  & > span {
    padding: 5px 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

// TICKET STYLED COMPONENTS DIV
const ContainerRight = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky; // Fijo pero relativo al contenedor padre
  top: 0;
  align-items: center;
  height: 100vh; // Altura completa
  min-width: 200px;
  max-width: 350px;
  width: 25vw; // Se ajusta en función del ancho de la ventana
  background: #f2f2f2;
  padding: 10px;
  gap: 10px;
  z-index: 10;

  @media (max-width: 768px) {
    width: 100%; // En pantallas pequeñas, ocupa todo el ancho
    position: relative;
    height: auto;
  }
`;


const styles = {
    headerButtons: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
    },

    horizontalScroll: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        gap: "10px",
        width: "100%",
    },

    vertical: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "10px",
        width: "100%",
    },

    menuTitle: {
        display: "flex",
        fontSize: "18px",
        height: "31px",
        borderRadius: "20px",
        padding: "3px 10px",
        alignItems: "center",
    },

    familyCard: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "150px",
        minWidth: "150px",
        height: "50px",
        borderRadius: "15px",
        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
    },
    familyCardTitle: {
        paddingTop: "5px",
        fontSize: "20px",
        fontWeight: 900,
        lineHeight: "27.28px",
    },

    menuCard: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "150px",
        minWidth: "150px",
        height: "80px",
        borderRadius: "15px",
        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
    },
    menuCardTitle: {
        fontSize: "20px",
        fontWeight: 900,
        lineHeight: "27.28px",
    },

    containerRightVertical: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "10px",
        width: "100%",
    },
    containerRightVerticalSelector: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "10px",
        width: "100%",
    },
    zoneSelector: {
        display: "flex", 
        flex: 2,
        background: "white",
    },
    noTableCard: {
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        height: "40px",
        borderRadius: "10px",
        background: "white",
        fontSize: "12px",
        fontWeight: 900,
        pointer: "cursor",
    },
    tablesContainer: {
        display: "flex",
        flexDirection: "row",
        gap: "10px",
        width: "100%",
        overflowX: "scroll",
    },
    tableCard: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "80px",
        minWidth: "80px",
        height: "50px",
        borderRadius: "10px",
        background: "white",
        fontSize: "20px",
        fontWeight: 900,
        pointer: "cursor",
    },
    tableAndZoneTitle: {
        fontSize: "20px",
        fontWeight: 900,
        textAlign: "left",
        width: "100%",
    },
    inputsContainer: {
        display: "flex",
        gap: "10px",
        width: "100%",
    },
    inputContainerColumn: {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        width: "100%"
    },
    notesTextArea: {
        display: "flex",
        width: "100%",
        background: "rgb(255, 255, 255)",
    },
    nameInput: {
        display: "flex",
        width: "100%",
        background: "white",
    },
    ticketsContainer: {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        maxHeight: "calc(100vh - 390px)",
        width: "100%",
        overflowY: "scroll",
        overflowX: "hidden"
    },
    payButtonContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "371px",
        height: "42px",
        position: "fixed",
        gap: "10px",
        bottom: 10,
    },
    addButton: {
        display: "flex",
        width: "137px",
        height: "42px",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "10px",
        background: "#363636",
        color: "white",
        fontSize: "18px",
        fontWeight: 700,
        cursor: "pointer",
    },
    payButton: {
        display: "flex",
        width: "224px",
        height: "42px",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "10px",
        border: "none",
        background: "#26307E",
        color: "white",
        fontSize: "18px",
        fontWeight: 700,
        cursor: "pointer",
    },
};