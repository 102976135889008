import React, { useEffect } from 'react';
import {
  Adapt,
  Dialog,
  Sheet,
  Separator, 
  SizableText, 
  Tabs, 
  Text,
  Button
} from 'tamagui';
import { PlusCircle, XCircle } from '@tamagui/lucide-icons'
import { POSGetFoodCompanionsAndCategoriesFoodsNames } from '../../../services/food.service';

export default function PosCompanionModal({ isOpen, setIsOpen, selectedFood, setTicket }) {
  return <DialogInstance isOpen={isOpen} setIsOpen={setIsOpen} selectedFood={selectedFood} setTicket={setTicket} />
}

function DialogInstance({ isOpen, setIsOpen, selectedFood, setTicket }) {
  const [companions, setCompanions] = React.useState([]);

  useEffect(() => {
    if (!selectedFood) return;
    const companionFoodsIds = [];
    const companionCategoryFoodsIds = [];
    selectedFood.companion.forEach(companion => {
      companion.foods.forEach(food => {
        companionFoodsIds.push(food);
      });
      companion.categories.forEach(category => {
        category.foods.forEach(food => {
          companionCategoryFoodsIds.push(food);
        });
      });
    });
    POSGetFoodCompanionsAndCategoriesFoodsNames(companionFoodsIds, companionCategoryFoodsIds)
      .then(response => {
        const { companionFoods, companionCategoryFoods } = response;
        let updatedCompanions = selectedFood.companion.map(companion => {
          return {
            ...companion,
            foods: companion.foods.map(foodId => ({
              _id: foodId,
              name: companionFoods.find(food => food._id === foodId)?.name || '',
              price_extra: companionFoods.find(food => food._id === foodId)?.price_extra || 0,
              selected: false
            })).filter(food => food.name !== ''),
            categories: companion.categories.map(category => ({
              ...category,
              foods: category.foods.map(foodId => ({
                _id: foodId,
                name: companionCategoryFoods.find(food => food._id === foodId)?.name || '',
                price_extra: companionCategoryFoods.find(food => food._id === foodId)?.price_extra || 0,
                selected: false
              })).filter(food => food.name !== '')
            }))
          };
        });
        setCompanions(updatedCompanions);
      }).catch(error => {
        console.error('error', error);
      });
  }, [selectedFood]);

  return (
    <Dialog modal open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Title display='none'>Menú</Dialog.Title>
      <Adapt when="sm" platform="touch">
        <Sheet animation="medium" zIndex={200000} modal dismissOnSnapToBottom>
          <Sheet.Frame padding="$lg" gap="$lg">
            <Adapt.Contents />
          </Sheet.Frame>
          <Sheet.Overlay
            animation="lazy"
            enterStyle={{ opacity: 0 }}
            exitStyle={{ opacity: 0 }}
          />
        </Sheet>
      </Adapt>
      <Dialog.Portal>
        <Dialog.Overlay
          key="overlay"
          animation="slow"
          opacity={0.5}
          enterStyle={{ opacity: 0 }}
          exitStyle={{ opacity: 0 }}
        />
        <Dialog.Content
          bordered
          elevate
          key="content"
          animateOnly={['transform', 'opacity']}
          animation={[
            'quicker',
            {
              opacity: {
                overshootClamping: true,
              },
            },
          ]}
          enterStyle={{ x: 0, y: -20, opacity: 0, scale: 0.9 }}
          exitStyle={{ x: 0, y: 10, opacity: 0, scale: 0.95 }}
          gap="10px"
          width="700px"
          height="400px"
        >

          <HorizontalTabs setIsOpen={setIsOpen} selectedFood={selectedFood} companions={companions} setCompanions={setCompanions} setTicket={setTicket} />
            
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog>
  )
}

function HorizontalTabs({ setIsOpen, selectedFood, companions, setCompanions, setTicket }) {

  const addFoodToTicket = (selected_companion, selected_food, selected_category = null) => {
    selected_companion.foods.forEach(food => {
      if (food._id !== selected_food._id) {
        food.selected = false;
      }
    });
    if (selected_companion.categories.length > 0) {
      selected_companion.categories.forEach(category => {
        category.foods.forEach(food => {
          if (food._id !== selected_food._id) {
            food.selected = false;
          }
        });
      });
    }
    selected_food.selected = !selected_food.selected;
    if (selected_category) {
      setCompanions((prev) => {
        return prev.map((companion) => {
          if (selected_companion._id === companion._id) {
            return {
              ...companion,
              categories: companion.categories.map((category) => {
                if (selected_category._id === category._id) {
                  return {
                    ...category,
                    foods: category.foods.map((f) => {
                      if (f._id === selected_food._id) {
                        return selected_food;
                      }
                      return f;
                    }),
                  };
                }
                return category;
              }),
            };
          }
          return companion;
        });
      });
    } else {
      setCompanions((prev) => {
        return prev.map((companion) => {
          if (selected_companion._id === companion._id) {
            return {
              ...companion,
              foods: companion.foods.map((f) => {
                if (f._id === selected_food._id) {
                  return selected_food;
                }
                return f;
              }),
            };
          }
          return companion;
        });
      });
    }
  }

  useEffect(() => {
    if (companions.length > 0 && companions.every(companion => companion.foods.some(food => food.selected) 
      || companion.categories.find(category => category.foods.some(food => food.selected)))
    ) {
      const filterSelectedItems = companions.map(companion => ({
        ...companion,
        foods: companion.foods.filter(food => food.selected),
        categories: companion.categories.map(category => ({
          ...category,
          foods: category.foods.filter(food => food.selected),
        })).filter(category => category.foods.length > 0),
      }));
      setTicket((prev) => {
        const combinedItems = [...prev, {
          ...selectedFood,
          companion: filterSelectedItems,
        }];
        const sortedItems = combinedItems.sort((a, b) => {
          if (a.companion.length < b.companion.length) return -1;
          if (a.companion.length > b.companion.length) return 1;
          if (a._id < b._id) return -1;
          if (a._id > b._id) return 1;
          return 0;
        });
        return sortedItems;
      });
      setCompanions((prev) => {
        return prev.map((companion) => {
          return {
            ...companion,
            foods: companion.foods.map((food) => {
              return {
                ...food,
                selected: false,
              };
            }),
            categories: companion.categories.map((category) => {
              return {
                ...category,
                foods: category.foods.map((food) => {
                  return {
                    ...food,
                    selected: false,
                  };
                }),
              };
            }),
          };
        });
      });
      setIsOpen(false);
    }
  }, [companions]);

  return (
    <Tabs
      defaultValue="tab1"
      orientation="horizontal"
      flexDirection="column"
      width="100%"
      height="100%"
      overflow="hidden"
    >
      <Tabs.List
        disablePassBorderRadius="bottom"
        aria-label="Manage your account"
      >
        { companions.map((companion, index) => (
          <Tabs.Tab 
            flex={1} 
            key={index}
            value={`tab${index + 1}`}
          >
            <SizableText fontFamily="$body">
              <Text>
                {companion.name}
              </Text>
            </SizableText>
          </Tabs.Tab>
        ))}
      </Tabs.List>
      <Separator />
      { companions.map((companion, i) => (
        <Tabs.Content
          key={companion._id}
          value={`tab${i + 1}`}
          alignItems="center"
          justifyContent="center"
          flex={1}
          background="#EDEDED"
          height="100%"
          overflow="scroll"
        >
          { companion.foods.map((food, j) => (
            <div 
              style={{
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                width: '100%',
                marginTop: '40px'
              }}
              key={`${i}-${j}-${food._id}`}
            >
              <div 
                style={{
                  padding: "0 20px",
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                  height: '100%',
                  width: '100%'
                }}
              >
                <Text>
                  {food.name}
                </Text>
                {food.price_extra > 0 && (
                  <Text color="#e0362b">
                    +{food.price_extra}€
                  </Text>
                )}  
              </div>
              {
                food.selected ? (
                  <Button icon={XCircle} size="$lg" color="#e0362b" onPress={() => addFoodToTicket(companion, food)}></Button>
                ) : (
                  <Button icon={PlusCircle} size="$lg" color="green"  onPress={() => addFoodToTicket(companion, food)}></Button>
                )
              }
            </div>
          ))}
          { companion.categories.map((category, j) => 
            category.foods.map((food, k) => (
              <div 
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '100%',
                  width: '100%'
                }}
                key={`${i}-${j}-${k}-${food._id}`}
              >
                <div 
                  style={{
                    padding: "0 20px",
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                    height: '100%',
                    width: '100%'
                  }}
                >
                  <Text>
                    {food.name}
                  </Text>
                  {food.price_extra > 0 && (
                    <Text color="#e0362b">
                      +{food.price_extra}€
                    </Text>
                  )}  
                </div>
                {
                  food.selected ? (
                    <Button icon={XCircle} size="$lg" color="#e0362b" onPress={() => addFoodToTicket(companion, food, category)}></Button>
                  ) : (
                    <Button icon={PlusCircle} size="$lg" color="green"  onPress={() => addFoodToTicket(companion, food, category)}></Button>
                  )
                }
              </div>
            ))
          )}
        </Tabs.Content>
      ))}
    </Tabs>
  )
}