import React from 'react';
import { TextField, Grid, Typography, Card, CardContent } from '@mui/material';


const Step1CreateBusiness = ({ formData, setFormData, errors }) => {
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        const nameParts = name.split('.');
        if (nameParts.length === 1) {
            setFormData(prevFormData => ({
                ...prevFormData,
                [name]: value
            }));
        } else {
            setFormData(prevFormData => ({
                ...prevFormData,
                [nameParts[0]]: {
                    ...prevFormData[nameParts[0]],
                    [nameParts[1]]: value
                }
            }));
        }
    };
    
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h5" gutterBottom>
                    Crear Empresa
                </Typography>
                <Card variant="outlined" sx={{ mb: 3 }}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            Información Básica
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                variant="outlined"
                                fullWidth
                                label="Email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                error={!!errors['email']}
                                helperText={errors['email']}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                variant="outlined"
                                fullWidth
                                label="Teléfono"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                error={!!errors['phone']}
                                helperText={errors['phone']}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                variant="outlined"
                                fullWidth
                                label="Nombre de Empresa"
                                name="companyName"
                                value={formData.companyName}
                                onChange={handleChange}
                                error={!!errors['companyName']}
                                helperText={errors['companyName']}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                variant="outlined"
                                fullWidth
                                label="Nombre Fiscal"
                                name="fiscalName"
                                value={formData.fiscalName}
                                onChange={handleChange}
                                error={!!errors['fiscalName']}
                                helperText={errors['fiscalName']}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <Card variant="outlined" sx={{ mb: 3 }}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            Información Fiscal y Administrativa
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                variant="outlined"
                                fullWidth
                                label="Nombre de Administrador Fiscal"
                                name="fiscalAdmin.name"
                                value={formData.fiscalAdmin.name}
                                onChange={handleChange}
                                error={!!errors['fiscalAdmin-name']}
                                helperText={errors['fiscalAdmin-name']}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                variant="outlined"
                                fullWidth
                                label="Apellido de Administrador Fiscal"
                                name="fiscalAdmin.surname"
                                value={formData.fiscalAdmin.surname}
                                onChange={handleChange}
                                error={!!errors['fiscalAdmin-surname']}
                                helperText={errors['fiscalAdmin-surname']}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                variant="outlined"
                                fullWidth
                                label="CIF"
                                name="cif"
                                value={formData.cif}
                                onChange={handleChange}
                                error={!!errors['cif']}
                                helperText={errors['cif']}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                variant="outlined"
                                fullWidth
                                label="VAT"
                                name="vat"
                                value={formData.vat}
                                onChange={handleChange}
                                error={!!errors['vat']}
                                helperText={errors['vat']}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <Card variant="outlined" sx={{ mb: 3 }}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            Direcciones
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                variant="outlined"
                                fullWidth
                                label="Calle del local"
                                name="localAddress.street"
                                value={formData.localAddress.street}
                                onChange={handleChange}
                                error={!!errors['localAddress-street']}
                                helperText={errors['localAddress-street']}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                variant="outlined"
                                fullWidth
                                label="ZIP del local"
                                name="localAddress.zip"
                                value={formData.localAddress.zip}
                                onChange={handleChange}
                                error={!!errors['localAddress-zip']}
                                helperText={errors['localAddress-zip']}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                variant="outlined"
                                fullWidth
                                label="Ciudad del local"
                                name="localAddress.city"
                                value={formData.localAddress.city}
                                onChange={handleChange}
                                error={!!errors['localAddress-city']}
                                helperText={errors['localAddress-city']}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                variant="outlined"
                                fullWidth
                                label="País del local"
                                name="localAddress.country"
                                value={formData.localAddress.country}
                                onChange={handleChange}
                                error={!!errors['localAddress-country']}
                                helperText={errors['localAddress-country']}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                variant="outlined"
                                fullWidth
                                label="Calle de la dirección fiscal"
                                name="fiscalAddress.street"
                                value={formData.fiscalAddress.street}
                                onChange={handleChange}
                                error={!!errors['fiscalAddress-street']}
                                helperText={errors['fiscalAddress-street']}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                variant="outlined"
                                fullWidth
                                label="ZIP de la dirección fiscal"
                                name="fiscalAddress.zip"
                                value={formData.fiscalAddress.zip}
                                onChange={handleChange}
                                error={!!errors['fiscalAddress-zip']}
                                helperText={errors['fiscalAddress-zip']}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                variant="outlined"
                                fullWidth
                                label="Ciudad de la dirección fiscal"
                                name="fiscalAddress.city"
                                value={formData.fiscalAddress.city}
                                onChange={handleChange}
                                error={!!errors['fiscalAddress-city']}
                                helperText={errors['fiscalAddress-city']}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                variant="outlined"
                                fullWidth
                                label="País de la dirección fiscal"
                                name="fiscalAddress.country"
                                value={formData.fiscalAddress.country}
                                onChange={handleChange}
                                error={!!errors['fiscalAddress-country']}
                                helperText={errors['fiscalAddress-country']}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <Card variant="outlined">
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            Información de Cuenta y Comisión
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                variant="outlined"
                                fullWidth
                                label="IBAN"
                                name="iban"
                                value={formData.iban}
                                onChange={handleChange}
                                error={!!errors['iban']}
                                helperText={errors['iban']}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                variant="outlined"
                                fullWidth
                                label="Comisión"
                                name="commission"
                                value={formData.commission}
                                onChange={handleChange}
                                error={!!errors['commission']}
                                helperText={errors['commission']}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default Step1CreateBusiness;