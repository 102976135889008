import React, { useState, useRef } from 'react';
import addImage from "../../assets/SVG/fi-rr-add.svg";

const styles = {
    card: {
        borderRadius: '20px',
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#fff',
        width: '350px',
        position: 'relative',
        zIndex: '1',
        boxShadow: '0px 0px 20px rgba(145, 158, 171, 0.2), 0px 12px 24px rgba(145, 158, 171, 0.12)'
    },
    card__boxtop: {
        width: '100%',
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'left',
    },
    card____boxtop__column: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    name__container: {
        wordWrap: 'break-word',
        overflowWrap: 'break-word',
        whiteSpace: 'normal',
        overflowX: 'hidden', // Oculta la barra de desplazamiento horizontal
    },
    name: {
        fontWeight: 'bold',
        fontSize: '1.5rem',
        margin: '10px 0',
        outline: 'none',
        border: 'none',
        width: 'calc(100% - 20px)', // Teniendo en cuenta el posible ancho de la barra de desplazamiento
        overflowY: 'auto', // Agrega una barra de desplazamiento vertical si es necesario
        resize: 'none',
    },
    prices: {
        display: 'flex',
        gap: '20px',
        alignItems: 'justify-content',
    },
    price: {
        display: 'flex',
        flex: '1',
        flexDirection: 'row',
        backgroundColor: '#f9f9f9',
        padding: '5px',
        borderRadius: '10px',
        textAlign: 'center',
        width: '6em',
        outline: 'none',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    price__input: {
        border: 'none',
        outline: 'none',
        textAlign: 'left',
        fontSize: '1rem',
        color: '#ccc',
        backgroundColor: '#f9f9f9',
        width: '100%',
    },
    euroSymbol: {
        fontSize: '1rem',
        color: '#ccc',
    },
    imageWrapper: {
        flex: '1',
        maxWidth: '135px',
        overflow: 'hidden',
        aspectRatio: '1',
        borderRadius: '15px'
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    placeholder: {
        flex: '1',
        maxWidth: '135px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        aspectRatio: '1',
        backgroundColor: '#f2f2f2',
    },
    image__button: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
    },
    description: {
        display: 'flex',
        alignItems: 'center',
        border: 'none',
        borderBottom: '1px solid #cccccc',
        padding: '5px 0',
        backgroundColor: 'transparent',
        width: '100%',
        fontStyle: 'italic',
        color: '#cccccc',
        fontSize: '16px',
        outline: 'none',
        resize: 'vertical',
        overflow: 'auto',
        margin: '20px 0',
    },
    addSection: {
        textAlign: 'center',
        fontWeight: 'bold',
        width: '100%',
        cursor: 'pointer',
        margin: '10px 0',
        display: 'flex',
        flexDirection: 'row',
        padding: '10px',
        border: '1px solid #ccc',
        borderRadius: '20px',
        justifyContent: 'center',
        alignItems: 'cener',
        boxShadow: 'inset 0 2px 4px rgba(0,0,0,0.1)',
        outline: 'none',
        backgroundColor: 'transparent',
        fontSize: '1rem',
    },
    allergens: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
    },
    deleteButton: {
        display: 'flex',
        flexDirection: 'row',
        padding: '10px',
        margin: '5px',
        border: '1px solid #ccc',
        borderRadius: '20px',
        justifyContent: 'center',
        alignItems: 'cener',
        width: '125px',
        boxShadow: 'inset 0 2px 4px rgba(0,0,0,0.1)',
        outline: 'none',
        backgroundColor: 'transparent',
        cursor: 'pointer',
        fontSize: '1rem',
        background: 'red',
        color: 'white'
    },
    allergensButton: {
        display: 'flex',
        flexDirection: 'row',
        padding: '10px',
        margin: '5px',
        border: '1px solid #ccc',
        borderRadius: '20px',
        justifyContent: 'center',
        alignItems: 'cener',
        width: '125px',
        boxShadow: 'inset 0 2px 4px rgba(0,0,0,0.1)',
        outline: 'none',
        backgroundColor: 'transparent',
        cursor: 'pointer',
        fontSize: '1rem',
    },
    workZoneInput: {
        borderRadius: '6px',
        textAlign: 'left',
        fontSize: '1rem',
        color: '#ccc',
        width: '95%',
        padding: '2px 5px',
        margin: '20px 0 0 0',
        backgroundColor: '#fff',
    },
};

const ProductItem = ({
    food,
    editProductName,
    editProductPrice,
    editProductPrice2,
    editProductDescription,
    editProductImage,
    combinationsAndMenusModal,
    allergensAndNutCondModal,
    workZones,
    editProductWorkZone,
    deleteProduct
}) => {

    const [productName, setProductName] = useState(food.name);
    const [productPrice, setProductPrice] = useState(food.price);
    const [productPrice12, setProductPrice12] = useState(food.price);
    const [productPrice2, setProductPrice2] = useState(food.price_2);
    const [productPrice22, setProductPrice22] = useState(food.price_2);
    const [productDescription, setProductDescription] = useState(food.description);
    const [productImage, setProductImage] = useState(`data:image/jpg;base64,${food.image_url}`);
    const [selectedZone, setSelectedZone] = useState(food.work_zone ? food.work_zone : '');

    const timeoutIdNameRef = useRef(null);
    const timeoutIdPriceRef = useRef(null);
    const timeoutIdPrice2Ref = useRef(null);
    const timeoutIdDescriptionRef = useRef(null);

    const fileInputRef = useRef();

    const openCombinationsAndMenusModal = (companionId) => {
        combinationsAndMenusModal(companionId, food._id);
    }

    const openAllergensAndNutCondModal = () => {
        allergensAndNutCondModal(food._id);
    }

    const setName = (title) => {
        setProductName(title);
        if (timeoutIdNameRef.current) {
            clearTimeout(timeoutIdNameRef.current);
        }
        timeoutIdNameRef.current = setTimeout(() => {
            editProductName(title, food._id);
            timeoutIdNameRef.current = null;
        }, 1300);
    }

    const setPrice = (price) => {
        let normalizedPrice = price.replace(',', '.').replace(/[^\d.]/g, '');
        setProductPrice(normalizedPrice);
        if (timeoutIdPriceRef.current) {
            clearTimeout(timeoutIdPriceRef.current);
        }
        timeoutIdPriceRef.current = setTimeout(() => {
            if (/^\d+(\.\d{0,2})?$/.test(normalizedPrice)) {
                normalizedPrice = parseFloat(normalizedPrice).toFixed(2);
                setProductPrice(normalizedPrice);
                setProductPrice12(normalizedPrice);
                editProductPrice(normalizedPrice, food._id);
                timeoutIdPriceRef.current = null;
            } else {
                setProductPrice(productPrice12);
                timeoutIdPriceRef.current = null;
            }
        }, 1300);
    }

    const setPrice2 = (price) => {
        let normalizedPrice = price.replace(',', '.').replace(/[^\d.]/g, '');
        setProductPrice2(normalizedPrice);
        if (timeoutIdPrice2Ref.current) {
            clearTimeout(timeoutIdPrice2Ref.current);
        }
        timeoutIdPrice2Ref.current = setTimeout(() => {
            if (normalizedPrice === '') normalizedPrice = '';
            else normalizedPrice = parseFloat(normalizedPrice).toFixed(2);
            if (/^\d+(\.\d{0,2})?$/.test(normalizedPrice) || normalizedPrice === '') {
                setProductPrice2(normalizedPrice);
                setProductPrice22(normalizedPrice);
                editProductPrice2(normalizedPrice, food._id);
                timeoutIdPrice2Ref.current = null;
            } else {
                setProductPrice2(productPrice22);
                timeoutIdPrice2Ref.current = null;
            }
        }, 1300);
    }

    const setDescription = (description) => {
        setProductDescription(description);
        if (timeoutIdDescriptionRef.current) {
            clearTimeout(timeoutIdDescriptionRef.current);
        }
        timeoutIdDescriptionRef.current = setTimeout(() => {
            editProductDescription(description, food._id);
            timeoutIdDescriptionRef.current = null;
        }, 1300);
    }

    const changeProductImage = () => {
        fileInputRef.current.click();
    }

    const handleFileInputChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                editProductImage(reader.result.split(',')[1], food._id);
                setProductImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleWorkZoneSelectorChange = (event) => {
        if (event.target.value === '-1') return;
        setSelectedZone(event.target.value);
        editProductWorkZone(event.target.value, food._id);
    }

    const addSection = () => {
        combinationsAndMenusModal(null, food._id);
    }

    return (
        <div style={styles.card}>
            <div style={styles.card__boxtop}>
                <div style={styles.card____boxtop__column}>
                    <textarea
                        style={styles.name}
                        value={productName ? productName : ''}
                        onChange={(event) => setName(event.target.value)}
                    />
                    <div style={styles.prices}>
                        <div style={styles.price}>
                            <input type="text" style={styles.price__input} value={productPrice ? productPrice : ''} onChange={(event) => setPrice(event.target.value)} />
                            <span style={styles.euroSymbol}>€</span>
                        </div>
                        <div style={styles.price}>
                            <input type="text" style={styles.price__input} value={productPrice2 ? productPrice2 : ''} onChange={(event) => setPrice2(event.target.value)} />
                            <span style={styles.euroSymbol}>€</span>
                        </div>
                    </div>
                </div>
                <div style={styles.card____boxtop__column}>
                    <div onClick={changeProductImage} style={styles.imageWrapper}>
                        <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            accept="image/*"
                            onChange={handleFileInputChange}
                        />
                        {/* <div onClick={changeProductImage} style={styles.image__button}></div> */}
                        {productImage === 'data:image/jpg;base64,' ? (
                            <div style={styles.placeholder}>
                                <img src={addImage}></img>
                            </div>
                        ) : (
                            <div style={styles.placeholder}>
                                <img style={styles.image} src={productImage} alt={food.name} />
                            </div>
                        )}
                    </div>
                </div>

            </div>
            <select style={styles.workZoneInput} value={selectedZone} onChange={handleWorkZoneSelectorChange}>
                <option value="-1">Seleccione una zona de trabajo</option>
                {workZones.map(zone => (
                    <option key={zone._id} value={zone._id}>{zone.name}</option>
                ))}
            </select>
            <textarea type="text" placeholder="AÑADIR DESCRIPCIÓN..." style={styles.description} value={productDescription ? productDescription : ''} onChange={(event) => setDescription(event.target.value)} />
            {food.companion.map((companion) => {
                return (
                    <button key={companion._id} onClick={() => openCombinationsAndMenusModal(companion._id)} style={styles.addSection}>{`Sección ${companion.name}`}</button>
                );
            })}
            <button onClick={addSection} style={styles.addSection}>{`Añadir sección +`}</button>
            <div style={styles.allergens}>
                <button onClick={() => deleteProduct(food._id)} style={styles.deleteButton}>ELIMINAR</button>
                <button onClick={openAllergensAndNutCondModal} style={styles.allergensButton}>ALERGENOS</button>
            </div>
        </div>
    );
};

export default ProductItem;