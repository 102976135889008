// src/components/loadingAnimation.jsx

import React from "react";
import { useSelector } from "react-redux";
import { PacmanLoader } from "react-spinners";
import styled from "styled-components";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";

const Loader = () => {
  const { status, responseStatus } = useSelector((state) => ({
    status: state.loading.status,
    responseStatus: state.loading.responseStatus,
  }));

  return (
    <InfoRight show={status}>
      <InfoRow>
        {responseStatus === "loading" && (
          <>
            <PacmanLoader
              color="#000000"
              size={8}
              speedMultiplier={1}
              aria-label="Loading Spinner"
            />
            <div style={{ marginLeft: "15px" }}></div>
          </>
        )}
        {responseStatus === "success" && <SuccessIcon />}
        {responseStatus === "error" && <ErrorIcon />}
      </InfoRow>
    </InfoRight>
  );
};

const InfoRight = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  top: 15px;
  right: 10px;
  background: rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.4);
  color: black;
  padding: 5px 7px;
  font-size: 10px;
  border-radius: 15px;
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  z-index: 9999;
  opacity: ${({ show }) => (show ? "1" : "0")};
  visibility: ${({ show }) => (show ? "visible" : "hidden")};
  transition: opacity 0.5s, visibility 0.5s;
  transform: translateY(${({ show }) => (show ? "0" : "-10px")});

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0;
      transform: translateY(-10px);
    }
  }

  animation: ${({ show }) => (show ? "fadeIn 0.5s" : "fadeOut 0.5s")};
`;

const InfoRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

const SuccessIcon = styled(FaCheckCircle)`
  color: green;
  font-size: 18px;
`;

const ErrorIcon = styled(FaTimesCircle)`
  color: red;
  font-size: 18px;
`;

export default Loader;
